import React, { FC } from 'react';

import Loader from 'src/components/shared-components/Loader/Loader';
import { ModalsTypes } from 'src/types/popUp';

import useTypedSelector from 'src/hooks/useTypedSelector';
import useActions from 'src/hooks/useActions';

import { Container } from 'src/ui/Container';

import routes from 'src/routes';

import { NavProfile } from './NavProfile';
import { MobileSidebar } from '../MobileSidebar';

import * as Styles from './LandingHeader.styles';

export const LandingHeader: FC = () => {
  const { isAuth, isFetching } = useTypedSelector((state) => state.auth);

  const { setCurrentModal } = useActions();

  return (
    <Styles.Header>
      <Container>
        <Styles.Flex>
          <Styles.Sidebar>
            <MobileSidebar />
          </Styles.Sidebar>
          <Styles.Title>DentMarketPlace</Styles.Title>
          <Styles.HeaderLink to={routes.about}>Как это работает?</Styles.HeaderLink>
          {isFetching && (
            <Styles.Loader>
              <Loader />
            </Styles.Loader>
          )}
          {isAuth && (
            <Styles.NavProfile>
              <NavProfile />
            </Styles.NavProfile>
          )}
          {!isFetching && !isAuth && (
            <Styles.Buttons>
              <Styles.Button onClick={() => setCurrentModal(ModalsTypes.reg)}>
                Регистрация
              </Styles.Button>
              <Styles.Button onClick={() => setCurrentModal(ModalsTypes.login)}>
                Вход
              </Styles.Button>
            </Styles.Buttons>
          )}
        </Styles.Flex>
      </Container>
    </Styles.Header>
  );
};
