import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Dropdown, Menu, message } from 'antd';

import { usersApi } from 'src/api/api';

import { VerticalDots } from 'src/ui/Icons';

import { Colors } from 'src/constants/colors';

import { Button } from 'src/ui/Button';

import routes from 'src/routes';

import applyParams from 'src/helpers/applyParams';

import UploadInvoice from '../UploadInvoice';

import { useComparedOrder } from '../../context/ComparedOrder';

import * as Styles from './OrderActions.styles';

export const OrderActions = () => {
  const { id } = useParams<{ id: string }>();

  const { comparedOrder } = useComparedOrder();

  if (!comparedOrder) {
    return null;
  }

  const closeOrder = async () => {
    try {
      const body = new FormData();
      body.append('orderId', id);

      const { data } = await usersApi.closeCart(Number(id), body);

      if (!data.result) {
        throw 'Произошла ошибка при закрытии заявки';
      }

      message.success('Заказ отправлен в архив!', 5);
    } catch (e) {
      message.error(e);
    }
  };

  return (
    <>
      <Styles.GlobalStyles />
      <Styles.Header>
        <Styles.TitleContainer>
          <Styles.Title>
            Заявка №
            {id}
            {' '}
            от
            {' '}
            {new Date(comparedOrder.order.date).toLocaleDateString()}
          </Styles.Title>
          <Dropdown
            overlay={(
              <Menu className="order-actions">
                <Menu.Item key="double" className="order-actions__item">
                  <Link to={applyParams(routes.newTemplate, [id])}>
                    Продублировать заявку
                  </Link>
                </Menu.Item>
                {comparedOrder.active && (
                  <Menu.Item key="close" className="order-actions__item">
                    <Button onClick={closeOrder}>
                      Закрыть заявку
                    </Button>
                  </Menu.Item>
                )}
              </Menu>
            )}
            placement="bottomLeft"
            trigger={['hover']}
          >
            <Button>
              <VerticalDots color={Colors.MajesticEggplant} />
            </Button>
          </Dropdown>
        </Styles.TitleContainer>
        {comparedOrder.active && (
          <UploadInvoice />
        )}
      </Styles.Header>
    </>
  );
};
