import React, { FC } from 'react';
import { range } from 'lodash';

import { LinkArrow } from '../Icons';

import * as Styles from './Pagination.styles';

interface PaginationProps {
  current: number;
  countOnPage?: number;
  total: number;
  onChange: (value: number) => void;
}

export const Pagination: FC<PaginationProps> = (props) => {
  const {
    current = 1,
    countOnPage = 20,
    total = 1,
    onChange,
  } = props;

  let items = [];

  const pageCount = Math.ceil(total / countOnPage);

  if (pageCount <= 1) {
    return null;
  }

  // 1 2 3
  // 1 2 3 ... 35 36
  // 1 ... 4 ... 35 36

  // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageNumbers = 0 + 5;

  if (totalPageNumbers >= pageCount) {
    return range(1, pageCount);
  }

  const leftSiblingIndex = Math.max(current - 0, 1);
  const rightSiblingIndex = Math.min(
    current + 0,
    pageCount,
  );

  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < pageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = pageCount;

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = 3 + 2 * 0;
    const leftRange = range(1, leftItemCount);

    items = [...leftRange, '...', pageCount];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = 3 + 2 * 0;
    const rightRange = range(
      pageCount - rightItemCount + 1,
      pageCount,
    );
    items = [firstPageIndex, '...', ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = range(leftSiblingIndex, rightSiblingIndex);
    items = [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
  }

  return (
    <Styles.Pagination>
      <Styles.ArrowButton disabled={current === 1} onClick={() => onChange(current - 1)}>
        <LinkArrow transform="rotate(-180)" />
      </Styles.ArrowButton>
      <Styles.Pages>
        {items.map((value) => (
          <Styles.Page key={value}>
            <Styles.PageInput
              id={`page-${value}`}
              name="pagination" // TODO: тут придется переделать с 99% вероятностью
              type="radio"
              value={value}
              checked={value === current}
              onChange={(e) => onChange(Number(e.target.value))}
              disabled={value === '...'}
            />
            <Styles.PageLabel htmlFor={`page-${value}`}>
              {value}
            </Styles.PageLabel>
          </Styles.Page>
        ))}
      </Styles.Pages>
      <Styles.ArrowButton disabled={current === pageCount} onClick={() => onChange(current + 1)}>
        <LinkArrow />
      </Styles.ArrowButton>
    </Styles.Pagination>
  );
};
