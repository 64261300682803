import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';

import { Colors } from 'src/constants/colors';
import { Button } from 'src/ui/Button';

export const Sidebar = styled.div<{ isCollapsed: boolean }>`
  container-type: inline-size;

  display: none;

  @media screen and (min-width: 1024px) {
    position: relative;

    flex-direction: column;

    display: flex;
      
    border-radius: 0px 24px 24px 0px;
    max-width: 272px;
    width: 100%;

    background-color: ${Colors.WhiteSmoke};

    overflow: hidden;

    transition: max-width .3s ease-in-out;

    ${({ isCollapsed }) => isCollapsed && `
      max-width: 56px;
    `}
  }
`;

export const Header = styled.div`
  margin-bottom: 32px;
  padding: 16px 8px;

  @container (min-width: 272px) {
    padding: 16px 16px;
  }
`;

export const Title = styled(NavLink)`
  display: none;

  @container (min-width: 272px) {
    display: revert;
  }
`;

export const Toggle = styled(Button)`
  position: absolute;
  right: 16px;
  bottom: 44px;
  
  justify-content: center;

  border-radius: 8px;
  width: 40px;
  height: 40px;

  color: ${Colors.UltraViolet};

  background-color: ${Colors.LittleDipper};
`;
