import React, { useRef, useState } from 'react';

import routes from 'src/routes';

import useOnClickOutside from 'src/hooks/useOnClickOutside';

import { SidebarMenu } from 'src/components/SidebarMenu';

import { Portal } from 'src/ui/Portal';
import { Burger } from 'src/ui/Icons';

import * as Styles from './MobileSidebar.styles';

export const MobileSidebar = () => {
  const [isOpened, setIsOpened] = useState(false);
  const sidebarRef = useRef(null);

  useOnClickOutside(sidebarRef, () => setIsOpened(false));

  return (
    <>
      <Styles.Burger type="button" onClick={() => setIsOpened(!isOpened)}>
        <Burger />
      </Styles.Burger>
      <Portal>
        <Styles.Overlay visible={isOpened} role="button" />
        <Styles.Sidebar visible={isOpened} ref={sidebarRef}>
          <Styles.Header>
            <Styles.Title to={routes.index}>
              {/* // TODO: вынести логотип */}
              <svg width="89" height="48" viewBox="0 0 89 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1410_5983)">
                  <path d="M33.0344 28.7891V27.6717C32.2505 28.5211 31.317 28.945 30.2339 28.945C28.9403 28.945 27.9087 28.5336 27.1388 27.7107C26.3674 26.8878 25.9824 25.7969 25.9824 24.4395C25.9824 23.0681 26.4359 21.9491 27.3445 21.0795C28.2515 20.2115 29.4048 19.7767 30.8027 19.7767C31.7035 19.7767 32.4484 19.9855 33.0359 20.4032V15.0764H34.6037V28.7891H33.0344ZM30.5268 27.4754C31.4931 27.4754 32.3284 27.0842 33.0344 26.3003V21.932C32.3159 21.4754 31.5913 21.2463 30.8603 21.2463C29.9596 21.2463 29.1975 21.5377 28.5788 22.1175C27.9585 22.6988 27.6484 23.4593 27.6484 24.399C27.6484 25.3528 27.9164 26.1039 28.451 26.6525C28.9871 27.2011 29.679 27.4754 30.5268 27.4754Z" fill="#1191D0" />
                  <path d="M44.1815 26.5948L44.6709 27.8291C43.8215 28.6006 42.7182 28.9855 41.3607 28.9855C39.9379 28.9855 38.794 28.5678 37.9322 27.7325C37.0704 26.8972 36.6387 25.7798 36.6387 24.3834C36.6387 23.051 37.0439 21.9507 37.8527 21.0826C38.6615 20.2146 39.7727 19.7798 41.1831 19.7798C42.3457 19.7798 43.3182 20.1554 44.1021 20.9065C44.8859 21.6577 45.2771 22.6271 45.2771 23.8162C45.2771 24.2213 45.2444 24.5736 45.1789 24.8743H38.4013C38.4667 25.6972 38.7769 26.3439 39.3317 26.813C39.8865 27.2837 40.6018 27.5174 41.4761 27.5174C42.6262 27.5143 43.527 27.2089 44.1815 26.5948ZM41.1052 21.2463C40.3213 21.2463 39.6823 21.4645 39.1852 21.9024C38.688 22.3403 38.395 22.9045 38.3031 23.5964H43.6517C43.6127 22.8655 43.368 22.2904 42.9176 21.8728C42.4672 21.4551 41.8626 21.2463 41.1052 21.2463Z" fill="#1191D0" />
                  <path d="M47.2178 28.7891V19.9745H48.7856V21.0514C49.1113 20.6992 49.5367 20.4047 50.0588 20.1693C50.5809 19.934 51.117 19.8171 51.6656 19.8171C54.1076 19.8171 55.3295 21.1106 55.3295 23.6961V28.7891H53.7617V23.9891C53.7617 22.1875 52.9326 21.2852 51.2744 21.2852C50.2817 21.2852 49.4526 21.6639 48.7871 22.4213V28.7875H47.2178V28.7891Z" fill="#1191D0" />
                  <path d="M57.5801 25.4385V17.5248H59.1479V19.9731H62.3801V21.4427H59.1479V25.2827C59.1479 26.7461 59.6699 27.477 60.7157 27.477C61.2112 27.477 61.6757 27.3134 62.1058 26.9876L62.5951 28.2017C61.9936 28.6988 61.244 28.9466 60.3416 28.9466C59.3754 28.9466 58.6725 28.6521 58.2362 28.0645C57.7983 27.4754 57.5801 26.6011 57.5801 25.4385Z" fill="#1191D0" />
                  <path d="M77.5804 23.0089V28.7876H76.0126V23.439C76.0126 22.0022 75.3783 21.2837 74.1128 21.2837C73.6032 21.2837 73.1435 21.4006 72.7321 21.6359C72.3206 21.8713 72.0105 22.1783 71.8017 22.557C71.8141 22.6614 71.8204 22.8702 71.8204 23.1835V28.7861H70.2526V23.1055C70.2526 22.557 70.0889 22.1159 69.7632 21.784C69.436 21.4505 68.9731 21.2837 68.3731 21.2837C67.3928 21.2837 66.623 21.6827 66.0619 22.4791V28.7876H64.4941V19.9731H66.0619V21.0889C66.3876 20.7102 66.8162 20.4032 67.3445 20.1679C67.8728 19.9326 68.4183 19.8157 68.9809 19.8157C69.6604 19.8157 70.2308 19.9528 70.6952 20.2271C71.158 20.5014 71.476 20.8739 71.6458 21.3445C71.9715 20.8879 72.4063 20.5185 72.9487 20.238C73.491 19.9575 74.0879 19.8172 74.7409 19.8172C76.6328 19.8172 77.5804 20.8816 77.5804 23.0089Z" fill="#1191D0" />
                  <path d="M80.0293 32.707V19.9746H81.5971V21.0125C81.9103 20.6463 82.3374 20.3501 82.8797 20.1211C83.422 19.892 83.9924 19.7782 84.594 19.7782C85.8345 19.7782 86.8241 20.1927 87.5612 21.0218C88.2984 21.8509 88.6677 22.9247 88.6677 24.2447C88.6677 25.6037 88.2438 26.732 87.3945 27.6343C86.5451 28.5351 85.4418 28.9855 84.0844 28.9855C83.0386 28.9855 82.2096 28.7829 81.5971 28.3777V32.707H80.0293ZM84.1436 21.2463C83.0979 21.2463 82.2501 21.6514 81.5971 22.4603V26.8286C82.2766 27.2852 83.0589 27.5143 83.9472 27.5143C84.8745 27.5143 85.6148 27.2135 86.1711 26.6135C86.7259 26.0135 87.0033 25.2421 87.0033 24.3024C87.0033 23.3891 86.7493 22.6504 86.2397 22.0894C85.7301 21.5268 85.0319 21.2463 84.1436 21.2463Z" fill="#1191D0" />
                  <path d="M0.240234 -0.0778809C5.0714 2.88316 14.1883 10.052 14.1883 24.6234C14.1883 38.2598 3.20127 46.5195 0.318156 48.0499C6.00647 42.6234 10.5026 33.5377 10.3701 23.9221C10.1784 10.0722 3.82465 3.35069 0.240234 -0.0778809Z" fill="#1191D0" />
                  <path d="M12.0068 8.79431C15.0567 10.6629 20.812 15.1886 20.812 24.3881C20.812 32.9969 13.8754 38.2115 12.0567 39.1761C15.6474 35.7507 18.4868 30.0141 18.4027 23.9439C18.2811 15.2026 14.2697 10.959 12.0068 8.79431Z" fill="#1191D0" />
                </g>
                <defs>
                  <clipPath id="clip0_1410_5983">
                    <rect width="88.3636" height="48" fill="white" transform="translate(0.318359)" />
                  </clipPath>
                </defs>
              </svg>
            </Styles.Title>
            <Styles.Cross type="button" onClick={() => setIsOpened(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M14.3618 14.3618C14.8443 13.8794 15.6265 13.8794 16.1089 14.3618L24.5 22.7529L32.8911 14.3618C33.3735 13.8794 34.1557 13.8794 34.6382 14.3618C35.1206 14.8443 35.1206 15.6265 34.6382 16.1089L26.2471 24.5L34.6382 32.8911C35.1206 33.3735 35.1206 34.1557 34.6382 34.6382C34.1557 35.1206 33.3735 35.1206 32.8911 34.6382L24.5 26.2471L16.1089 34.6382C15.6265 35.1206 14.8443 35.1206 14.3618 34.6382C13.8794 34.1557 13.8794 33.3735 14.3618 32.8911L22.7529 24.5L14.3618 16.1089C13.8794 15.6265 13.8794 14.8443 14.3618 14.3618Z" fill="black" />
              </svg>
            </Styles.Cross>
          </Styles.Header>
          <SidebarMenu />
        </Styles.Sidebar>
      </Portal>
    </>
  );
};
