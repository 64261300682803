import React from 'react';
import { Container } from 'src/ui/Container';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import { BackArrow } from 'src/ui/Icons';

import { MobileSidebar } from '../MobileSidebar';
import { Breadcrumbs } from '../Breadcrumbs';

import * as Styles from './AppHeader.styles';

export const AppHeader = () => {
  const [query] = useQueryParams({
    backUrl: withDefault(StringParam, ''),
  });

  return (
    <Styles.Header>
      <Container>
        <Styles.Flex>
          {query.backUrl && (
            <Breadcrumbs
              to={query.backUrl}
              icon={<BackArrow width="32" height="32" />}
            />
          )}
          <Styles.Logo>
            <svg width="72" height="40" viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.9403 23.9925V23.0638C26.3184 23.7697 25.5778 24.122 24.7185 24.122C23.6923 24.122 22.8739 23.7801 22.2631 23.0962C21.6511 22.4123 21.3457 21.5056 21.3457 20.3775C21.3457 19.2376 21.7055 18.3076 22.4263 17.5849C23.1459 16.8634 24.0608 16.5021 25.1698 16.5021C25.8844 16.5021 26.4754 16.6756 26.9415 17.0228V12.5956H28.1853V23.9925H26.9403ZM24.951 22.9006C25.7175 22.9006 26.3802 22.5755 26.9403 21.924V18.2934C26.3703 17.9139 25.7954 17.7235 25.2156 17.7235C24.5009 17.7235 23.8964 17.9657 23.4055 18.4475C22.9134 18.9307 22.6674 19.5627 22.6674 20.3438C22.6674 21.1365 22.88 21.7608 23.3041 22.2167C23.7294 22.6726 24.2784 22.9006 24.951 22.9006Z" fill="#1191D0" />
              <path d="M35.7839 22.169L36.1721 23.1948C35.4983 23.836 34.6229 24.1559 33.546 24.1559C32.4172 24.1559 31.5097 23.8088 30.826 23.1145C30.1423 22.4202 29.7998 21.4915 29.7998 20.331C29.7998 19.2236 30.1213 18.3091 30.7629 17.5877C31.4046 16.8662 32.2862 16.5048 33.4051 16.5048C34.3274 16.5048 35.0989 16.817 35.7208 17.4413C36.3427 18.0656 36.653 18.8712 36.653 19.8595C36.653 20.1963 36.6271 20.489 36.5752 20.739H31.1981C31.2501 21.4229 31.4961 21.9604 31.9363 22.3503C32.3764 22.7415 32.9439 22.9358 33.6375 22.9358C34.55 22.9332 35.2646 22.6793 35.7839 22.169ZM33.3433 17.7237C32.7214 17.7237 32.2145 17.905 31.82 18.269C31.4256 18.6329 31.1932 19.1018 31.1203 19.6769H35.3635C35.3326 19.0694 35.1385 18.5915 34.7812 18.2443C34.4239 17.8972 33.9441 17.7237 33.3433 17.7237Z" fill="#1191D0" />
              <path d="M38.1924 23.9927V16.6667H39.4362V17.5617C39.6946 17.269 40.0321 17.0242 40.4463 16.8286C40.8605 16.633 41.2858 16.5359 41.721 16.5359C43.6584 16.5359 44.6277 17.6109 44.6277 19.7598V23.9927H43.3839V20.0033C43.3839 18.506 42.7262 17.756 41.4107 17.756C40.6231 17.756 39.9653 18.0708 39.4374 18.7003V23.9914H38.1924V23.9927Z" fill="#1191D0" />
              <path d="M46.4131 21.2071V14.6298H47.6569V16.6647H50.2211V17.8861H47.6569V21.0776C47.6569 22.2938 48.0711 22.9013 48.9007 22.9013C49.2938 22.9013 49.6623 22.7653 50.0035 22.4946L50.3917 23.5036C49.9145 23.9168 49.3198 24.1227 48.6039 24.1227C47.8374 24.1227 47.2798 23.8779 46.9336 23.3896C46.5862 22.9 46.4131 22.1734 46.4131 21.2071Z" fill="#1191D0" />
              <path d="M62.2813 19.1878V23.9906H61.0375V19.5453C61.0375 18.3511 60.5343 17.754 59.5303 17.754C59.126 17.754 58.7613 17.8511 58.4349 18.0467C58.1085 18.2423 57.8625 18.4975 57.6968 18.8122C57.7067 18.899 57.7116 19.0726 57.7116 19.3329V23.9894H56.4678V19.2682C56.4678 18.8122 56.338 18.4457 56.0796 18.1698C55.82 17.8926 55.4528 17.754 54.9768 17.754C54.1991 17.754 53.5883 18.0856 53.1432 18.7475V23.9906H51.8994V16.6647H53.1432V17.5921C53.4016 17.2774 53.7416 17.0222 54.1608 16.8266C54.5799 16.631 55.0126 16.5339 55.4589 16.5339C55.998 16.5339 56.4505 16.6479 56.819 16.8758C57.1862 17.1038 57.4384 17.4134 57.5732 17.8045C57.8316 17.425 58.1765 17.118 58.6068 16.8849C59.037 16.6517 59.5106 16.5352 60.0286 16.5352C61.5296 16.5352 62.2813 17.4198 62.2813 19.1878Z" fill="#1191D0" />
              <path d="M64.2236 27.2493V16.667H65.4674V17.5297C65.7159 17.2253 66.0547 16.9792 66.485 16.7888C66.9152 16.5984 67.3677 16.5038 67.845 16.5038C68.8291 16.5038 69.6142 16.8484 70.199 17.5375C70.7839 18.2265 71.0769 19.119 71.0769 20.216C71.0769 21.3455 70.7406 22.2833 70.0668 23.0332C69.3929 23.7819 68.5176 24.1562 67.4407 24.1562C66.6111 24.1562 65.9533 23.9878 65.4674 23.6511V27.2493H64.2236ZM67.4877 17.724C66.6581 17.724 65.9855 18.0607 65.4674 18.733V22.3636C66.0065 22.7431 66.6271 22.9335 67.3319 22.9335C68.0675 22.9335 68.6548 22.6835 69.0962 22.1848C69.5363 21.6862 69.7564 21.045 69.7564 20.264C69.7564 19.5049 69.5549 18.891 69.1506 18.4247C68.7463 17.9571 68.1924 17.724 67.4877 17.724Z" fill="#1191D0" />
              <path d="M0.922852 0C4.75562 2.46098 11.9884 8.41914 11.9884 20.5298C11.9884 31.8632 3.27197 38.7281 0.98467 40C5.49745 35.4899 9.0644 27.9386 8.95931 19.9469C8.80723 8.43598 3.76652 2.84956 0.922852 0Z" fill="#1191D0" />
              <path d="M10.2578 7.37378C12.6774 8.92679 17.2433 12.6882 17.2433 20.3341C17.2433 27.4891 11.7402 31.823 10.2974 32.6247C13.146 29.7778 15.3987 25.0099 15.3319 19.9649C15.2355 12.6999 12.053 9.17289 10.2578 7.37378Z" fill="#1191D0" />
            </svg>
          </Styles.Logo>
          <Styles.Sidebar>
            <MobileSidebar />
          </Styles.Sidebar>
        </Styles.Flex>
      </Container>
    </Styles.Header>
  );
};
