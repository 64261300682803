import { Branch } from 'src/constants/enums';

export const createBranch = (): Omit<Branch, 'id'> => ({
  branchName: '',
  userName: '',
  phone: '',
  email: '',
  organization: '',
  address: '',
  region: {
    name: '',
  },
});
