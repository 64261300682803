import React, { forwardRef } from 'react';

import { Modal } from 'src/ui/Modal';

import * as Styles from './LeavingRouteConfirmation.styles';

interface LeavingRouteConfirmationProps {
  onClose: (e: Event) => void;
}

export const LeavingRouteConfirmation = forwardRef<HTMLDialogElement, LeavingRouteConfirmationProps>((props, ref) => {
  const { onClose } = props;

  return (
    <Modal
      ref={ref}
      title="Сохранить изменения"
      onClose={onClose}
    >
      <Styles.ModalBody>
        <Styles.Form id="leaving-route" method="dialog">
          <Styles.Text>
            На странице есть несохраненные изменения. Сохранить их перед выходом?
          </Styles.Text>
        </Styles.Form>
        <Styles.Buttons>
          <Styles.SubmitButton
            form="leaving-route"
            variant="filled"
            size="big"
            value="yes"
          >
            Сохранить
          </Styles.SubmitButton>
          <Styles.CancelButton
            form="leaving-route"
            variant="outlined"
            size="big"
            value="no"
          >
            Выйти без сохранения
          </Styles.CancelButton>
        </Styles.Buttons>
      </Styles.ModalBody>
    </Modal>
  );
});
