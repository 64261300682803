import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import _find from 'lodash/find';
import { useDispatch } from 'react-redux';
import _omit from 'lodash/omit';
import axios, { AxiosError } from 'axios';

import { usersApi } from 'src/api/api';

import useTypedSelector from 'src/hooks/useTypedSelector';

import { setUserDataAC } from 'src/store/userProfile/actions';
import { selectIsCustomer, selectIsCustomerType } from 'src/store/userProfile/selectors';

import { AppPage } from 'src/ui/AppPage';
import { Container } from 'src/ui/Container';
import { FormField } from 'src/ui/FormField';
import { Select } from 'src/ui/Select';
import { Toast } from 'src/ui/Toast';
import { Input } from 'src/ui/Input';

import cities from 'src/assets/data/cities';

import { IBackendUserData } from 'src/types/userProfile';

import { SettingsForm } from './Settings.types';

import * as Styles from './Settings.styles';

export const Settings = () => {
  const {
    mainBranchName,
    organization,
    website,
    region,
    address,
    phone,
  } = useTypedSelector((store) => store.userProfile);

  const isCustomer = useTypedSelector(selectIsCustomer);
  const isCustomerType = useTypedSelector(selectIsCustomerType);

  const dispatch = useDispatch();

  const {
    handleSubmit: onSubmit,
    formState: {
      errors,
      isSubmitting,
      isSubmitSuccessful,
    },
    reset,
    control,
    register,
  } = useForm<SettingsForm>({
    defaultValues: {
      organization,
      mainBranchName,
      website,
      region,
      address,
      phone,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, {
        keepDefaultValues: true,
        keepDirtyValues: true,
      });
    }
  }, [isSubmitSuccessful, reset]);

  const handleSubmit = onSubmit(async (data) => {
    try {
      const body: IBackendUserData = {
        ..._omit(data, ['mainBranchName']),
        // TODO: это надо переделать на бэкенде
        region: data.region?.name!,
      };

      if (isCustomerType) {
        body.branchName = mainBranchName;
      }

      // TODO: из-за туду выше
      await usersApi.updateData(body);

      dispatch(setUserDataAC(data));

      Toast.success('Изменения сохранены!');
    } catch (ex) {
      if (axios.isAxiosError(ex)) {
        Toast.error((ex as AxiosError).response?.data.message || 'Произошла ошибка при сохранении настроек!');
      } else {
        Toast.error((ex as Error).message || 'Произошла ошибка при сохранении настроек!');
      }
    }
  });

  return (
    <AppPage>
      <Container variant="medium">
        <Styles.Title>
          Компания
        </Styles.Title>
        <form onSubmit={handleSubmit}>
          <Styles.Rows>
            {isCustomer && (
            <Styles.Row>
              <Styles.FormLabel required>
                Организация
              </Styles.FormLabel>
              <FormField error={errors?.organization?.message}>
                <Input
                  placeholder="Славная улыбка"
                  {...register('organization', {
                    required: {
                      value: true,
                      message: 'Укажите название организации',
                    },
                    maxLength: {
                      value: 200,
                      message: 'Название организации должно быть не больше 20 символов',
                    },
                    pattern: {
                      value: /^[А-Яа-я\s\d-_]*$/,
                      message: 'Некорректное название филиала',
                    },
                  })}
                  error={errors?.organization?.message}
                />
              </FormField>
            </Styles.Row>
            )}
            <Styles.Row>
              <Styles.FormLabel required>
                Название филиала
              </Styles.FormLabel>
              <FormField error={errors?.mainBranchName?.message}>
                <Input
                  disabled={!isCustomer}
                  placeholder="Филиал 1"
                  {...register('mainBranchName', {
                    required: {
                      value: true,
                      message: 'Укажите название филиала',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Название филиала должно быть не больше 20 символов',
                    },
                    pattern: {
                      value: /^[А-Яа-я\s\d-_]*$/,
                      message: 'Некорректное название филиала',
                    },
                  })}
                  error={errors?.mainBranchName?.message}
                />
              </FormField>
            </Styles.Row>
            <Styles.Row>
              <Styles.FormLabel>
                Сайт
              </Styles.FormLabel>
              <FormField error={errors?.website?.message}>
                <Input
                  disabled={!isCustomer}
                  placeholder="slavinasmile.ru"
                  {...register('website', {
                    maxLength: {
                      value: 200,
                      message: 'Сайт должен быть не больше 200 символов',
                    },
                    pattern: {
                      value: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\\=]*)?/gi,
                      message: 'Некорректный сайт',
                    },
                  })}
                  error={errors?.website?.message}
                />
              </FormField>
            </Styles.Row>
            <Styles.Row>
              <Styles.FormLabel required>
                Регион доставки
              </Styles.FormLabel>
              <FormField error={errors?.region?.name?.message}>
                <Controller
                  control={control}
                  name="region.name"
                  render={({ field }) => (
                    <Select
                      isDisabled={!isCustomer}
                      placeholder="Выберите регион"
                      value={_find(cities.map((city) => ({ label: city })), ['label', field.value])}
                      onChange={(value) => field.onChange(value?.label)}
                      options={cities.map((city) => ({ label: city }))}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ label }) => label}
                      isMulti={false}
                      isClearable
                      error={errors?.region?.name?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Выберите регион',
                    },
                  }}
                />
              </FormField>
            </Styles.Row>
            <Styles.Row>
              <Styles.FormLabel required>
                Адрес доставки
              </Styles.FormLabel>
              <FormField error={errors?.address?.message}>
                <Input
                  disabled={!isCustomer}
                  placeholder="Санкт-Петербург, ул. Медиков, д.12/1, оф. 100"
                  {...register('address', {
                    required: {
                      value: true,
                      message: 'Укажите адрес доставки',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Адрес доставки должен быть не больше 255 символов',
                    },
                  })}
                  error={errors?.address?.message}
                />
              </FormField>
            </Styles.Row>
          </Styles.Rows>
          {isCustomer && (
            <Styles.SubmitButton
              size="big"
              variant="filled"
              type="submit"
              loading={isSubmitting}
            >
              Сохранить изменения
            </Styles.SubmitButton>
          )}
        </form>
      </Container>
    </AppPage>
  );
};
