import React, { FC } from 'react';

interface ArrowProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

export const Arrow: FC<ArrowProps> = ({ width = 87, height = 87, color = 'currentColor' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 87 87" fill="none">
    <path d="M86.1781 45.4304L45.4089 86.1996C44.8973 86.6999 44.209 86.9782 43.4934 86.9742C42.7779 86.9702 42.0927 86.6842 41.5868 86.1782C41.0808 85.6722 40.7947 84.9871 40.7907 84.2715C40.7867 83.556 41.0651 82.8677 41.5654 82.3561L77.6947 46.2266L2.71795 46.2266C1.9971 46.2266 1.30578 45.9402 0.796068 45.4305C0.286354 44.9208 0 44.2295 0 43.5086C0 42.7878 0.286354 42.0965 0.796068 41.5868C1.30578 41.077 1.9971 40.7907 2.71795 40.7907L77.6947 40.7907L41.5654 4.66121C41.3083 4.40988 41.1037 4.11005 40.9634 3.7791C40.823 3.44814 40.7497 3.09263 40.7477 2.73315C40.7457 2.37367 40.815 2.01737 40.9517 1.68486C41.0883 1.35236 41.2895 1.05026 41.5437 0.796068C41.7979 0.541873 42.1 0.34063 42.4325 0.203991C42.765 0.0673517 43.1213 -0.00196901 43.4808 4.25556e-05C43.8403 0.00205412 44.1958 0.075358 44.5268 0.21571C44.8577 0.356062 45.1575 0.560673 45.4089 0.817696L86.1781 41.5869C86.6877 42.0966 86.974 42.7878 86.974 43.5086C86.974 44.2294 86.6877 44.9207 86.1781 45.4304Z" fill={color} />
  </svg>
);
