import React, { ComponentPropsWithoutRef } from 'react';

export const LinkArrow = (
  {
    width = 24,
    height = 24,
    color = 'currentColor',
    transform,
  }: ComponentPropsWithoutRef<'svg'>,
) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" transform={transform}>
    <path d="M10 18L16 12L10 6" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
