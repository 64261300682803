import styled from 'styled-components';

import { Colors } from 'src/constants/colors';
import { TypographyStyles } from '../Typography/Typography';

export const Tabs = styled.ul`
  column-gap: 24px;
  
  display: inline-flex;

  margin: 0;
  box-shadow: 0 2px 0 0 ${Colors.LittleDipper};
`;

export const Tab = styled.li``;

export const TabLabel = styled.label`
  display: inline-block;
  
  padding: 0 8px 8px;

  ${TypographyStyles.Text};
`;

export const TabInput = styled.input`
  position: absolute;

  appearance: none;

  &:checked + ${TabLabel} {
    box-shadow: 0 2px 0 0 ${Colors.UltraViolet};
  }
`;
