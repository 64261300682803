import { Colors } from 'src/constants/colors';
import { TypographyStyles } from 'src/ui/Typography/Typography';
import { css, styled } from 'styled-components';

const ProviderCellMixin = css`
  flex-basis: 92px;

  text-align: left;

  @container delivery-layout (min-width: 704px) {
    flex-basis: 104px;
  }

  @container delivery-layout (min-width: 688px) {
    flex-basis: 148px;
  }

  @container delivery-layout (min-width: 944px) {
    flex-basis: 204px;
  }

  @container delivery-layout (min-width: 1516px) {
    flex-basis: 240px;
  }
`;

const DeliveryDateCellMixin = css`
  flex-basis: 44px;

  text-align: left;
`;

const DeliveryTypeCellMixin = css`
  display: none;

  @container delivery-layout (min-width: 868px) {
    display: table-cell;

    flex-basis: 128px;

    text-align: left;
  }

  @container delivery-layout (min-width: 920px) {
    flex-basis: 140px;
  }
`;

const ManageCellMixin = css`
  display: none;

  @container delivery-layout (min-width: 1132px) {
    display: table-cell;

    flex-basis: 170px;

    text-align: left;
  }
`;

const EmailCellMixin = css`
  flex-basis: 140px;

  text-align: left;

  @container delivery-layout (min-width: 704px) {
    flex-basis: 230px;
  }

  @container delivery-layout (min-width: 688px) {
    flex-basis: 170px;
  }

  @container delivery-layout (min-width: 1159px) {
    flex-basis: 220px;
  }

  @container delivery-layout (min-width: 1516px) {
    flex-basis: 240px;
  }
`;

const PhoneCellMixin = css`
  flex-basis: 148px;

  text-align: left;
`;

const CostCellMixin = css`
  flex-basis: 74px;

  text-align: right;
`;

const DeliveryCommentCellMixin = css`
  flex-basis: 24px;

  .medium-container {
    display: flex;
  }

  .large-container {
    display: none;
  }

  @container delivery-layout (min-width: 1516px) {
    flex-grow: 1;

    .medium-container {
      display: none;
    }

    .large-container {
      display: flex;
    }
  }
`;

export const Title = styled.h5`
  margin: 0;
  margin-bottom: 16px;

  ${TypographyStyles.H4};

  @media screen and (min-width: 1280px) {
    margin-bottom: 24px;
  }
`;

export const TableTitle = styled.h6`  
  margin: 0;
  margin-bottom: 16px;

  ${TypographyStyles.H6};
`;

export const Table = styled.table`
  table-layout: fixed;
  
  width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody`
  &:not(:last-child) {
    border-bottom: 56px solid transparent;
  }
`;

export const TableHeadRow = styled.tr`
  container: delivery-layout / inline-size;

  column-gap: 16px;

  display: flex;

  height: 36px;

  @media screen and (min-width: 1280px) {
    column-gap: 20px;
  }
`;

export const TableHeadCell = styled.th`
  ${TypographyStyles.TextSmall};
  color: ${Colors.BluePlaza};
`;

export const TableHeadProviderCell = styled(TableHeadCell)`
  ${ProviderCellMixin};
`;

export const TableHeadDeliveryDateCell = styled(TableHeadCell)`
  ${DeliveryDateCellMixin};
`;

export const TableHeadDeliveryTypeCell = styled(TableHeadCell)`
  ${DeliveryTypeCellMixin};
`;

export const TableHeadManagerCell = styled(TableHeadCell)`
  ${ManageCellMixin};
`;

export const TableHeadEmailCell = styled(TableHeadCell)`
  ${EmailCellMixin};
`;

export const TableHeadPhoneCell = styled(TableHeadCell)`
  ${PhoneCellMixin};
`;

export const TableHeadCostCell = styled(TableHeadCell)`
  ${CostCellMixin};

  text-align: right;
`;

export const TableHeadDeliveryCommentCell = styled(TableHeadCell)`
  ${DeliveryCommentCellMixin};

  text-align: left;
`;

export const TableRow = styled.tr`
  container: delivery-layout / inline-size;

  column-gap: 16px;
  align-items: center;

  display: flex;

  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 52px;

  &:first-child {
    padding-top: 0;
    padding-bottom: 16px;
    min-height: 36px;
  }

  &:last-child:not(:first-child) {
    padding-top: 16px;
    padding-bottom: 0;
    min-height: 36px;
  }

  &:not(:last-child) {
    box-shadow: 0 0.5px 0 0 ${Colors.LittleDipper};
  }

  @media screen and (min-width: 1280px) {
    column-gap: 20px;
  }
`;

export const TableCell = styled.td``;

export const TableProviderCell = styled(TableCell)`
  ${ProviderCellMixin};
`;

export const TableDeliveryDateCell = styled(TableCell)`
  ${DeliveryDateCellMixin};
`;

export const TableDeliveryTypeCell = styled(TableCell)`
  ${DeliveryTypeCellMixin};
`;

export const TableManagerCell = styled(TableCell)`
  ${ManageCellMixin};
`;

export const TableEmailCell = styled(TableCell)`
  ${EmailCellMixin};
`;

export const TablePhoneCell = styled(TableCell)`
  ${PhoneCellMixin};
`;

export const TableCostCell = styled(TableCell)`
  ${CostCellMixin};
`;

export const TableDeliveryCommentCell = styled(TableCell)`
  ${DeliveryCommentCellMixin};
`;

export const TableCellText = styled.span<{ clamp?: number }>`
  display: block;

  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};

  ${({ clamp }) => clamp && `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${clamp};

    overflow: hidden;
  `}
`;
