import { CompareData, Good, IOffersGoods } from './types';

export const calculateGoodCost = ({ price, amount, offersGoods }: Good) => (
  Number(price) * Number(amount || offersGoods.amount)
);

export const calculateOfferCost = (goods: Good[]) => (
  goods.reduce((memo, g) => (
    memo + calculateGoodCost(g)
  ), 0)
);

export const getOffersWithMinimalDeliveryPrice = (offers: CompareData[]) => {
  let result: CompareData[] = [];

  let minDeliveryPrice = Infinity;

  offers.forEach((o) => {
    const { deliveryPrice } = o;

    if (deliveryPrice && (Number(deliveryPrice) < minDeliveryPrice)) {
      minDeliveryPrice = Number(deliveryPrice);
      result = [];
    }

    if (deliveryPrice && (Number(deliveryPrice) === minDeliveryPrice)) {
      result.push(o);
    }
  });

  return result;
};

export const getOffersWithMinimalGoodCost = (offers: CompareData[], goodId: Pick<IOffersGoods, 'id'>) => {
  let result: CompareData[] = [];

  let minGoodCost = Infinity;

  offers.forEach((o) => {
    const offerGood = o.goods.find(({ offersGoods }) => (
      offersGoods.id === goodId
    ))!;

    const goodCost = calculateGoodCost(offerGood);

    if (goodCost < minGoodCost) {
      minGoodCost = goodCost;
      result = [];
    }

    if (goodCost === minGoodCost) {
      result.push(o);
    }
  });

  return result;
};

export const getMinimalOfferCost = (offers: CompareData[]) => (
  Math.min(...offers.map((o) => calculateOfferCost(o.goods)))
);

export const MOCK = {
  active: false,
  offers: [
    {
      id: 222,
      email: null,
      organization: '32 Жемчужины',
      name: 'Константин',
      phone: '+7(412) 938-7436',
      website: null,
      deliveryDate: '2023-01-31',
      deliveryMaxDate: '2023-04-08',
      deliveryPrice: '0.00',
      deliveryComment: '',
      uploadedFile: null,
      active: true,
      isCustom: false,
      offerUser: {
        email: '32zh@mail.ru',
        website: null,
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '200',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '200',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '200',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Пропилен 6620, нить 6/0 (L=45см, 16мм, обратно-режущая) Medipac',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
    {
      id: 223,
      email: null,
      organization: 'Стом89',
      name: 'Ольга',
      phone: '+7(412) 938-7436',
      website: null,
      deliveryDate: '2023-01-31',
      deliveryMaxDate: '2023-04-08',
      deliveryPrice: '0.00',
      deliveryComment: '',
      uploadedFile: null,
      active: true,
      isCustom: false,
      offerUser: {
        email: '32zh@mail.ru',
        website: null,
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '200',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '200',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '1000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Пропилен 6620, нить 6/0 (L=45см, 16мм, обратно-режущая) Medipac',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
    {
      id: 225,
      email: '32zh@mail.ru',
      organization: 'DentPro',
      name: 'Наталья',
      phone: '+7(412) 938-7436',
      website: 'https://google.com',
      deliveryDate: '2023-01-31',
      deliveryMaxDate: null,
      deliveryPrice: '0',
      deliveryComment: null,
      uploadedFile: null,
      active: true,
      isCustom: true,
      offerUser: {
        email: '32zh@mail.ru',
        website: 'https://google.com',
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '200',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '200',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '1000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
    {
      id: 226,
      email: '32zh@mail.ru',
      organization: 'Dr. Zub',
      name: 'Ирина',
      phone: '+7(412) 938-7436',
      website: 'https://google.com',
      deliveryDate: '2023-01-31',
      deliveryMaxDate: null,
      deliveryPrice: '0',
      deliveryComment: 'Гарантируем своевременную доставку',
      uploadedFile: null,
      active: true,
      isCustom: true,
      offerUser: {
        email: '32zh@mail.ru',
        website: 'https://google.com',
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '350',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '350',
          amount: 5,
          comment: 'При заказе от 6 шт. сделаем скидку 5%, при заказе от 18 - скидка 10%',
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '1000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
    {
      id: 227,
      email: '32zh@mail.ru',
      organization: 'White',
      name: 'Илья',
      phone: '+7(412) 938-7436',
      website: 'https://google.com',
      deliveryDate: '2023-01-31',
      deliveryMaxDate: null,
      deliveryPrice: '0',
      deliveryComment: null,
      uploadedFile: null,
      active: true,
      isCustom: true,
      offerUser: {
        email: '32zh@mail.ru',
        website: 'https://google.com',
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '350',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '250',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '1000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
    {
      id: 228,
      email: '32zh@mail.ru',
      organization: 'Здоровые зубы',
      name: 'Петр',
      phone: '+7(412) 938-7436',
      website: 'https://google.com',
      deliveryDate: '2023-01-31',
      deliveryMaxDate: null,
      deliveryPrice: '0',
      deliveryComment: null,
      uploadedFile: null,
      active: true,
      isCustom: true,
      offerUser: {
        email: '32zh@mail.ru',
        website: 'https://google.com',
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '350',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '450',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '1000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
    {
      id: 229,
      email: '32zh@mail.ru',
      organization: 'Мир зубов',
      name: 'Инокентий',
      phone: '+7(412) 938-7436',
      website: 'https://google.com',
      deliveryDate: '2023-01-31',
      deliveryMaxDate: null,
      deliveryPrice: '0',
      deliveryComment: null,
      uploadedFile: null,
      active: true,
      isCustom: true,
      offerUser: {
        email: '32zh@mail.ru',
        website: 'https://google.com',
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '350',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '1100',
          amount: 15,
          comment: 'Только пачками по 6 упаковок',
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 3,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '1000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
    {
      id: 230,
      email: '32zh@mail.ru',
      organization: 'Доставка медицинского оборудования',
      name: 'Алена',
      phone: '+7(412) 938-7436',
      website: 'https://google.com',
      deliveryDate: '2023-01-31',
      deliveryMaxDate: null,
      deliveryPrice: '0',
      deliveryComment: null,
      uploadedFile: null,
      active: true,
      isCustom: true,
      offerUser: {
        email: '32zh@mail.ru',
        website: 'https://google.com',
      },
      offerUserEmployee: null,
      deliveryType: {
        name: 'Курьер',
      },
      goods: [
        {
          id: 1714,
          price: '350',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2566,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1715,
          price: '150000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2567,
            name: 'Салфетки целлюлозные',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
        {
          id: 1716,
          price: '1000',
          amount: null,
          comment: null,
          offersGoods: {
            id: 2568,
            name: 'Крем для рук',
            code: null,
            amount: 5,
            type: {
              name: 'упаковка',
            },
          },
        },
      ],
    },
  ],
  order: {
    date: '2023-06-12',
    goods: [
      {
        id: 2566,
        name: 'Крем для рук',
        code: null,
        amount: 5,
        type: {
          name: 'штука',
        },
      },
      {
        id: 2567,
        name: 'Салфетки целлюлозные',
        code: null,
        amount: 3,
        type: {
          name: 'упаковка',
        },
      },
      {
        id: 2568,
        name: 'Пропилен 6620, нить 6/0 (L=45см, 16мм, обратно-режущая) Medipac',
        code: null,
        amount: 2,
        type: {
          name: 'штука',
        },
      },
    ],
  },
  offersStatus: [],
};

export const OFFERS_MOCK = [
  {
    id: 1,
    organization: '32 жемчужины',
  },
  {
    id: 1,
    organization: 'Стом89',
  },
  {
    id: 1,
    organization: 'DentPro',
  },
  {
    id: 1,
    organization: 'DentalServiseCompany',
  },
  {
    id: 1,
    organization: 'Dr. Zub',
  },
  {
    id: 1,
    organization: 'Славная улыбка',
  },
  {
    id: 1,
    organization: 'White',
  },
  {
    id: 1,
    organization: 'Здоровые зубы',
  },
  {
    id: 1,
    organization: 'Мир зубов',
  },
  {
    id: 1,
    organization: 'ProDental',
  },
  {
    id: 1,
    organization: 'SuperT',
  },
  {
    id: 1,
    organization: 'Doctor W',
  },
  {
    id: 1,
    organization: 'Доставка медицинского оборужования',
  },
];
