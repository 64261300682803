import React, {
  FC,
  useMemo,
} from 'react';

import { TabsContext, TabsContextValue } from './Tabs.context';

import * as Styles from './Tabs.styles';

interface TabsProps {
  value: string;
  onChange: (value: string) => void;
}

export const Tabs: FC<TabsProps> = (props) => {
  const { children, value, onChange } = props;

  const context: TabsContextValue = useMemo(() => ({
    value,
    onChange: (e) => onChange(e.target.value),
  }), [value, onChange]);

  return (
    <TabsContext.Provider value={context}>
      <Styles.Tabs>
        {children}
      </Styles.Tabs>
    </TabsContext.Provider>
  );
};
