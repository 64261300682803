import styled from 'styled-components';

import { TypographyStyles } from 'src/ui/Typography/Typography';
import { Button } from 'src/ui/Button';

export const Header = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 16px;

  display: flex;

  margin-bottom: 32px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 56px;
  }
`;

export const Title = styled.h2`
  margin: 0;

  ${TypographyStyles.H3};

  @media screen and (min-width: 768px) {
    ${TypographyStyles.H2};
  }
`;

export const Buttons = styled.div`
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;

  display: flex;
`;

export const AddButton = styled(Button)`
  width: 100%;

  ${TypographyStyles.TextMedium};

  @media screen and (min-width: 768px) {
    width: auto;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;

  ${TypographyStyles.Text};

  @media screen and (min-width: 768px) {
    width: auto;
  }
`;
