import React, {
  FC, MouseEventHandler, useRef, useState,
} from 'react';

import useOnClickOutside from 'src/hooks/useOnClickOutside';

import { Button, ButtonProps } from '../Button';
import { Portal } from '../Portal';

import * as Styles from './ContextMenu.styles';

interface Position {
  top: number;
  left?: number;
  right?: number;
}

const ContextMenu: FC = (props) => {
  const { children } = props;

  const buttonRef = useRef(null);

  const [position, setPosition] = useState<Position | null>(null);

  useOnClickOutside(buttonRef, () => {
    setPosition(null);
  });

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    const {
      bottom,
      left,
    } = (e.target as HTMLButtonElement).getBoundingClientRect();

    if (window.innerWidth - left < 300) {
      setPosition({ top: bottom + window.scrollY + 8, right: window.innerWidth - left });
    } else {
      setPosition({ top: bottom + window.scrollY + 8, left });
    }
  };

  return (
    <>
      <Button ref={buttonRef} type="button" onClick={handleClick}>
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13.9235" cy="4" r="2" fill="#2F353B" />
          <circle cx="13.9235" cy="12" r="2" fill="#2F353B" />
          <circle cx="13.9235" cy="20" r="2" fill="#2F353B" />
        </svg>
      </Button>
      {position && (
        <Portal>
          <Styles.Tooltip style={position} onClick={(e) => e.stopPropagation()}>
            <Styles.List>
              {children}
            </Styles.List>
          </Styles.Tooltip>
        </Portal>
      )}
    </>
  );
};

interface ContextMenuItemProps extends ButtonProps {}

const ContextMenuItem: FC<ContextMenuItemProps> = (props) => {
  const { children, as, ...other } = props;

  return (
    <Styles.Item>
      <Styles.ItemButton {...other} forwardedAs={as} size="big" width="full">
        {children}
      </Styles.ItemButton>
    </Styles.Item>
  );
};

export const ContextMenuCompound = Object.assign(ContextMenu, {
  Item: ContextMenuItem,
});
