import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { Order } from 'src/constants/enums';

import { ContextMenu } from 'src/ui/ContextMenu';

import { useCloseOrder, useExportOrderToXlsWithPrefetching } from '../OrderRow.hooks';

import * as Styles from './OrderContextMenu.styles';

interface OrderContextMenuProps {
  order: Order;
}

export const OrderContextMenu: FC<OrderContextMenuProps> = (props) => {
  const { order } = props;

  const {
    exportOrderToXls,
    isExporting,
  } = useExportOrderToXlsWithPrefetching();

  const {
    closeOrder,
    isClosing,
  } = useCloseOrder();

  return (
    <Styles.TableContextMenu>
      <ContextMenu>
        <Styles.MenuButton
          forwardedAs={NavLink}
          to={`new-template/${order.id}`}
        >
          Повторить заявку
        </Styles.MenuButton>
        <ContextMenu.Item
          onClick={() => exportOrderToXls(order.id)}
          loading={isExporting}
        >
          Экспортировать в Excel
        </ContextMenu.Item>
        {!order.closeDate && (
          <ContextMenu.Item
            onClick={() => closeOrder(order.id)}
            loading={isClosing}
          >
            Закрыть
          </ContextMenu.Item>
        )}
      </ContextMenu>
    </Styles.TableContextMenu>
  );
};
