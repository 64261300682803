import styled from 'styled-components';

import { Colors } from 'src/constants/colors';

import { TypographyStyles } from '../Typography/Typography';

export const FormField = styled.div`
  position: relative;

  flex-direction: column;
  row-gap: 4px;

  display: flex;
`;

export const FormError = styled.span`
  ${TypographyStyles.TextSmall};
  color: ${Colors.ChineseGoldfish};
`;
