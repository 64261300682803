/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import {
  Switch, Route, matchPath, Redirect, useLocation,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';

import {
  RemindPass,
  ModalsWrapperControl,
  PopUps,
  ConfirmEmail,
  SuccessOrder,
} from './components';

import {
  AboutUs,
  Home,
  Contacts,
  CompareProposal,
  CustomerProposal,
  Settings,
  Personal,
  Agreement,
  Agreement2,
  ProviderProposal,
  Branches,
  Employees,
  EditEmployee,
  TermsOfUse,
  Market,
  PersonalMarket,
  EditPersonalMarketGood,
  MarketModeration,
  ExternalAuth,
  PurchaseHistory,
  CompareProposalNew,
  Profile,
  Help,
} from './pages';

import useTypedSelector from './hooks/useTypedSelector';
import useActions from './hooks/useActions';
import Page from './layout/Page';
import getCookie from './helpers/getCookie';
import { authApi } from './api/api';
import NotFound from './pages/NotFound/NotFound';
import LoaderCentered from './components/shared-components/Loader/LoaderCentered';
import { ModalsTypes } from './types/popUp';
import { setIsLoading } from './store/auth/actions';
import { store } from './store';
import CustomerTemplateProposal from './pages/CustomerTemplateProposal/CustomerTemplateProposal';
import routes from './routes';
import Logout from './pages/Logout';
import MarketHeader from './components/MarketHeader';
import MarketHeaderMobile from './components/MarketHeader/MarketHeaderMobile';
import useDecodedToken from './hooks/useDecodedToken';
import { OAuthServices } from './constants/enums';
import { selectIsCustomerType } from './store/userProfile/selectors';

import { LandingHeader } from './components/LandingHeader';
import applyParams from './helpers/applyParams';

const ROUTES_WITHOUT_REFRESH_AND_MODALS = [
  routes.confirmEmail,
  routes.forgetPassword,
];

const ROUTES_WITHOUT_REFRESH = [
  routes.termsOfUse,
  routes.contacts,
  routes.about,
  applyParams(routes.externalAuth, [OAuthServices.Yandex]),
  applyParams(routes.externalAuth, [OAuthServices.Google]),
];

function App() {
  const { getUserData, setIsFetching, setCurrentModal } = useActions();
  const {
    isAuth, isFetching, isActive,
  } = useTypedSelector((state) => state.auth);
  const { isProvider } = useTypedSelector((state) => state.userProfile);
  const { result } = useTypedSelector((state) => state.responseResult);
  const { isLoading } = useTypedSelector((state) => state.auth);
  const isCustomerType = useTypedSelector(selectIsCustomerType);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataRefreshed, setIsDataRefreshed] = useState(false);

  const tagManagerArgs = {
    gtmId: 'GTM-PNXGJGG',
  };

  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { is_admin } = useDecodedToken();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else setIsMobile(false);
    }

    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);

    const token: string | undefined | null = getCookie('Authentication') || localStorage.getItem('token');
    if (token) {
      localStorage.setItem('token', token);
      getUserData(token);
      setIsDataLoaded(true);
    } else {
      if (
        ROUTES_WITHOUT_REFRESH_AND_MODALS.includes(pathname)
          || ROUTES_WITHOUT_REFRESH.includes(pathname)
      ) {
        store.dispatch(setIsLoading(false));
        store.dispatch(setIsFetching(false));

        return;
      }

      authApi.refresh()
        .then((res) => {
          localStorage.setItem('token', res.data.token);
          getUserData(res.data.token);
          setIsDataRefreshed(true);
        }).catch(async () => {
          store.dispatch(setIsLoading(false));
          store.dispatch(setIsFetching(false));

          try {
            await authApi.logOut();

            localStorage.clear();

            if (window.location.pathname !== routes.index) {
              window.location.href = routes.index;
            }
          } catch (e) {
            console.warn(e);
          }
        });
    }
  }, []);

  const isReadyUser = isAuth && isActive;

  if (isReadyUser && isProvider === null) {
    return (
      <Route component={LoaderCentered} />
    );
  }

  return (
    <>
      {!ROUTES_WITHOUT_REFRESH_AND_MODALS.includes(pathname) && (
        <ModalsWrapperControl />
      )}
      <PopUps />
      <Switch>
        <Route exact path={routes.index}>
          <Home />
        </Route>
        <Route exact path={routes.help}>
          <Help />
        </Route>
        <Route exact path={routes.about}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<AboutUs />}
          />
        </Route>
        <Route exact path={routes.contacts}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<Contacts />}
          />
        </Route>
        <Route exact path={routes.termsOfUse}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<TermsOfUse />}
          />
        </Route>
        <Route exact path={routes.confirmEmail}>
          <ConfirmEmail />
        </Route>
        <Route exact path={routes.forgetPassword}>
          <RemindPass />
        </Route>
        <Route
          exact
          path={routes.logout}
          component={Logout}
        />
        <Route
          path={routes.market}
        >
          <Page
            semiLarge
            headerComponent={!isMobile ? <MarketHeader /> : <MarketHeaderMobile />}
            footerComponent={null}
            children={<Market />}
          />
        </Route>
        <Route
          exact
          path={routes.personalMarket.index}
        >
          <Page
            semiLarge
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<PersonalMarket />}
          />
        </Route>
        <Route exact path={routes.personalMarket.add}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<EditPersonalMarketGood />}
          />
        </Route>
        <Route exact path={routes.personalMarket.edit}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<EditPersonalMarketGood />}
          />
        </Route>
        {is_admin && (
          <Route exact path={routes.marketModeration}>
            <Page
              headerComponent={<LandingHeader />}
              footerComponent={null}
              children={<MarketModeration />}
            />
          </Route>
        )}
        <Route exact path={routes.externalAuth}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<ExternalAuth />}
          />
        </Route>
        {isCustomerType && (
          <Route exact path={routes.purchaseHistory}>
            <Page
              large
              headerComponent={<LandingHeader />}
              footerComponent={null}
              children={<PurchaseHistory />}
            />
          </Route>
        )}
        <Route exact path={routes.agreement}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<Agreement />}
          />
        </Route>
        <Route exact path={routes.privacyPolicy}>
          <Page
            headerComponent={<LandingHeader />}
            footerComponent={null}
            children={<Agreement2 />}
          />
        </Route>

        {isReadyUser && (
          <Switch>
            <Route exact path={routes.personal}>
              <Personal />
            </Route>
            <Route exact path={routes.profile}>
              <Profile />
            </Route>
            <Route exact path={routes.branches}>
              <Branches />
            </Route>
            <Route exact path={routes.employees.index}>
              <Page
                headerComponent={<LandingHeader />}
                footerComponent={null}
                children={<Employees />}
              />
            </Route>
            <Route exact path={routes.employees.add}>
              <Page
                headerComponent={<LandingHeader />}
                footerComponent={null}
                children={<EditEmployee />}
              />
            </Route>
            <Route exact path={routes.employees.edit}>
              <Page
                headerComponent={<LandingHeader />}
                footerComponent={null}
                children={<EditEmployee />}
              />
            </Route>
            <Route exact path={routes.settings}>
              <Settings />
            </Route>
            {result && (
              <Route exact path={routes.success}>
                <Page
                  headerComponent={<LandingHeader />}
                  footerComponent={null}
                  children={<SuccessOrder />}
                />
              </Route>
            )}
            {isProvider ? (
              <Switch>
                <Route exact path={routes.order}>
                  <Page children={<ProviderProposal />} />
                </Route>

                <Route exact path={routes.orderCompare}>
                  <CompareProposal />
                </Route>
                <Route exact path={routes.orderCompareNew}>
                  <CompareProposalNew />
                </Route>
                {isDataLoaded && <Route component={NotFound} />}
                {isLoading ? <Route component={LoaderCentered} /> : ''}
              </Switch>
            )
              : (
                <Switch>
                  <Route exact path={routes.new}>
                    <Page
                      large
                      headerComponent={<LandingHeader />}
                      footerComponent={null}
                      children={<CustomerProposal />}
                    />
                  </Route>
                  <Route exact path={routes.newTemplate}>
                    <Page
                      large
                      headerComponent={<LandingHeader />}
                      footerComponent={null}
                      children={<CustomerTemplateProposal />}
                    />
                  </Route>
                  <Route exact path={routes.orderCompare}>
                    <CompareProposal />
                  </Route>
                  <Route exact path={routes.orderCompareNew}>
                    <CompareProposalNew />
                  </Route>
                  {isDataLoaded && <Route component={NotFound} />}
                  {isLoading ? <Route component={LoaderCentered} /> : ''}
                </Switch>
              )}
          </Switch>
        )}

        {!isAuth && !isLoading && !isFetching && (!isDataLoaded && !isDataRefreshed)
          && matchPath(pathname, { path: routes.order })
          && setCurrentModal(ModalsTypes.login) && <Redirect to={routes.index} />}

        {!isAuth && !isLoading && !isFetching && (!isDataLoaded && !isDataRefreshed)
          && matchPath(pathname, { path: routes.orderCompare })
          && setCurrentModal(ModalsTypes.login) && <Redirect to={routes.index} />}

        {!isAuth && (isDataLoaded || isDataRefreshed)
          && (!matchPath(pathname, { path: routes.orderCompare })
            && !matchPath(pathname, { path: routes.personal })
            && !matchPath(pathname, { path: routes.new })
            && !matchPath(pathname, { path: routes.order })
            && !matchPath(pathname, { path: routes.settings })
            && !matchPath(pathname, { path: routes.branches })
            && !matchPath(pathname, { path: routes.employees.index })
          )
          ? <Route component={NotFound} />
          : <LoaderCentered />}

        {!isAuth && (!isDataLoaded && !isDataRefreshed) && isLoading
          && <Route component={LoaderCentered} />}

      </Switch>
    </>
  );
}

export default App;
