import React, { FC } from 'react';
import { Tooltip } from 'antd';

import { Comment } from 'src/ui/Icons';

import { Colors } from 'src/constants/colors';

import { CompareData } from '../../types';

import * as Styles from './DeliveryTable.styles';

interface DeliveryTableProps {
  offers: CompareData[];
}

export const DeliveryTable: FC<DeliveryTableProps> = (props) => {
  const { offers } = props;

  return (
    <div>
      <Styles.Title>
        Информация о доставке
      </Styles.Title>
      <Styles.Table>
        <Styles.TableHead>
          <Styles.TableHeadRow>
            <Styles.TableHeadProviderCell>
              Поставщик
            </Styles.TableHeadProviderCell>
            <Styles.TableHeadDeliveryDateCell>
              Дата
            </Styles.TableHeadDeliveryDateCell>
            <Styles.TableHeadDeliveryTypeCell>
              Способ
            </Styles.TableHeadDeliveryTypeCell>
            <Styles.TableHeadManagerCell>
              Менеджер
            </Styles.TableHeadManagerCell>
            <Styles.TableHeadEmailCell>
              Почта
            </Styles.TableHeadEmailCell>
            <Styles.TableHeadPhoneCell>
              Телефон
            </Styles.TableHeadPhoneCell>
            <Styles.TableHeadCostCell>
              Стоимость
            </Styles.TableHeadCostCell>
            <Styles.TableHeadDeliveryCommentCell>
              <span className="large-container">
                Комментарий
              </span>
            </Styles.TableHeadDeliveryCommentCell>
          </Styles.TableHeadRow>
        </Styles.TableHead>
        <Styles.TableBody>
          {offers.map((o) => (
            <Styles.TableRow>
              <Styles.TableProviderCell>
                <Styles.TableCellText clamp={1}>
                  {o.organization}
                </Styles.TableCellText>
              </Styles.TableProviderCell>
              <Styles.TableDeliveryDateCell>
                <Styles.TableCellText style={{ letterSpacing: '-3px' }}>
                  {o.deliveryDate
                    ? new Intl.DateTimeFormat('ru-RU', { month: 'numeric', day: 'numeric' }).format(Date.parse(o.deliveryDate))
                    : '-'}
                </Styles.TableCellText>
              </Styles.TableDeliveryDateCell>
              <Styles.TableDeliveryTypeCell>
                <Styles.TableCellText>
                  {o.deliveryType?.name}
                </Styles.TableCellText>
              </Styles.TableDeliveryTypeCell>
              <Styles.TableManagerCell>
                <Styles.TableCellText>
                  {o.name}
                </Styles.TableCellText>
              </Styles.TableManagerCell>
              <Styles.TableEmailCell>
                <Styles.TableCellText style={{ letterSpacing: '-0.4px' }}>
                  {o.offerUser.email}
                </Styles.TableCellText>
              </Styles.TableEmailCell>
              <Styles.TablePhoneCell>
                <Styles.TableCellText style={{ letterSpacing: '-1.7px' }}>
                  {o.phone}
                </Styles.TableCellText>
              </Styles.TablePhoneCell>
              <Styles.TableCostCell>
                <Styles.TableCellText>
                  {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(Number(o.deliveryPrice))}
                </Styles.TableCellText>
              </Styles.TableCostCell>
              <Styles.TableDeliveryCommentCell>
                <Styles.TableCellText className="large-container" clamp={2}>
                  {o.deliveryComment}
                </Styles.TableCellText>
                {o.deliveryComment && (
                  <Tooltip
                    className="medium-container"
                    title={o.deliveryComment}
                    placement="topRight"
                  >
                    <div>
                      <Comment color={Colors.UltraViolet} />
                    </div>
                  </Tooltip>
                )}
              </Styles.TableDeliveryCommentCell>
            </Styles.TableRow>
          ))}
        </Styles.TableBody>
      </Styles.Table>
    </div>
  );
};
