import React, { FC } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { Order } from 'src/constants/enums';

import { OrderContextMenu } from './OrderContextMenu';
import { OrderDrawer } from './OrderDrawer';

import * as Styles from '../OrdersTable.styles';

interface OrderRowProps {
  order: Order;
}

export const OrderRow: FC<OrderRowProps> = (props) => {
  const { order } = props;

  const history = useHistory();

  return (
    <Styles.TableRow onClick={() => history.push(`/order/compare/${order.id}?backUrl=${window.location.pathname}`)}>
      <Styles.TableIdCell>
        <Styles.TableCellText clamp={1}>
          №
          {order.id}
          {' '}
          от
          {' '}
          {new Date(order.date).toLocaleDateString()}
        </Styles.TableCellText>
      </Styles.TableIdCell>
      <Styles.TableDateCell />
      <Styles.TableProviderTypeCell />
      <Styles.TableProvidersCell />
      <Styles.TableGoodsCountCell>
        {order.goodsCount}
      </Styles.TableGoodsCountCell>
      <Styles.TableOffersCountCell>
        {order.offersCount}
      </Styles.TableOffersCountCell>
      <Styles.TableActionsCell>
        <Styles.TableCellButton
          forwardedAs={NavLink}
          to={`new-template/${order.id}`}
          variant="outlined"
          size="small"
        >
          Повторить
        </Styles.TableCellButton>
        <OrderContextMenu order={order} />
        <OrderDrawer order={order} />
      </Styles.TableActionsCell>
    </Styles.TableRow>
  );
};
