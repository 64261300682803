import styled from 'styled-components';
import { transparentize } from 'polished';

import { Colors } from 'src/constants/colors';

import { TypographyStyles } from '../Typography/Typography';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${transparentize(0.3, Colors.BluePlaza)};
`;

export const Drawer = styled.div`
  position: absolute;
  bottom: 0;

  box-shadow: 0px 0.14px 1.355px 0px rgba(66, 49, 137, 0.01), 0px 0.317px 3.08px 0px rgba(66, 49, 137, 0.02), 0px 0.552px 5.36px 0px rgba(66, 49, 137, 0.02), 0px 0.877px 8.515px 0px rgba(66, 49, 137, 0.03), 0px 1.352px 13.137px 0px rgba(66, 49, 137, 0.03), 0px 2.11px 20.5px 0px rgba(66, 49, 137, 0.03), 0px 3.503px 34.033px 0px rgba(66, 49, 137, 0.04), 0px 7px 68px 0px rgba(66, 49, 137, 0.07);
  border-radius: 24px 24px 0 0;
  padding: 16px 32px 56px;
  width: 100%;

  background-color: ${Colors.White};
`;

export const Line = styled.span`
  display: block;

  margin: 0 auto 56px;
  border-radius: 8px;
  width: 64px;
  height: 4px;

  background: ${Colors.Gainsboro};
`;

export const Title = styled.h4`
  margin-bottom: 32px;

  ${TypographyStyles.H4};
  color: ${Colors.BluePlaza};
`;
