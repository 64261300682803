import styled from 'styled-components';

import { Button } from 'src/ui/Button';
import { TypographyStyles } from 'src/ui/Typography/Typography';

export const ModalBody = styled.div`
  width: 656px;
`;

export const Form = styled.form`
  flex-direction: column;
  row-gap: 24px;

  display: flex;

  margin-bottom: 56px;
`;

export const FormLabel = styled.label`
  display: block;

  margin-bottom: 8px;

  ${TypographyStyles.Text};
`;

export const Buttons = styled.div`
  column-gap: 16px;

  display: flex;
`;

export const SettingsButton = styled(Button)`
  ${TypographyStyles.TextMedium};

  width: 100%;
  
  @media screen and (min-width: 768px) {
    width: auto;
  }
`;

export const SubmitButton = styled(Button)`
  ${TypographyStyles.TextMedium};
`;

export const CancelButton = styled(Button)`
  ${TypographyStyles.Text};
`;
