import React, { FC } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { renderToString } from 'react-dom/server';

import * as Styles from './Toast.styles';
import { Close } from '../Icons';

let toast: Element;

const TOAST_TYPES = ['success', 'error'] as const;
type ToastType = typeof TOAST_TYPES[number];

interface ToastProps {
  type: ToastType;
  text: string;
}

const Toast: FC<ToastProps> = (props) => {
  const { type, text } = props;

  return (
    <Styles.ToastBody>
      <Styles.ToastIcon>
        {type === 'success' && (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#47A76A" />
            <path d="M22.0822 10.8572C22.4372 10.4342 23.0679 10.379 23.491 10.734C23.9141 11.089 23.9693 11.7197 23.6142 12.1428L15.258 22.1014C15.0527 22.3461 14.7556 22.4678 14.4576 22.4579L14.4438 22.4578C14.1892 22.4564 13.9366 22.3589 13.7427 22.1649L8.79289 17.2152C8.40235 16.8247 8.40238 16.1915 8.79292 15.801C9.1834 15.4105 9.81657 15.4104 10.2071 15.801L14.4085 20.0024L22.0822 10.8572Z" fill="white" />
          </svg>
        )}
        {type === 'error' && (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#F34723" />
            <path d="M20.1924 10.2929C20.5829 9.90237 21.2161 9.90237 21.6066 10.2929C21.9971 10.6834 21.9971 11.3166 21.6066 11.7071L17.364 15.9497L21.6066 20.1924C21.9971 20.5829 21.9971 21.2161 21.6066 21.6066C21.2161 21.9971 20.5829 21.9971 20.1924 21.6066L15.9497 17.364L11.7071 21.6066C11.3166 21.9971 10.6834 21.9971 10.2929 21.6066C9.90237 21.2161 9.90237 20.5829 10.2929 20.1924L14.5355 15.9497L10.2929 11.7071C9.90237 11.3166 9.90237 10.6834 10.2929 10.2929C10.6834 9.90237 11.3166 9.90237 11.7071 10.2929L15.9497 14.5355L20.1924 10.2929Z" fill="white" />
          </svg>
        )}
      </Styles.ToastIcon>
      <Styles.ToastText>
        {text}
      </Styles.ToastText>
      <Styles.CloseButton onClick={() => unmountComponentAtNode(toast)}>
        <Close width={24} height={24} />
      </Styles.CloseButton>
    </Styles.ToastBody>
  );
};

const renderToast = (type: ToastType) => (content: string, ms: number = 10000) => {
  if (!toast) {
    const template = document.createElement('template');
    template.innerHTML = renderToString(<Styles.Toast />);

    document.body.appendChild(template.content);

    toast = document.querySelector(`.${Styles.Toast.styledComponentId}`)!;
  }

  setTimeout(() => {
    unmountComponentAtNode(toast);
  }, ms);

  render(
    <Toast
      type={type}
      text={content}
    />,
    toast,
  );
};

type ReducerType = Record<ToastType, (content: string, ms?: number) => void>;

export const ToastApi = TOAST_TYPES
  .reduce<ReducerType>(
  (memo, type) => ({
    ...memo,
    [type]: renderToast(type),
  }),
  {} as ReducerType,
);
