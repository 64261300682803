import React, { FC, MouseEventHandler, useMemo } from 'react';

import { DrawerContext, DrawerContextValue } from './Drawer.context';

import { Portal } from '../Portal';

import * as Styles from './Drawer.styles';

interface DrawerProps {
  title: string;
  onClose: () => void;
}

export const Drawer: FC<DrawerProps> = (props) => {
  const {
    title,
    children,
    onClose,
  } = props;

  const context: DrawerContextValue = useMemo(() => ({ }), []);

  const handleOverlayClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    onClose();
  };

  return (
    <DrawerContext.Provider value={context}>
      <Portal>
        <Styles.Overlay onClick={handleOverlayClick} />
        <Styles.Drawer onClick={(e) => e.stopPropagation()}>
          <Styles.Line />
          <Styles.Title>
            {title}
          </Styles.Title>
          {children}
        </Styles.Drawer>
      </Portal>
    </DrawerContext.Provider>
  );
};
