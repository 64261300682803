import React from 'react';

import { CompareData } from '../../types';

import { GoodTable } from './CostRow';

import * as Styles from './CostTable.styles';

interface CostTableProps {
  offers: CompareData[];
}

export const CostTable = (props: CostTableProps) => {
  const { offers } = props;

  const [{ goods = [] }] = offers;

  return (
    <div>
      <Styles.Title>
        Сравнение предложений
      </Styles.Title>
      {goods.map((g, gindx) => (
        <GoodTable
          good={g}
          goodIndex={gindx}
          offers={offers}
        />
      ))}
    </div>
  );
};
