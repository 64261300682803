import React, { FC } from 'react';

import { Order } from 'src/constants/enums';

import * as Styles from './OrdersTable.styles';
import { OrderRow } from './OrderRow';

interface OrdersTableProps {
  items: Order[];
}

export const OrdersTable: FC<OrdersTableProps> = (props) => {
  const { items } = props;

  return (
    <Styles.Table>
      <Styles.TableHead>
        <Styles.TableHeadRow>
          <Styles.TableHeadIdCell>
            Заявка
          </Styles.TableHeadIdCell>
          <Styles.TableHeadDateCell>
            Обновлено
          </Styles.TableHeadDateCell>
          <Styles.TableHeadProviderTypeCell>
            Тип поставки
          </Styles.TableHeadProviderTypeCell>
          <Styles.TableHeadProvidersCell>
            Поставщики
          </Styles.TableHeadProvidersCell>
          <Styles.TableHeadGoodsCountCell>
            Позиции
          </Styles.TableHeadGoodsCountCell>
          <Styles.TableHeadOffersCountCell>
            Ответы
          </Styles.TableHeadOffersCountCell>
          <Styles.TableHeadActionsCell />
        </Styles.TableHeadRow>
      </Styles.TableHead>
      <Styles.TableBody>
        {items.map((order) => (
          <OrderRow order={order} />
        ))}
      </Styles.TableBody>
    </Styles.Table>
  );
};
