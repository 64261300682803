import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';

export const Sidebar = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(100%);

  flex-direction: column;

  display: flex;

  width: 100%;
  height: 100%;
  max-width: 375px;

  background-color: white;

  transition: transform 0.3s ease-in-out;

  overflow: auto;

  ${(props) => props.visible && `
    transform: translate(0);
  `}
`;

export const Overlay = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(100%);

  width: 100%;
  height: 100%;

  background-color: #2f353b4d;

  ${(props) => props.visible && `
    transform: translate(0);
  `}
`;

export const Header = styled.div`
  position: relative;

  justify-content: flex-end;
  align-items: center;

  display: flex;

  margin-bottom: 32px;
  padding: 16px;
`;

export const Burger = styled.button``;

export const Cross = styled.button``;

export const Title = styled(NavLink)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
