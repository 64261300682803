import styled, { css } from 'styled-components';

import { ContainerProps } from './Container.types';

const getContainerStyles = ({ variant = 'large' }: ContainerProps) => {
  switch (variant) {
    case 'large': {
      return css`
        max-width: 1600px;
      `;
    }

    case 'medium': {
      return css`
        max-width: 100%;

        @media screen and (min-width: 1280px) {
          max-width: 870px;
        }

        @media screen and (min-width: 1920px) {
          max-width: 1260px;
        }
      `;
    }

    case 'small': {
      return css`
        max-width: 704px;
      `;
    }

    default: {
      return css``;
    }
  }
};

export const Container = styled.div<ContainerProps>`
  max-width: 1600px;
  width: 100%;

  ${getContainerStyles};
`;
