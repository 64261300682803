import styled from 'styled-components';

import { ContextMenu } from 'src/ui/ContextMenu';

export const MenuButton = styled(ContextMenu.Item)` 
  display: flex;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const TableContextMenu = styled.div`
  display: none;

  @container order-layout (min-width: 688px) {
    display: block;
  }
`;
