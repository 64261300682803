import React, { ComponentPropsWithoutRef, FC } from 'react';

export const Spinner: FC<ComponentPropsWithoutRef<'svg'>> = ({ width = 28, height = 28 }) => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 14C26 11.6266 25.2962 9.30655 23.9776 7.33316C22.6591 5.35977 20.7849 3.8217 18.5922 2.91345C16.3995 2.00519 13.9867 1.76755 11.6589 2.23058C9.33114 2.6936 7.19295 3.83649 5.51472 5.51472C3.83649 7.19295 2.6936 9.33114 2.23058 11.6589C1.76755 13.9867 2.00519 16.3995 2.91345 18.5922C3.8217 20.7849 5.35977 22.6591 7.33316 23.9776C9.30655 25.2962 11.6266 26 14 26" stroke="url(#paint0_linear_868_56048)" strokeWidth="4" />
    <defs>
      <linearGradient id="paint0_linear_868_56048" x1="14" y1="0.5" x2="14" y2="26" gradientUnits="userSpaceOnUse">
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
