import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';

import { useExportOrderToXls } from 'src/hooks/useExportOrderToXls';

import { usersApi } from 'src/api/api';

export const useCloseOrder = () => {
  const queryClient = useQueryClient();

  const orderMutation = useMutation({
    mutationFn: (orderId: number) => usersApi.closeCart(orderId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
  });

  const closeOrder = async (orderId: number) => {
    try {
      await orderMutation.mutateAsync(orderId);
    } catch (ex) {
      message.error('Произошла ошибка при закрытии заявки!');
    }
  };

  return {
    closeOrder,
    isClosing: orderMutation.isLoading,
  };
};

export const useExportOrderToXlsWithPrefetching = () => {
  const queryClient = useQueryClient();

  const { exportOrder } = useExportOrderToXls();

  const [exportStatus, setExportStatus] = useState('idle');

  const exportOrderToXls = async (orderId: number) => {
    try {
      setExportStatus('loading');

      const fullOrder = await queryClient.fetchQuery({
        queryKey: ['orders', orderId],
        queryFn: () => usersApi.getTemplate(null, orderId),
      });

      await exportOrder({
        goods: fullOrder.data.goods.map((good) => ({
          ...good,
          type: good.type.name,
        })),
      });
    } catch (ex) {
      console.warn(ex);
    } finally {
      setExportStatus('idle');
    }
  };

  return {
    isExporting: exportStatus === 'loading',
    exportOrderToXls,
  };
};
