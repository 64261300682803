import { css, styled } from 'styled-components';

import { Colors } from 'src/constants/colors';
import { spinner } from 'src/constants/styles';

import { ButtonProps } from './Button.types';

const getButtonSizeStyles = ({ size }: ButtonProps) => {
  switch (size) {
    case 'big': {
      return css`
        gap: 0 16px;
        padding: 16px 32px;
      `;
    }

    case 'small': {
      return css`
        gap: 0 12px;
        padding: 8px 24px;
      `;
    }

    default: {
      return css``;
    }
  }
};

const getButtonVariantStyles = ({ variant, loading }: ButtonProps) => {
  switch (variant) {
    case 'filled': {
      return css`
        color: ${Colors.White};

        background-color: ${Colors.UltraViolet};
        
        &:hover {
          color: ${Colors.White};

          background-color: ${Colors.DarkSlateBlue};
        }

        &:focus, &:active {
          background-color: ${Colors.MajesticEggplant};
        }

        &:disabled {
          color: ${Colors.White};

          background-color: ${Colors.RoughStone};
        }

        ${loading && `
          pointer-events: none;

          color: ${Colors.White};
        `}
      `;
    }

    case 'outlined': {
      return css`
        box-shadow: 0 0 0 1px ${Colors.UltraViolet};
        
        background-color: ${Colors.White};
        
        color: ${Colors.MajesticEggplant};

        &:hover {
          box-shadow: 0 0 0 1px ${Colors.DarkSlateBlue};
        }

        &:focus, &:active {
          box-shadow: 0 0 0 1px ${Colors.MajesticEggplant};
        }

        &:disabled {
          box-shadow: 0 0 0 1px ${Colors.RoughStone};

          color: ${Colors.RoughStone};
        }

        ${loading && `
          pointer-events: none;

          color: ${Colors.UltraViolet};
        `}
      `;
    }

    default: {
      return css`
        &:disabled {
          color: ${Colors.RoughStone};

          ${loading && `
            pointer-events: none;

            color: ${Colors.RoughStone};
          `}
        }
      `;
    }
  }
};

const getButtonWidthStyles = ({ width }: ButtonProps) => {
  switch (width) {
    case 'full': {
      return css`
        width: 100%;
      `;
    }

    default: {
      return css``;
    }
  }
};

export const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !['size', 'variant', 'width'].includes(prop),
})<ButtonProps>`
  position: relative;

  justify-content: center;
  align-items: center;
  
  display: flex;

  border-radius: 4px;

  background-color: transparent;

  ${getButtonSizeStyles};
  ${getButtonVariantStyles};
  ${getButtonWidthStyles};
`;

export const Loader = styled.span`
  position: absolute;

  align-items: center;
  justify-content: center;
  
  display: flex;

  border-radius: 4px;
  width: 100%;
  height: 100%;

  background: inherit;
`;

export const Spinner = styled.span`
  display: block;
  
  animation: ${spinner} 1s linear infinite;
`;
