import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';

import { usersApi } from 'src/api/api';

import { CartDataArrayItem } from '../types';

interface CartContextValue {
  cart: CartDataArrayItem[];
  getCart: () => Promise<void>;
}

const CartContext = createContext<CartContextValue>({
  cart: [],
  getCart: async () => {},
});

export const useCartContext = () => useContext(CartContext);

export const CartContextProvider: FC = ({ children }) => {
  const [cart, setCart] = useState<CartDataArrayItem[]>([]);

  const { id: orderId } = useParams<{ id: string }>();

  const getCart = useCallback(async () => {
    const { data } = await usersApi.getCartData(Number(orderId));

    setCart(data);
  }, []);

  useEffect(() => {
    getCart();
  }, []);

  const context = useMemo(() => ({
    cart,
    getCart,
  }), [cart, getCart]);

  return (
    <CartContext.Provider value={context}>
      {children}
    </CartContext.Provider>
  );
};
