import React, { FC } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';

import { BranchesListItem } from './BranchesListItem';

import { BranchesForm } from './BranchesList.types';

import * as Styles from './BranchesList.styles';

interface BranchesListProps {
  fields: FieldArrayWithId<BranchesForm, 'branches', 'id'>[];
  remove: UseFieldArrayRemove;
}

export const BranchesList: FC<BranchesListProps> = (props) => {
  const { fields, remove } = props;

  return (
    <Styles.Branches>
      {fields.map((branch, index) => (
        <li key={branch.id}>
          <BranchesListItem
            index={index}
            canRemove={fields.length > 1}
            onRemove={(branchIndex) => remove(branchIndex)}
          />
        </li>
      ))}
    </Styles.Branches>
  );
};
