import React, { useState } from 'react';

import { AppPage } from 'src/ui/AppPage';
import { Container } from 'src/ui/Container';
import { LinkArrow } from 'src/ui/Icons';

import CreateOrderImage1920 from './assets/create-order@1920.png';
import CreateOrderImage1280 from './assets/create-order@1280.png';
import CreateOrderImage1024 from './assets/create-order@1024.png';
import CreateOrderImage768 from './assets/create-order@768.png';
import CreateOrderImage375 from './assets/create-order@375.png';

import GoodNameImage1920 from './assets/good-name@1920.png';
import GoodNameImage1280 from './assets/good-name@1280.png';
import GoodNameImage1024 from './assets/good-name@1024.png';
import GoodNameImage768 from './assets/good-name@768.png';
import GoodNameImage375 from './assets/good-name@375.png';

import UploadOwnBaseImage1920 from './assets/upload-own-base@1920.png';
import UploadOwnBaseImage1280 from './assets/upload-own-base@1280.png';
import UploadOwnBaseImage1024 from './assets/upload-own-base@1024.png';
import UploadOwnBaseImage768 from './assets/upload-own-base@768.png';
import UploadOwnBaseImage375 from './assets/upload-own-base@375.png';

import GoodCountImage1920 from './assets/good-count@1920.png';
import GoodCountImage1280 from './assets/good-count@1280.png';
import GoodCountImage1024 from './assets/good-count@1024.png';
import GoodCountImage768 from './assets/good-count@768.png';
import GoodCountImage375 from './assets/good-count@375.png';

import AddGoodLineImage1920 from './assets/add-good-line@1920.png';
import AddGoodLineImage1280 from './assets/add-good-line@1280.png';
import AddGoodLineImage1024 from './assets/add-good-line@1024.png';
import AddGoodLineImage768 from './assets/add-good-line@768.png';
import AddGoodLineImage375 from './assets/add-good-line@375.png';

import AddGoodFromMarketImage1920 from './assets/add-good-from-market@1920.png';
import AddGoodFromMarketImage1280 from './assets/add-good-from-market@1280.png';
import AddGoodFromMarketImage1024 from './assets/add-good-from-market@1024.png';
import AddGoodFromMarketImage768 from './assets/add-good-from-market@768.png';
import AddGoodFromMarketImage375 from './assets/add-good-from-market@375.png';

import AddGoodFromExcelImage1920 from './assets/add-good-from-excel@1920.png';
import AddGoodFromExcelImage1280 from './assets/add-good-from-excel@1280.png';
import AddGoodFromExcelImage1024 from './assets/add-good-from-excel@1024.png';
import AddGoodFromExcelImage768 from './assets/add-good-from-excel@768.png';
import AddGoodFromExcelImage375 from './assets/add-good-from-excel@375.png';

import OrderActionsImage1920 from './assets/order-actions@1920.png';
import OrderActionsImage1280 from './assets/order-actions@1280.png';
import OrderActionsImage1024 from './assets/order-actions@1024.png';
import OrderActionsImage768 from './assets/order-actions@768.png';
import OrderActionsImage375 from './assets/order-actions@375.png';

import WatchActiveOrdersImage1920 from './assets/watch-active-orders@1920.png';
import WatchActiveOrdersImage1280 from './assets/watch-active-orders@1280.png';
import WatchActiveOrdersImage1024 from './assets/watch-active-orders@1024.png';
import WatchActiveOrdersImage768 from './assets/watch-active-orders@768.png';
import WatchActiveOrdersImage375 from './assets/watch-active-orders@375.png';

import WatchAllOffersImage1920 from './assets/watch-all-offers@1920.png';
import WatchAllOffersImage1280 from './assets/watch-all-offers@1280.png';
import WatchAllOffersImage1024 from './assets/watch-all-offers@1024.png';
import WatchAllOffersImage768 from './assets/watch-all-offers@768.png';
import WatchAllOffersImage375 from './assets/watch-all-offers@375.png';

import UploadPdfOfferImage1920 from './assets/upload-pdf-offer@1920.png';
import UploadPdfOfferImage1280 from './assets/upload-pdf-offer@1280.png';
import UploadPdfOfferImage1024 from './assets/upload-pdf-offer@1024.png';
import UploadPdfOfferImage768 from './assets/upload-pdf-offer@768.png';
import UploadPdfOfferImage375 from './assets/upload-pdf-offer@375.png';

import * as Styles from './Help.styles';

const defaultOptions = {
  howServiceHelp: false,
  howServiceWork: false,
  howMuchEconomy: false,
  howMakeOrder: false,
  howLeaveComment: false,
  howWatchOffers: false,
  howMuchWait: false,
  whatDoIfProviderNotAnswer: false,
  howMuchDeliveryCost: false,
  howMuchOfferLive: false,
  isPreOrderChanged: false,
  howRequestGoods: false,
  anyQuestions: false,
};

export const Help = () => {
  const [options, setOptions] = useState(defaultOptions);

  return (
    <AppPage>
      <Container variant="small">
        <Styles.Header>
          <Styles.Title>
            Помощь
          </Styles.Title>
        </Styles.Header>
        <Styles.Blocks>
          <Styles.Block>
            <Styles.BlockTitle>
              О dentmp
            </Styles.BlockTitle>
            <Styles.BlockOptions>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howServiceHelp: !options.howServiceHelp })}>
                  Чем dentmp может мне помочь?
                  <LinkArrow transform={options.howServiceHelp ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howServiceHelp && (
                  <Styles.BlockOptionList>
                    <Styles.BlockOptionItem>
                      <span>
                        &#183;
                      </span>
                      <Styles.BlockOptionText>
                        Упростить процедуру закупок большого количества наименований товаров.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionItem>
                    <Styles.BlockOptionItem>
                      <span>
                        &#183;
                      </span>
                      <Styles.BlockOptionText>
                        Провести качественный анализ цен по входящим предложениям. Цены
                        <Styles.Breakline />
                        {' '}
                        всегда актуальны, и значит, анализ цен будет качественный.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionItem>
                    <Styles.BlockOptionItem>
                      <span>
                        &#183;
                      </span>
                      <Styles.BlockOptionText>
                        Снизить расходы на закупку материалов.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionItem>
                    <Styles.BlockOptionItem>
                      <span>
                        &#183;
                      </span>
                      <Styles.BlockOptionText>
                        Путём сравнения предложений от&nbsp;различных поставщиков сервис позволяет заказчику найти наиболее выгодное предложение.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionItem>
                    <Styles.BlockOptionItem>
                      <span>
                        &#183;
                      </span>
                      <Styles.BlockOptionText>
                        Сервис позволяет найти аналоги товара, который перестали поставлять в&nbsp;Россию.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionItem>
                  </Styles.BlockOptionList>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howServiceWork: !options.howServiceWork })}>
                  Как работает сервис?
                  <LinkArrow transform={options.howServiceWork ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howServiceWork && (
                  <Styles.BlockOptionSteps>
                    <Styles.BlockOptionStep>
                      <span>
                        1.
                      </span>
                      <Styles.BlockOptionText>
                        Оставьте заявку на нашем сайте.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionStep>
                    <Styles.BlockOptionStep>
                      <span>
                        2.
                      </span>
                      <Styles.BlockOptionText>
                        Соберите лучшие предложения от&nbsp;наших поставщиков.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionStep>
                    <Styles.BlockOptionStep>
                      <span>
                        3.
                      </span>
                      <Styles.BlockOptionText>
                        Сравните все предложения.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionStep>
                    <Styles.BlockOptionStep>
                      <span>
                        4.
                      </span>
                      <Styles.BlockOptionText>
                        Выбирайте наиболее выгодное предложение.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionStep>
                    <Styles.BlockOptionStep>
                      <span>
                        5.
                      </span>
                      <Styles.BlockOptionText>
                        Оформите заявку и получите товар.
                      </Styles.BlockOptionText>
                    </Styles.BlockOptionStep>
                  </Styles.BlockOptionSteps>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howMuchEconomy: !options.howMuchEconomy })}>
                  Сколько я могу сэкономить с&nbsp;dentmp?
                  <LinkArrow transform={options.howMuchEconomy ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howMuchEconomy && (
                  <Styles.BlockOptionText>
                    От 8-10%. Чем больше заказ,тем больше вы экономите средств на закупках.
                  </Styles.BlockOptionText>
                )}
              </Styles.BlockOption>
            </Styles.BlockOptions>
          </Styles.Block>
          <Styles.Block>
            <Styles.BlockTitle>
              Создание заявки
            </Styles.BlockTitle>
            <Styles.BlockOptions>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howMakeOrder: !options.howMakeOrder })}>
                  Как оформить заявку?
                  <LinkArrow transform={options.howMakeOrder ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howMakeOrder && (
                  <Styles.BlockOptionGroups>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Зайдите в раздел “Заявки” и нажмите кнопку “Создать заявку”.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={CreateOrderImage1920} />
                        <source media="(min-width: 1280px)" srcSet={CreateOrderImage1280} />
                        <source media="(min-width: 1024px)" srcSet={CreateOrderImage1024} />
                        <source media="(min-width: 768px)" srcSet={CreateOrderImage768} />
                        <source media="(min-width: 375px)" srcSet={CreateOrderImage375} />
                        <Styles.BlockOptionImage src={CreateOrderImage1920} alt="Create order" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Укажите по какому наименованию товара хотели бы получить предложение.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={GoodNameImage1920} />
                        <source media="(min-width: 1280px)" srcSet={GoodNameImage1280} />
                        <source media="(min-width: 1024px)" srcSet={GoodNameImage1024} />
                        <source media="(min-width: 768px)" srcSet={GoodNameImage768} />
                        <source media="(min-width: 375px)" srcSet={GoodNameImage375} />
                        <Styles.BlockOptionImage src={GoodNameImage1920} alt="Good name" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Вы можете загрузить свою базу товаров. Это позволит системе распознавать наименования и подсказывать варианты, соответствующие вашей
                        <br />
                        номенклатуре. Загружаемый файл должен быть в формате .xls. Загружается
                        <br />
                        только первый столбец, там должны быть только наименования товаров.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={UploadOwnBaseImage1920} />
                        <source media="(min-width: 1280px)" srcSet={UploadOwnBaseImage1280} />
                        <source media="(min-width: 1024px)" srcSet={UploadOwnBaseImage1024} />
                        <source media="(min-width: 768px)" srcSet={UploadOwnBaseImage768} />
                        <source media="(min-width: 375px)" srcSet={UploadOwnBaseImage375} />
                        <Styles.BlockOptionImage src={UploadOwnBaseImage1920} alt="Upload own base" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Укажите количество.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={GoodCountImage1920} />
                        <source media="(min-width: 1280px)" srcSet={GoodCountImage1280} />
                        <source media="(min-width: 1024px)" srcSet={GoodCountImage1024} />
                        <source media="(min-width: 768px)" srcSet={GoodCountImage768} />
                        <source media="(min-width: 375px)" srcSet={GoodCountImage375} />
                        <Styles.BlockOptionImage src={GoodCountImage1920} alt="Good count" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Вы можете добавить до 150 позиций, добавляя строки с помощью кнопки “Добавить строку для товара”.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={AddGoodLineImage1920} />
                        <source media="(min-width: 1280px)" srcSet={AddGoodLineImage1280} />
                        <source media="(min-width: 1024px)" srcSet={AddGoodLineImage1024} />
                        <source media="(min-width: 768px)" srcSet={AddGoodLineImage768} />
                        <source media="(min-width: 375px)" srcSet={AddGoodLineImage375} />
                        <Styles.BlockOptionImage src={AddGoodLineImage1920} alt="Add good line" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Либо добавляйте товары через витрину. Строки для них будут созданы автоматически.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={AddGoodFromMarketImage1920} />
                        <source media="(min-width: 1280px)" srcSet={AddGoodFromMarketImage1280} />
                        <source media="(min-width: 1024px)" srcSet={AddGoodFromMarketImage1024} />
                        <source media="(min-width: 768px)" srcSet={AddGoodFromMarketImage768} />
                        <source media="(min-width: 375px)" srcSet={AddGoodFromMarketImage375} />
                        <Styles.BlockOptionImage src={AddGoodFromMarketImage1920} alt="Add good from market" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Так же вы можете добавить список товаров, загрузив файл из Excel.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={AddGoodFromExcelImage1920} />
                        <source media="(min-width: 1280px)" srcSet={AddGoodFromExcelImage1280} />
                        <source media="(min-width: 1024px)" srcSet={AddGoodFromExcelImage1024} />
                        <source media="(min-width: 768px)" srcSet={AddGoodFromExcelImage768} />
                        <source media="(min-width: 375px)" srcSet={AddGoodFromExcelImage375} />
                        <Styles.BlockOptionImage src={AddGoodFromExcelImage1920} alt="Add good from excel" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Уточните настройки и опубликуйте заявку. Если вы пока не готовы получать предложения от поставщиков и хотите доработать завку позже, сохраните
                        <br />
                        ее как черновик или экспортируйте в Excel.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={OrderActionsImage1920} />
                        <source media="(min-width: 1280px)" srcSet={OrderActionsImage1280} />
                        <source media="(min-width: 1024px)" srcSet={OrderActionsImage1024} />
                        <source media="(min-width: 768px)" srcSet={OrderActionsImage768} />
                        <source media="(min-width: 375px)" srcSet={OrderActionsImage375} />
                        <Styles.BlockOptionImage src={OrderActionsImage1920} alt="Order actions" />
                      </picture>
                    </Styles.BlockOptionGroup>
                  </Styles.BlockOptionGroups>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howLeaveComment: !options.howLeaveComment })}>
                  Как оставить поставщику комментарий по заявке?
                  <LinkArrow transform={options.howLeaveComment ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howLeaveComment && (
                  <Styles.BlockOptionText>
                    При создании в настройках заявки вы&nbsp;можете оставить комментарий.
                    <br />
                    Например, цвет, размер и другие пожелания, которые вы хотели бы отразить касательно заказываемого товара.
                  </Styles.BlockOptionText>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howWatchOffers: !options.howWatchOffers })}>
                  Как посмотреть предложения по заявке?
                  <LinkArrow transform={options.howWatchOffers ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howWatchOffers && (
                  <Styles.BlockOptionGroups>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Зайдите в “Заявки” и выберите “Активные заявки”. В столбце “Ответы” вы
                        <Styles.Breakline />
                        {' '}
                        можете увидеть количество ответов от поставщиков на эту заявку. Для того,
                        <br />
                        чтобы перейти в саму заявку кликните по строке.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={WatchActiveOrdersImage1920} />
                        <source media="(min-width: 1280px)" srcSet={WatchActiveOrdersImage1280} />
                        <source media="(min-width: 1024px)" srcSet={WatchActiveOrdersImage1024} />
                        <source media="(min-width: 768px)" srcSet={WatchActiveOrdersImage768} />
                        <source media="(min-width: 375px)" srcSet={WatchActiveOrdersImage375} />
                        <Styles.BlockOptionImage src={WatchActiveOrdersImage1920} alt="Watch active orders" />
                      </picture>
                    </Styles.BlockOptionGroup>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        По умолчанию сервис отображает лучшие цены по каждой позиции. Для того, чтобы развернуть все предложения, нажмите “Показать все предложения”.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={WatchAllOffersImage1920} />
                        <source media="(min-width: 1280px)" srcSet={WatchAllOffersImage1280} />
                        <source media="(min-width: 1024px)" srcSet={WatchAllOffersImage1024} />
                        <source media="(min-width: 768px)" srcSet={WatchAllOffersImage768} />
                        <source media="(min-width: 375px)" srcSet={WatchAllOffersImage375} />
                        <Styles.BlockOptionImage src={WatchAllOffersImage1920} alt="Watch all offers" />
                      </picture>
                    </Styles.BlockOptionGroup>
                  </Styles.BlockOptionGroups>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howMuchWait: !options.howMuchWait })}>
                  Как долго ждать ответ от поставщика?
                  <LinkArrow transform={options.howMuchWait ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howMuchWait && (
                  <Styles.BlockOptionText>
                    В среднем поставщики отвечают в&nbsp;течении 2-3х часов, в рабочее время.
                  </Styles.BlockOptionText>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, whatDoIfProviderNotAnswer: !options.whatDoIfProviderNotAnswer })}>
                  Что делать если поставщик не отвечает?
                  <LinkArrow transform={options.whatDoIfProviderNotAnswer ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.whatDoIfProviderNotAnswer && (
                  <Styles.BlockOptionGroups>
                    <Styles.BlockOptionGroup>
                      <Styles.BlockOptionText>
                        Если у вас уже есть предложение от&nbsp;поставщика в формате pdf,
                        <Styles.Breakline mobileOnly />
                        {' '}
                        то вы можете добавить этот счет в&nbsp;таблицу сравнения самостоятельно. Для этого перейдите
                        <Styles.Breakline />
                        {' '}
                        в заявку и нажмите кнопку «Добавить счет PDF к&nbsp;сравнению». Все данные после распознания будут добавлены в&nbsp;таблицу сравнения.
                      </Styles.BlockOptionText>
                      <picture>
                        <source media="(min-width: 1920px)" srcSet={UploadPdfOfferImage1920} />
                        <source media="(min-width: 1280px)" srcSet={UploadPdfOfferImage1280} />
                        <source media="(min-width: 1024px)" srcSet={UploadPdfOfferImage1024} />
                        <source media="(min-width: 768px)" srcSet={UploadPdfOfferImage768} />
                        <source media="(min-width: 375px)" srcSet={UploadPdfOfferImage375} />
                        <Styles.BlockOptionImage src={UploadPdfOfferImage1920} alt="Upload Pdf offer" />
                      </picture>
                    </Styles.BlockOptionGroup>
                  </Styles.BlockOptionGroups>
                )}
              </Styles.BlockOption>
            </Styles.BlockOptions>
          </Styles.Block>
          <Styles.Block>
            <Styles.BlockTitle>
              Предложения от поставщиков
            </Styles.BlockTitle>
            <Styles.BlockOptions>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howMuchDeliveryCost: !options.howMuchDeliveryCost })}>
                  Стоимость доставки
                  <LinkArrow transform={options.howMuchDeliveryCost ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howMuchDeliveryCost && (
                  <Styles.BlockOptionTexts>
                    <Styles.BlockOptionText>
                      Условия доставки определяет поставщик.
                    </Styles.BlockOptionText>
                    <Styles.BlockOptionText>
                      Если суммы заказа не хватает для бесплатной доставки, Вы можете попросить поставщика выставить счет и&nbsp;отложить товар до следующего заказа. Либо
                      <Styles.Breakline />
                      {' '}
                      добавить товар в корзину того поставщика, условия которого оказались более выгодными для вас.
                    </Styles.BlockOptionText>
                  </Styles.BlockOptionTexts>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howMuchOfferLive: !options.howMuchOfferLive })}>
                  Как долго действует предложение
                  <Styles.Breakline mobileOnly />
                  {' '}
                  от поставщиков?
                  <LinkArrow transform={options.howMuchOfferLive ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howMuchOfferLive && (
                  <Styles.BlockOptionText>
                    Поставщики проставляют цены, опираясь на складские остатки на&nbsp;момент создания предложения. Поэтому цены и количество всегда актуальны в течение действия предложения. Как правило, это 2-3 рабочих дня.
                  </Styles.BlockOptionText>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, isPreOrderChanged: !options.isPreOrderChanged })}>
                  Учитываются ли ранее достигнутые
                  <Styles.Breakline mobileOnly />
                  {' '}
                  договоренности?
                  <LinkArrow transform={options.isPreOrderChanged ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.isPreOrderChanged && (
                  <Styles.BlockOptionText>
                    Да, учитываются. Так как вы оставляете свои данные при создании заявки, поставщик может вас идентифицировать и сделать вам предложение с учетом всех ранее достигнутых договоренностей о&nbsp;скидках, условиях доставки
                    <Styles.Breakline />
                    {' '}
                    и оплаты.
                  </Styles.BlockOptionText>
                )}
              </Styles.BlockOption>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, howRequestGoods: !options.howRequestGoods })}>
                  Как запросить товар у поставщика?
                  <LinkArrow transform={options.howRequestGoods ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.howRequestGoods && (
                  <Styles.BlockOptionTexts>
                    <Styles.BlockOptionText>
                      Добавляйте заинтересовавшие предложения в корзину и отправляйте
                      <br />
                      их поставщикам. Под каждую заявку создается отдельная корзина.
                      <br />
                    </Styles.BlockOptionText>
                    <Styles.BlockOptionText>
                      После того, как вы нажмете кнопку «Отправить заявки», поставщику на почту поступит от вас запрос
                      <Styles.Breakline mobileOnly />
                      {' '}
                      с просьбой выставить счет на перечень товаров,
                      <Styles.Breakline />
                      {' '}
                      который вы у него выбрали по&nbsp;текущей заявке.
                    </Styles.BlockOptionText>
                  </Styles.BlockOptionTexts>
                )}
              </Styles.BlockOption>
            </Styles.BlockOptions>
          </Styles.Block>
          <Styles.Block>
            <Styles.BlockTitle>
              Контакты
            </Styles.BlockTitle>
            <Styles.BlockOptions>
              <Styles.BlockOption>
                <Styles.BlockOptionHeader width="full" onClick={() => setOptions({ ...defaultOptions, anyQuestions: !options.anyQuestions })}>
                  Остались вопросы?
                  <LinkArrow transform={options.anyQuestions ? 'rotate(-90)' : 'rotate(90)'} />
                </Styles.BlockOptionHeader>
                {options.anyQuestions && (
                  <div>
                    <Styles.BlockOptionText>
                      Мы всегда на связи +7 911 006 79 93, whats app, telegram, jivo чат на сайте.
                    </Styles.BlockOptionText>
                    {/* TODO: вынести ссылки в общий компонент */}
                    <Styles.BlockOptionLinks>
                      <Styles.BlockOptionLink>
                        <a href="https://t.me/+79110067993" target="_blank" rel="noreferrer">
                          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24.188" r="22" fill="#7366BD" />
                            <path d="M34.3185 15.8241L30.2538 36.3086C30.2538 36.3086 29.6856 37.7289 28.122 37.0471L18.7001 29.8388C19.9665 28.7009 29.791 19.872 30.2207 19.4716C30.8847 18.852 30.4726 18.4832 29.7007 18.9518L15.1891 28.1635L9.59042 26.2796C9.59042 26.2796 8.70901 25.967 8.62419 25.2851C8.53886 24.6033 9.61903 24.234 9.61903 24.234L32.4428 15.2842C32.4428 15.2842 34.3185 14.4604 34.3185 15.8241Z" fill="white" />
                          </svg>
                        </a>
                      </Styles.BlockOptionLink>
                      <Styles.BlockOptionLink>
                        <a href="https://wa.me/79110067993" target="_blank" rel="noreferrer">
                          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24.188" r="22" fill="#47A76A" />
                            <g clipPath="url(#clip0_1536_34165)">
                              <path d="M24.3045 11.2295H24.2982C17.3191 11.2295 11.6438 16.9064 11.6438 23.887C11.6438 26.6559 12.5362 29.2222 14.0535 31.3059L12.476 36.0082L17.3413 34.4529C19.3427 35.7788 21.7303 36.5446 24.3045 36.5446C31.2835 36.5446 36.9589 30.8661 36.9589 23.887C36.9589 16.908 31.2835 11.2295 24.3045 11.2295ZM31.6696 29.1035C31.3642 29.9658 30.1523 30.681 29.1856 30.8898C28.5242 31.0306 27.6603 31.143 24.7523 29.9373C21.0325 28.3963 18.6371 24.6164 18.4504 24.3712C18.2716 24.1259 16.9473 22.3697 16.9473 20.5533C16.9473 18.737 17.8697 17.8525 18.2415 17.4728C18.5469 17.1611 19.0516 17.0187 19.5358 17.0187C19.6924 17.0187 19.8332 17.0266 19.9598 17.033C20.3316 17.0488 20.5183 17.0709 20.7636 17.6579C21.0689 18.3937 21.8125 20.21 21.9011 20.3967C21.9913 20.5834 22.0815 20.8366 21.9549 21.0818C21.8363 21.335 21.7319 21.4473 21.5452 21.6625C21.3585 21.8776 21.1813 22.0422 20.9946 22.2732C20.8237 22.4741 20.6306 22.6893 20.8458 23.0611C21.061 23.425 21.8046 24.6386 22.8995 25.6132C24.3124 26.871 25.4579 27.2729 25.8677 27.4438C26.1731 27.5704 26.537 27.5403 26.7601 27.303C27.0433 26.9976 27.3929 26.4913 27.7489 25.9929C28.0021 25.6353 28.3217 25.591 28.6571 25.7176C28.9989 25.8363 30.8073 26.7302 31.1791 26.9153C31.5509 27.102 31.7962 27.1906 31.8864 27.3473C31.975 27.5039 31.975 28.2396 31.6696 29.1035Z" fill="white" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1536_34165">
                                <rect width="25.3151" height="25.3151" fill="white" transform="translate(11.6438 11.2295)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </Styles.BlockOptionLink>
                      <Styles.BlockOptionLink>
                        <a href="mailto:hello@dentmp.ru" target="_blank" rel="noreferrer">
                          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24.188" r="22" fill="#7366BD" />
                            <path d="M24.3234 25.3438L12.2466 19.3188V31.2901C12.2482 31.897 12.4901 32.4785 12.9192 32.9077C13.3484 33.3368 13.9299 33.5787 14.5368 33.5803H34.1207C34.7276 33.5787 35.3092 33.3368 35.7384 32.9077C36.1675 32.4785 36.4093 31.897 36.411 31.2901V19.445L24.3234 25.3438Z" fill="white" />
                            <path d="M24.3342 23.0812L36.411 17.1869V17.135C36.4093 16.5281 36.1675 15.9465 35.7384 15.5174C35.3092 15.0882 34.7276 14.8464 34.1207 14.8447H14.5368C13.9439 14.8459 13.3744 15.0764 12.9475 15.4879C12.5206 15.8994 12.2695 16.4601 12.2466 17.0526L24.3342 23.0812Z" fill="white" />
                          </svg>
                        </a>
                      </Styles.BlockOptionLink>
                    </Styles.BlockOptionLinks>
                  </div>
                )}
              </Styles.BlockOption>
            </Styles.BlockOptions>
          </Styles.Block>
        </Styles.Blocks>
      </Container>
    </AppPage>
  );
};
