import styled from 'styled-components';

import { Colors } from 'src/constants/colors';

import { Button } from '../Button';
import { TypographyStyles } from '../Typography/Typography';

export const Dialog = styled.dialog`
  flex-direction: column;

  display: flex;

  margin: auto;
  box-shadow: 0px 0.13953px 1.3554px 0px rgba(66, 49, 137, 0.01), 0px 0.31702px 3.07958px 0px rgba(66, 49, 137, 0.02), 0px 0.55175px 5.35988px 0px rgba(66, 49, 137, 0.02), 0px 0.87651px 8.51466px 0px rgba(66, 49, 137, 0.03), 0px 1.35239px 13.13748px 0px rgba(66, 49, 137, 0.03), 0px 2.1103px 20.50001px 0px rgba(66, 49, 137, 0.03), 0px 3.50342px 34.0332px 0px rgba(66, 49, 137, 0.04), 0px 7px 68px 0px rgba(66, 49, 137, 0.07);
  border-radius: 16px;
  padding: 24px 32px 32px;

  &:not([open]) {
    display: none; // TODO: убрать для анимации

    pointer-events: none;
    opacity: 0;
  }
  
  &::backdrop {
    background-color: rgba(47, 53, 59, 0.30);
  }
`;

export const Title = styled.h3`
  margin-bottom: 24px;

  ${TypographyStyles.H3};
`;

export const CloseButton = styled(Button)`
  align-self: flex-end;

  margin-bottom: 4px;

  color: ${Colors.UltraViolet};
`;
