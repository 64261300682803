import React from 'react';

export const Avatar = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1408_3442)">
      <path d="M12 21.6875C17.3503 21.6875 21.6875 17.3503 21.6875 12C21.6875 6.64974 17.3503 2.3125 12 2.3125C6.64974 2.3125 2.3125 6.64974 2.3125 12C2.3125 17.3503 6.64974 21.6875 12 21.6875Z" fill="#423189" />
      <path d="M19.7741 17.7735C18.8706 16.5639 17.6974 15.5817 16.3478 14.905C14.9982 14.2282 13.5093 13.8756 11.9995 13.8751C10.4898 13.8746 9.00068 14.2263 7.65063 14.9022C6.30058 15.578 5.12677 16.5595 4.2225 17.7685C5.12215 18.9836 6.29402 19.9711 7.64414 20.6517C8.99426 21.3323 10.4851 21.687 11.997 21.6875C13.509 21.688 15 21.3342 16.3506 20.6545C17.7011 19.9748 18.8736 18.9881 19.7741 17.7735Z" fill="#F8F4FF" />
      <path d="M12 12.625C14.0711 12.625 15.75 10.9461 15.75 8.875C15.75 6.80393 14.0711 5.125 12 5.125C9.92893 5.125 8.25 6.80393 8.25 8.875C8.25 10.9461 9.92893 12.625 12 12.625Z" fill="#F8F4FF" />
    </g>
    <defs>
      <clipPath id="clip0_1408_3442">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
