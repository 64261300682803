import React, {
  FC,
  MouseEvent,
  useEffect,
} from 'react';

import { LandingHeader } from 'src/components/LandingHeader';

import { UserTypes } from 'src/constants/enums';

import routes from 'src/routes';

import { LandingPage } from 'src/ui/LandingPage';
import { Arrow } from 'src/ui/Icons';

import { selectIsCustomerType, selectIsProvider } from 'src/store/userProfile/selectors';

import LoaderCentered from 'src/components/shared-components/Loader/LoaderCentered';
import { ModalsTypes } from '../../types/popUp';
import useActions from '../../hooks/useActions';
import useTypedSelector from '../../hooks/useTypedSelector';

import Logo from './logo.png';

import * as Styles from './Home.styles';

const getGrid = (
  { isProvider, isCustomerType }:
  { isProvider: boolean; isAuth: boolean; isCustomerType: boolean; },
) => {
  if (isCustomerType) {
    return Styles.CustomerGrid;
  }

  if (isProvider) {
    return Styles.ProviderGrid;
  }

  return Styles.Grid;
};

export const Home: FC = () => {
  const { isActive, isAuth, isFetching } = useTypedSelector((state) => state.auth);
  const { type } = useTypedSelector((state) => state.userProfile);
  const isProvider = useTypedSelector(selectIsProvider);
  const isCustomerType = useTypedSelector(selectIsCustomerType);

  const { setCurrentModal } = useActions();

  useEffect(() => {
    if (isActive
      && type
      && ![UserTypes.UndefinedBranch, UserTypes.UndefinedEmployee].includes(type)
    ) {
      setCurrentModal(ModalsTypes.null);
    }
  }, []);

  const handleLinkClick = (e: MouseEvent) => {
    if (!isAuth) {
      e.preventDefault();
      setCurrentModal(ModalsTypes.login);
    }
  };

  const Grid = getGrid({ isProvider, isAuth, isCustomerType });

  return (
    <LandingPage header={<LandingHeader />}>
      {isFetching && (
        <LoaderCentered />
      )}
      {!isFetching && (
        <>
          <Styles.GridContainer>
            <Grid>
              <Styles.MarketGridItem
                to={routes.market}
                onClick={handleLinkClick}
              >
                <Styles.GridItemContent>
                  <Styles.GridItemTitle>
                    Посмотреть
                    <br />
                    витрину
                  </Styles.GridItemTitle>
                </Styles.GridItemContent>
                <Styles.Arrow>
                  <Arrow width="100%" height="100%" />
                </Styles.Arrow>
              </Styles.MarketGridItem>
              <Styles.LogoGridItem
                to={routes.about}
              >
                <img alt="Логотип" src={Logo} />
                <Styles.Arrow>
                  <Arrow width="100%" height="100%" />
                </Styles.Arrow>
              </Styles.LogoGridItem>
              {(!isAuth || isCustomerType) && (
                <Styles.OrderGridItem
                  to={routes.new}
                  onClick={handleLinkClick}
                >
                  <Styles.GridItemContent>
                    <Styles.GridItemTitle>
                      Создать
                      <br />
                      заявку
                    </Styles.GridItemTitle>
                    <Styles.GridItemText>
                      Для стоматологий
                    </Styles.GridItemText>
                  </Styles.GridItemContent>
                  <Styles.Arrow>
                    <Arrow width="100%" height="100%" />
                  </Styles.Arrow>
                </Styles.OrderGridItem>
              )}
              {(!isAuth || isProvider) && (
                <Styles.OfferGridItem
                  to={routes.personal}
                  onClick={handleLinkClick}
                >
                  <Styles.GridItemContent>
                    <Styles.GridItemTitle>
                      Сделать
                      <br />
                      предложение
                    </Styles.GridItemTitle>
                    <Styles.GridItemText>
                      Для поставщиков
                    </Styles.GridItemText>
                  </Styles.GridItemContent>
                  <Styles.Arrow>
                    <Arrow width="100%" height="100%" />
                  </Styles.Arrow>
                </Styles.OfferGridItem>
              )}
            </Grid>
          </Styles.GridContainer>
          <Styles.Links>
            <Styles.Link>
              <a href="https://t.me/+79110067993" target="_blank" rel="noreferrer">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#483D8B" />
                  <path d="M35.2566 14.8756L30.8224 37.2223C30.8224 37.2223 30.2025 38.7718 28.4968 38.0279L18.2184 30.1643C19.5999 28.923 30.3175 19.2915 30.7862 18.8547C31.5107 18.1788 31.0611 17.7765 30.2189 18.2877L14.3881 28.3368L8.2805 26.2815C8.2805 26.2815 7.31897 25.9405 7.22643 25.1967C7.13334 24.4529 8.31172 24.05 8.31172 24.05L33.2103 14.2866C33.2103 14.2866 35.2566 13.3879 35.2566 14.8756Z" fill="white" />
                </svg>
              </a>
            </Styles.Link>
            <Styles.Link>
              <a href="https://wa.me/79110067993" target="_blank" rel="noreferrer">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#47A76A" />
                  <g clipPath="url(#clip0_205_393)">
                    <path d="M24.3322 9.86328H24.3253C16.7118 9.86328 10.5205 16.0563 10.5205 23.6715C10.5205 26.692 11.494 29.4917 13.1492 31.7648L11.4284 36.8946L16.7359 35.1979C18.9194 36.6443 21.5239 37.4797 24.3322 37.4797C31.9457 37.4797 38.1369 31.285 38.1369 23.6715C38.1369 16.058 31.9457 9.86328 24.3322 9.86328ZM32.3668 29.3622C32.0337 30.3029 30.7116 31.0831 29.657 31.3109C28.9355 31.4645 27.9931 31.5871 24.8206 30.2718C20.7628 28.5907 18.1495 24.4672 17.9459 24.1997C17.7508 23.9321 16.3062 22.0162 16.3062 20.0348C16.3062 18.0533 17.3124 17.0884 17.718 16.6742C18.0512 16.3342 18.6018 16.1788 19.1299 16.1788C19.3008 16.1788 19.4544 16.1874 19.5925 16.1944C19.9981 16.2116 20.2018 16.2358 20.4693 16.8761C20.8025 17.6787 21.6137 19.6602 21.7103 19.8639C21.8087 20.0676 21.9071 20.3437 21.769 20.6113C21.6396 20.8874 21.5257 21.01 21.322 21.2447C21.1183 21.4794 20.925 21.659 20.7213 21.911C20.5349 22.1302 20.3243 22.3649 20.5591 22.7705C20.7938 23.1675 21.6051 24.4914 22.7995 25.5546C24.3408 26.9268 25.5905 27.3652 26.0375 27.5516C26.3706 27.6897 26.7676 27.6569 27.011 27.398C27.3199 27.0649 27.7014 26.5125 28.0897 25.9688C28.3659 25.5788 28.7146 25.5304 29.0805 25.6685C29.4533 25.798 31.4262 26.7732 31.8318 26.9751C32.2374 27.1788 32.5049 27.2754 32.6033 27.4463C32.7 27.6172 32.7 28.4198 32.3668 29.3622Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_205_393">
                      <rect width="27.6164" height="27.6164" fill="white" transform="translate(10.5205 9.86328)" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </Styles.Link>
            <Styles.Link>
              <a href="tel:+79110067993" target="_blank" rel="noreferrer">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#483D8B" />
                  <path d="M34.2587 31.4603C34.5024 31.7003 34.6959 31.9865 34.8278 32.3021C34.9596 32.6178 35.0273 32.9565 35.0268 33.2986C35.0747 34.5327 34.0926 35.3064 33.3612 36.0429C32.413 36.9816 31.1697 37.5638 29.8417 37.6912C28.5136 37.8186 27.1823 37.4833 26.073 36.742C20.4472 32.9862 15.622 28.1521 11.8764 22.5195C11.151 21.4152 10.8287 20.0944 10.9639 18.7801C11.0991 17.4657 11.6835 16.2382 12.6186 15.3047L13.5205 14.4029C14.0118 13.9191 14.6736 13.648 15.363 13.648C16.0525 13.648 16.7143 13.9191 17.2055 14.4029L19.4321 16.6295C19.9192 17.119 20.1926 17.7814 20.1926 18.472C20.1926 19.1625 19.9192 19.825 19.4321 20.3145C19.0927 20.5559 18.8554 20.9152 18.7668 21.3222C18.6783 21.7292 18.7447 22.1546 18.9531 22.5152C20.7849 25.4135 23.2373 27.8689 26.1334 29.7041C26.4384 29.8973 26.8003 29.9803 27.159 29.9395C27.5177 29.8987 27.8516 29.7365 28.1054 29.4797C28.3379 29.1913 28.6277 28.9544 28.9566 28.7841C29.2855 28.6137 29.6462 28.5136 30.0159 28.49C30.3855 28.4665 30.756 28.5201 31.1039 28.6474C31.4517 28.7747 31.7693 28.9729 32.0364 29.2295L34.2587 31.4603ZM22.6986 14.0102C22.6969 13.9534 22.7065 13.8968 22.727 13.8437C22.7474 13.7907 22.7782 13.7423 22.8176 13.7013C22.857 13.6603 22.9041 13.6276 22.9563 13.605C23.0085 13.5825 23.0647 13.5706 23.1215 13.5701C29.4055 13.3362 35.3388 19.2513 35.0958 25.5444C35.0935 25.6588 35.0459 25.7677 34.9633 25.847C34.8808 25.9263 34.7701 25.9695 34.6557 25.9673C34.5412 25.965 34.4324 25.9173 34.3531 25.8348C34.2738 25.7522 34.2305 25.6416 34.2328 25.5271C34.4645 19.7264 28.9382 14.1888 23.1388 14.4331C23.0821 14.4342 23.0258 14.4242 22.973 14.4036C22.9202 14.3829 22.872 14.3521 22.8311 14.3128C22.7902 14.2736 22.7575 14.2266 22.7348 14.1747C22.712 14.1228 22.6997 14.0669 22.6986 14.0102Z" fill="white" />
                  <path d="M22.6986 17.6823C22.6955 17.568 22.7378 17.457 22.8162 17.3737C22.8946 17.2904 23.0029 17.2416 23.1172 17.2379C27.4228 17.0247 31.6369 21.2379 31.4237 25.5487C31.4203 25.6631 31.3715 25.7715 31.2882 25.85C31.2048 25.9285 31.0937 25.9707 30.9793 25.9672C30.8648 25.9638 30.7564 25.9151 30.6779 25.8317C30.5994 25.7484 30.5573 25.6372 30.5607 25.5228C30.767 21.7217 26.9473 17.8907 23.1388 18.1009C23.025 18.1034 22.9148 18.0608 22.8324 17.9824C22.7499 17.904 22.7018 17.7961 22.6986 17.6823ZM22.6986 21.3501C22.6967 21.2358 22.7401 21.1253 22.8193 21.0428C22.8985 20.9603 23.0072 20.9126 23.1215 20.91C24.3501 20.9117 25.5279 21.4005 26.3966 22.2693C27.2654 23.138 27.7542 24.3158 27.7559 25.5444C27.7548 25.601 27.7425 25.6569 27.7198 25.7088C27.697 25.7608 27.6643 25.8077 27.6234 25.847C27.5826 25.8862 27.5343 25.9171 27.4816 25.9377C27.4288 25.9583 27.3724 25.9684 27.3158 25.9672C27.2591 25.9661 27.2032 25.9538 27.1513 25.9311C27.0994 25.9084 27.0524 25.8756 27.0132 25.8348C26.9739 25.7939 26.9431 25.7457 26.9224 25.6929C26.9018 25.6401 26.8918 25.5838 26.8929 25.5271C26.9775 23.5784 25.0793 21.6837 23.1302 21.773C23.0174 21.7724 22.9093 21.7279 22.8288 21.6489C22.7482 21.57 22.7016 21.4629 22.6986 21.3501Z" fill="white" />
                </svg>
              </a>
            </Styles.Link>
            <Styles.Link>
              <a href="mailto:hello@dentmp.ru" target="_blank" rel="noreferrer">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#47A76A" />
                  <path d="M24.3527 25.2612L11.178 18.6885V31.748C11.1798 32.4101 11.4436 33.0445 11.9118 33.5127C12.3799 33.9808 13.0144 34.2446 13.6764 34.2464H35.0407C35.7028 34.2446 36.3372 33.9808 36.8054 33.5127C37.2735 33.0445 37.5373 32.4101 37.5391 31.748V18.8261L24.3527 25.2612Z" fill="white" />
                  <path d="M24.3644 22.7929L37.5391 16.3627V16.3061C37.5373 15.644 37.2735 15.0095 36.8054 14.5414C36.3372 14.0732 35.7028 13.8094 35.0407 13.8076H13.6764C13.0296 13.8089 12.4083 14.0603 11.9426 14.5093C11.4769 14.9582 11.2029 15.5699 11.178 16.2162L24.3644 22.7929Z" fill="white" />
                </svg>
              </a>
            </Styles.Link>
          </Styles.Links>
        </>
      )}
    </LandingPage>
  );
};
