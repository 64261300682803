import React, { FC, MouseEventHandler, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Order } from 'src/constants/enums';

import { Drawer } from 'src/ui/Drawer';

import { useCloseOrder, useExportOrderToXlsWithPrefetching } from '../OrderRow.hooks';

import * as Styles from './OrderDrawer.styles';

interface OrderDrawerProps {
  order: Order;
}

export const OrderDrawer: FC<OrderDrawerProps> = (props) => {
  const { order } = props;

  const [isOpened, setIsOpened] = useState(false);

  const {
    exportOrderToXls,
    isExporting,
  } = useExportOrderToXlsWithPrefetching();

  const {
    closeOrder,
    isClosing,
  } = useCloseOrder();

  const handleTriggerClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    setIsOpened(true);
  };

  return (
    <>
      <Styles.DrawerTrigger type="button" onClick={handleTriggerClick}>
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13.9235" cy="4" r="2" fill="#2F353B" />
          <circle cx="13.9235" cy="12" r="2" fill="#2F353B" />
          <circle cx="13.9235" cy="20" r="2" fill="#2F353B" />
        </svg>
      </Styles.DrawerTrigger>
      {isOpened && (
        <Drawer
          title={`Заявка №${order.id} от ${new Date(order.date).toLocaleDateString()}`}
          onClose={() => setIsOpened(false)}
        >
          <Styles.Actions>
            <Styles.Action>
              <Styles.ActionButton
                forwardedAs={NavLink}
                to={`new-template/${order.id}`}
              >
                Повторить заявку
                <Styles.ActionButtonHint>
                  И получать новые предложения от поставщиков
                </Styles.ActionButtonHint>
              </Styles.ActionButton>
            </Styles.Action>
            <Styles.Action>
              <Styles.ActionButton
                width="full"
                onClick={() => exportOrderToXls(order.id)}
                loading={isExporting}
              >
                Экспортировать в Excel
                <Styles.ActionButtonHint>
                  Для просмотра и редактирования оффлайн
                </Styles.ActionButtonHint>
              </Styles.ActionButton>
            </Styles.Action>
            {!order.closeDate && (
              <Styles.Action>
                <Styles.ActionButton
                  width="full"
                  onClick={() => closeOrder(order.id)}
                  loading={isClosing}
                >
                  Закрыть
                </Styles.ActionButton>
              </Styles.Action>
            )}
          </Styles.Actions>
        </Drawer>
      )}
    </>
  );
};
