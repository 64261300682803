import React, { FC } from 'react';
import moment from 'moment';

import {
  Modal,
  Upload,
} from 'antd';

import 'moment/locale/ru';

import { LoadingButton } from 'src/components/shared-components/Loading';

import { Button } from 'src/ui/Button';
import UploadInvoiceForm from './Form';
import useUploadInvoice, { ValidationErrorTexts } from './useUploadInvoiceButton';

import { useComparedOrder } from '../../context/ComparedOrder';

import s from './index.module.css';

moment.locale('ru');

const UploadInvoice: FC = () => {
  const {
    isLoading,
    invalidFiles,
    setInvalidFiles,
    uploadedInvoice,
    setUploadedInvoice,
    onBeforeUpload,
    onUpload,
  } = useUploadInvoice();

  const { getComparedOrder } = useComparedOrder();

  const handleSubmit = () => {
    getComparedOrder();
    setUploadedInvoice(null);
  };

  return (
    <>
      <Upload
        action=""
        accept="application/pdf"
        beforeUpload={onBeforeUpload}
        onChange={onUpload}
        // TODO: antd багует тут https://github.com/ant-design/ant-design/issues/8036
        fileList={[]}
      >
        <Button
          size="small"
          variant="filled"
          disabled={isLoading}
        >
          <span>
            Добавить счет PDF к сравнению
          </span>
          {isLoading && (
            <span className={s.buttonLoader}>
              <LoadingButton />
            </span>
          )}
        </Button>
      </Upload>
      <Modal
        centered
        title="Ошибки в файлах"
        visible={invalidFiles.length > 0}
        footer={null}
        onCancel={() => setInvalidFiles([])}
      >
        <ul>
          {invalidFiles.map((file) => (
            <li className={s.errorListItem}>
              <span>{file.name}</span>
              {file.errors?.map((error) => (
                <p className={s.errorText}>{ValidationErrorTexts[error]}</p>
              ))}
            </li>
          ))}
        </ul>
      </Modal>
      <Modal
        width="80%"
        title="Файл успешно загружен и распознан, проверьте данные,
        розовым отмечаются не однозначно распознанные"
        visible={Boolean(uploadedInvoice)}
        footer={null}
        onCancel={() => setUploadedInvoice(null)}
      >
        <UploadInvoiceForm
          invoice={uploadedInvoice!}
          onSubmit={handleSubmit}
        />
      </Modal>

    </>
  );
};

export default UploadInvoice;
