import React, { FC, ReactNode } from 'react';

import routes from 'src/routes';

import * as Styles from './Breadcrumbs.styles';

interface BreadcrumbsProps {
  icon: ReactNode;
  to: string; // TODO: может быть понадобиться типизировать через routes
}

const BreadcrumbsTexts = {
  [routes.orderCompare]: 'Все заявки',
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ icon, to }) => (
  <Styles.Link to={to}>
    {icon}
    <Styles.LinkText>
      {BreadcrumbsTexts[to]}
    </Styles.LinkText>
  </Styles.Link>
);
