import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Colors } from 'src/constants/colors';

export const Header = styled.header`
  padding: 16px 16px;

  @media screen and (min-width: 768px) {
    padding: 16px 32px 40px 32px;
  }

  @media screen and (min-width: 1024px) {
    padding: 40px 32px 34px;
  }

  @media screen and (min-width: 1280px) {
    padding: 40px 140px 34px;
  }

  @media screen and (min-width: 1920px) {
    padding: 64px 160px;
  }
`;

export const Flex = styled.div`
  align-items: center;

  display: flex;
`;

export const Sidebar = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Loader = styled.div`
  margin-left: auto;
`;

export const NavProfile = styled.div`
  margin-left: auto;
`;

export const Title = styled.h1`
  display: none;

  margin: 0;
  margin-left: 16px;

  font-size: 26px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${Colors.BluePlaza};

  @media screen and (min-width: 768px) {
    display: block;
    
    margin-left: 0;  
    margin-right: 28px;
  }

  @media screen and (min-width: 1024px) {
    margin-right: 56px;
  }
`;

export const HeaderLink = styled(NavLink)`
  display: none;

  font-size: 18px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${Colors.BluePlaza};

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const Buttons = styled.div`
  gap: 8px;

  display: flex;

  margin-left: auto;
`;

export const Button = styled.button`
  align-items: center;
  display: flex;

  font-size: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${Colors.BluePlaza};

  &:not(:last-child) {
    &::after {
      content: '';

      display: block;

      margin-left: 8px;
      width: 1px;
      height: 100%;

      background-color: ${Colors.BluePlaza};
    }
  }
`;
