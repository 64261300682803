import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Colors } from 'src/constants/colors';

import { TypographyStyles } from 'src/ui/Typography/Typography';

export const Link = styled(NavLink)`
  gap: 0 8px;
  align-items: center;

  display: flex;

  && {
    color: ${Colors.Black};
  }

  @media screen and (min-width: 1024px) {
    && {
      color: ${Colors.UltraViolet};
    }
  }
`;

export const LinkText = styled.span`
  display: none;

  ${TypographyStyles.Text};

  @media screen and (min-width: 1024px) {
    display: inline-block;
  }
`;
