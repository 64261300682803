import React, { ComponentPropsWithoutRef } from 'react';

export const VerticalDots = (
  {
    width = 24,
    height = 24,
    color = 'currentColor',
  }: ComponentPropsWithoutRef<'svg'>,
) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="4" r="2" fill={color} />
    <circle cx="12" cy="12" r="2" fill={color} />
    <circle cx="12" cy="20" r="2" fill={color} />
  </svg>
);
