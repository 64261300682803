import React, { FC, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { Modal } from 'src/ui/Modal';
import { Toast } from 'src/ui/Toast';

import { usersApi } from 'src/api/api';

import { getUserData } from 'src/store/userProfile/actions';

import * as Styles from './DeleteBranch.styles';

interface DeleteBranchProps {
  index: number;
  onSubmit: () => void;
}

export const DeleteBranch: FC<DeleteBranchProps> = (props) => {
  const { index, onSubmit } = props;

  const { getValues } = useFormContext();

  const branch = getValues(`branches.${index}`);

  const modalRef = useRef<HTMLDialogElement | null>(null);

  const dispatch = useDispatch();

  const branchMutation = useMutation({
    mutationFn: (id: number) => usersApi.deleteBranch(id),
  });

  const deleteBranch = async (id: number) => {
    try {
      await branchMutation.mutateAsync(id);

      dispatch(getUserData());

      Toast.success(`Филиал ${index + 1} удален`);
    } catch (e) {
      Toast.error('Произошла ошибка при удалении филиала');
    }
  };

  const handleClose = ({ target }: Event) => {
    const { returnValue } = target as HTMLDialogElement;

    if (returnValue === 'yes') {
      if (!branch.id) {
        onSubmit();
      } else {
        deleteBranch(branch.id);
      }
    }
  };

  return (
    <>
      <Styles.RemoveBranch
        type="button"
        onClick={() => modalRef?.current?.showModal()}
      >
        Удалить филиал
      </Styles.RemoveBranch>
      <Modal
        ref={modalRef}
        title="Удалить филиал"
        onClose={handleClose}
      >
        <Styles.ModalBody>
          <Styles.Form
            id={`branch-delete-${index}`}
            method="dialog"
            onSubmit={(e) => e.stopPropagation()}
          >
            <Styles.Text>
              Вы уверены, что хотите удалить филиал
              {' '}
              {index + 1}
              ?
            </Styles.Text>
          </Styles.Form>
          <Styles.Buttons>
            <Styles.SubmitButton
              form={`branch-delete-${index}`}
              variant="filled"
              size="big"
              value="yes"
              loading={branchMutation.isLoading}
            >
              Удалить
            </Styles.SubmitButton>
            <Styles.CancelButton
              form={`branch-delete-${index}`}
              variant="outlined"
              size="big"
              value="no"
            >
              Отмена
            </Styles.CancelButton>
          </Styles.Buttons>
        </Styles.ModalBody>
      </Modal>
    </>
  );
};
