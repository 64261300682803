import styled from 'styled-components';

import { Button } from 'src/ui/Button';
import { TypographyStyles } from 'src/ui/Typography/Typography';
import { Colors } from 'src/constants/colors';

export const ChangeButton = styled(Button)`
  ${TypographyStyles.Text};
  color: ${Colors.UltraViolet};
`;

export const ModalBody = styled.div`
  width: 656px;
`;

export const Form = styled.form`
  margin-bottom: 56px;
`;

export const Field = styled.div`
  flex-direction: column;
  row-gap: 8px;
  
  display: flex;
`;

export const FieldLabel = styled.span<{ required?: boolean }>`
  ${TypographyStyles.Text};

  ${({ required }) => required && `
    &::after {
      content: '*';

      color: ${Colors.ChineseGoldfish};
    }
  `}
`;

export const Buttons = styled.div`
  column-gap: 16px;

  display: flex;
`;

export const SubmitButton = styled(Button)`
  ${TypographyStyles.TextMedium};
`;

export const CancelButton = styled(Button)`
  ${TypographyStyles.Text};
`;
