import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ReactInputMask from 'react-input-mask';
import axios, { AxiosError } from 'axios';

import useTypedSelector from 'src/hooks/useTypedSelector';

import { selectIsCustomerOrProvider } from 'src/store/userProfile/selectors';
import { getUserData, setUserDataAC } from 'src/store/userProfile/actions';

import { AppPage } from 'src/ui/AppPage';
import { Container } from 'src/ui/Container';
import { FormField } from 'src/ui/FormField';
import { Toast } from 'src/ui/Toast';
import { Input, PasswordInput } from 'src/ui/Input';

import { authApi, usersApi } from 'src/api/api';
import { IBackendUserData } from 'src/types/userProfile';
import getCookie from 'src/helpers/getCookie';

import routes from 'src/routes';

import { ChangeEmail } from './ui/ChangeEmail';

import { ProfileForm } from './Profile.types';

import * as Styles from './Profile.styles';

export const Profile = () => {
  const { name, phone, email } = useTypedSelector((store) => store.userProfile);

  const isCustomerOrProvider = useTypedSelector(selectIsCustomerOrProvider);

  const dispatch = useDispatch();

  const {
    handleSubmit: onSubmit,
    formState: {
      errors,
      isSubmitting,
      isSubmitSuccessful,
    },
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm<ProfileForm>({
    defaultValues: {
      name,
      phone: phone || '', // TODO: ну такое
      oldPassword: '',
      newPassword: '',
    },
  });

  // TODO: хак для вызова перерисовки ReactInputMask
  watch('phone');

  useEffect(() => {
    setValue('phone', phone || '');
  }, [phone]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, {
        keepDefaultValues: true,
        keepDirtyValues: true,
      });
    }
  }, [isSubmitSuccessful, reset]);

  const handleSubmit = onSubmit(async (data) => {
    try {
      const { oldPassword, newPassword, ...other } = data;

      await usersApi.updateData(other as IBackendUserData);

      dispatch(setUserDataAC(other));

      if (oldPassword && newPassword) {
        const response = await authApi.changePassword(oldPassword, newPassword);

        // TODO: перейти на http-статусы
        if (!response.data.result) {
          throw new Error(response.data.msg);
        }

        await authApi.refresh();

        const updatedToken: string | undefined | null = getCookie('Authentication');

        // TODO: переделать работу с профилями и авторизацию
        dispatch(getUserData(updatedToken || ''));
      }

      Toast.success('Изменения сохранены!');
    } catch (ex) {
      // TODO: переделать
      if (axios.isAxiosError(ex)) {
        Toast.error((ex as AxiosError).response?.data.message || 'Произошла ошибка при сохранении настроек!');
      } else if ((ex as Error).message === 'Неверный текущий пароль!') {
        setError('oldPassword', {
          message: (ex as Error).message,
        });
      } else {
        Toast.error((ex as Error).message || 'Произошла ошибка при сохранении настроек!');
      }
    }
  });

  return (
    <AppPage>
      <Container variant="medium">
        <Styles.Title>
          Личный профиль
        </Styles.Title>
        <Styles.Form onSubmit={handleSubmit}>
          <Styles.Fieldset>
            <Styles.Field>
              <Styles.FieldLabel required>
                Имя
              </Styles.FieldLabel>
              <FormField error={errors?.name?.message}>
                <Input
                  disabled={!isCustomerOrProvider}
                  placeholder="Елена"
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Укажите имя',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Имя должно содержать не более 20 символов',
                    },
                    pattern: {
                      value: /^[А-Яа-я]+$/,
                      message: 'Имя должно содержать только кириллицу',
                    },
                  })}
                  error={errors?.name?.message}
                />
              </FormField>
            </Styles.Field>
            <Styles.Field>
              <Styles.FieldLabel required>
                Телефон
              </Styles.FieldLabel>
              <FormField error={errors?.phone?.message}>
                <Styles.Input
                  disabled={!isCustomerOrProvider}
                  forwardedAs={ReactInputMask}
                  mask="+7(999) 999-9999"
                  placeholder="+7(973)098 87 76"
                  {...register('phone', {
                    required: {
                      value: true,
                      message: 'Укажите контактный телефон',
                    },
                  })}
                  error={errors?.phone?.message}
                />
              </FormField>
            </Styles.Field>
            <Styles.Field>
              <Styles.FieldLabel>
                Электронная почта
              </Styles.FieldLabel>
              <Styles.EmailBlock>
                <Input
                  placeholder="Электронная почта"
                  value={email}
                  disabled
                />
                {isCustomerOrProvider && (
                  <ChangeEmail />
                )}
              </Styles.EmailBlock>
            </Styles.Field>
          </Styles.Fieldset>
          {isCustomerOrProvider && (
            <Styles.Fieldset>
              <Styles.Field>
                <Styles.FieldLabel>
                  Изменить пароль
                </Styles.FieldLabel>
                <Styles.PasswordBlock>
                  <FormField error={errors?.oldPassword?.message}>
                    <PasswordInput
                      placeholder="Старый пароль"
                      {...register('oldPassword', {
                        validate: (_, values) => values.oldPassword.length !== 0 || values.newPassword.length === 0 || 'Укажите старый пароль', // TODO: добавить валидацию на случай, если пользователь сначала ввел confirm
                      })}
                      error={errors?.oldPassword?.message}
                    />
                  </FormField>
                  <FormField error={errors?.newPassword?.message}>
                    <PasswordInput
                      placeholder="Новый пароль от 6 символов"
                      {...register('newPassword', {
                        pattern: {
                          value: /^(?=.{6,})/,
                          message: 'Пароль должен содержать не менее 6 символов',
                        },
                        validate: {
                          checkEmpty: (_, values) => values.oldPassword.length === 0 || values.newPassword.length !== 0 || 'Введите пароль',
                          checkUnique: (_, values) => values.oldPassword.length === 0 || values.newPassword.length === 0 || values.oldPassword !== values.newPassword || 'Пароли не должны совпадать',
                        },
                      })}
                      error={errors?.newPassword?.message}
                    />
                  </FormField>
                </Styles.PasswordBlock>
              </Styles.Field>
            </Styles.Fieldset>
          )}
          {isCustomerOrProvider && (
            <Styles.SubmitButton
              size="big"
              variant="filled"
              type="submit"
              loading={isSubmitting}
            >
              Сохранить изменения
            </Styles.SubmitButton>
          )}
        </Styles.Form>
        <Styles.Link to={routes.logout}>
          Выйти из аккаунта
        </Styles.Link>
      </Container>
    </AppPage>
  );
};
