import React, {
  forwardRef, useEffect, useImperativeHandle, useRef,
} from 'react';

import { Close } from '../Icons';
import { Portal } from '../Portal';

import { ModalProps } from './Modal.types';

import * as Styles from './Modal.styles';

export const Modal = forwardRef<HTMLDialogElement, ModalProps>((props, ref) => {
  const {
    title,
    open,
    onClose,
    children,
  } = props;

  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const handleClose = (e: Event) => {
      if (onClose) {
        onClose(e);
      }
    };

    modalRef.current?.addEventListener('close', handleClose);

    return () => {
      modalRef.current?.removeEventListener('close', handleClose);
    };
  }, [onClose]);

  useImperativeHandle(ref, () => modalRef.current as HTMLDialogElement, []);

  return (
    <Portal>
      <Styles.Dialog ref={modalRef} open={open}>
        {onClose && (
          <Styles.CloseButton type="button" onClick={() => modalRef.current?.close()}>
            <Close />
          </Styles.CloseButton>
        )}
        <Styles.Title>
          {title}
        </Styles.Title>
        {children}
      </Styles.Dialog>
    </Portal>
  );
});
