import styled from 'styled-components';
import { StylesConfig } from 'react-select';

import { Colors } from 'src/constants/colors';

import { TypographyStyles } from '../Typography/Typography';

export const DefaultStylesProp: StylesConfig = {
  control: (baseStyles, props) => {
    const { selectProps } = props;

    const commonStyles = {
      border: 'none',
      borderRadius: '8px',
    };

    if (selectProps.isDisabled) {
      return {
        ...baseStyles,
        ...commonStyles,
        boxShadow: `0 0 0 1px ${Colors.RoughStone}`,
        backgroundColor: Colors.White,
      };
    }

    if (selectProps.error) {
      return {
        ...baseStyles,
        ...commonStyles,
        boxShadow: `0 0 0 1px ${Colors.ChineseGoldfish}`,
      };
    }

    return {
      ...baseStyles,
      ...commonStyles,
      boxShadow: `0 0 0 1px ${Colors.BluePlaza}`,
    };
  },
  option: (baseStyles) => ({
    ...baseStyles,
    padding: '8px 16px',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '16px 24px 16px 16px',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: 0,
    color: Colors.RoughStone,
  }),
  dropdownIndicator: (baseStyles, props) => {
    const { selectProps } = props;

    const commonStyles = {
      padding: 0,
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    };

    if (selectProps.isDisabled) {
      return {
        ...baseStyles,
        ...commonStyles,
        color: Colors.RoughStone,
      };
    }

    return {
      ...baseStyles,
      ...commonStyles,
      color: Colors.BluePlaza,
      '&:hover': {
        color: Colors.BluePlaza,
      },
    };
  },
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    marginRight: '16px',
  }),
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    color: Colors.RoughStone,
    marginRight: '16px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    margin: 0,
    padding: 0,
  }),
};

export const SelectValueContainer = styled.div`
  ${TypographyStyles.Text};
  display: grid;
  flex: 1;
  align-items: center;
`;

export const SelectOption = styled.p`
  ${TypographyStyles.Text};
`;
