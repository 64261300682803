import React, { FC, ReactElement } from 'react';

import { Container } from '../Container';

import * as Styles from './LandingPage.styles';

interface LandingPageProps {
  header?: ReactElement;
}

export const LandingPage: FC<LandingPageProps> = ({ header = null, children }) => (
  <>
    {header}
    <Styles.Main>
      <Container>
        {children}
      </Container>
    </Styles.Main>
  </>
);
