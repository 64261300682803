import styled from 'styled-components';
import { Colors } from 'src/constants/colors';
import { TypographyStyles } from '../Typography/Typography';
import { Button } from '../Button';

export const Pagination = styled.div`
  column-gap: 56px;

  display: flex;
`;

export const ArrowButton = styled(Button)`
  color: ${Colors.UltraViolet};

  &:disabled {
    color: ${Colors.RoughStone};
  }
`;

export const Pages = styled.ul`
  column-gap: 56px;
  
  display: flex;

  margin: 0;
`;

export const Page = styled.li``;

export const PageLabel = styled.label`
  ${TypographyStyles.Text};
  color: ${Colors.UltraViolet};
`;

export const PageInput = styled.input`
  appearance: none;

  position: absolute;

  &:checked + ${PageLabel} {
    padding: 8px 16px;
    border-radius: 8px;

    background-color: ${Colors.Lavender};
  }
`;
