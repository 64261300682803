import React from 'react';

import useTypedSelector from 'src/hooks/useTypedSelector';

import { selectIsCustomer, selectIsProvider } from 'src/store/userProfile/selectors';

import { Avatar, Bin, LinkArrow } from 'src/ui/Icons';

import routes from 'src/routes';

import * as Styles from './SidebarMenu.styles';

export const SidebarMenu = () => {
  const { isAuth } = useTypedSelector((state) => state.auth);
  const { name, email } = useTypedSelector((state) => state.userProfile);

  const isCustomer = useTypedSelector(selectIsCustomer);
  const isProvider = useTypedSelector(selectIsProvider);

  return (
    <Styles.Content>
      {!isAuth && (
        <Styles.Menu>
          <li>
            <Styles.Link to={routes.personal}>
              <Styles.LinkText>Как это работает?</Styles.LinkText>
            </Styles.Link>
          </li>
        </Styles.Menu>
      )}
      {isAuth && (
        <>
          <Styles.Menu>
            <li>
              <Styles.UserLink to={routes.profile}>
                <Styles.UserAvatar>
                  <Avatar />
                </Styles.UserAvatar>
                <Styles.LinkText>
                  <Styles.UserName>{name}</Styles.UserName>
                  <Styles.UserEmail>{email}</Styles.UserEmail>
                </Styles.LinkText>
                <Styles.Arrow>
                  <LinkArrow />
                </Styles.Arrow>
              </Styles.UserLink>
            </li>
          </Styles.Menu>
          <Styles.Menu>
            <li>
              <Styles.Link to={routes.personal}>
                <Styles.LinkIcon>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 20H2.99998L2 22H22L21 20ZM21 16C21 12.7 18.3 10 15 10H14V9.00003C14 8.40003 13.6 8.00004 13 8.00004H11C10.4 8.00004 10 8.40003 10 9.00003V10H9.00003C5.70003 10 3.00003 12.7 3.00003 16V18H21L21 16ZM11 2H13V5H11V2ZM17.767 4.46272L19.1812 5.87689L17.0599 7.99812L15.6457 6.58395L17.767 4.46272ZM6.28226 4.40909L8.40354 6.53037L6.98932 7.94454L4.86809 5.82326L6.28226 4.40909Z" fill="currentColor" />
                  </svg>
                </Styles.LinkIcon>
                <Styles.LinkText>Мои заявки</Styles.LinkText>
                <Styles.Arrow>
                  <LinkArrow />
                </Styles.Arrow>
              </Styles.Link>
            </li>
            <li>
              <Styles.Link to={routes.market}>
                <Styles.LinkIcon>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 4H5.5C4.673 4 4 4.673 4 5.5V10C4 10.827 4.673 11.5 5.5 11.5H10C10.827 11.5 11.5 10.827 11.5 10V5.5C11.5 4.673 10.827 4 10 4ZM18.5 4H14C13.173 4 12.5 4.673 12.5 5.5V10C12.5 10.827 13.173 11.5 14 11.5H18.5C19.327 11.5 20 10.827 20 10V5.5C20 4.673 19.327 4 18.5 4ZM18.5 12.5H14C13.173 12.5 12.5 13.173 12.5 14V18.5C12.5 19.327 13.173 20 14 20H18.5C19.327 20 20 19.327 20 18.5V14C20 13.173 19.327 12.5 18.5 12.5ZM10 12.5H5.5C4.673 12.5 4 13.173 4 14V18.5C4 19.327 4.673 20 5.5 20H10C10.827 20 11.5 19.327 11.5 18.5V14C11.5 13.173 10.827 12.5 10 12.5Z" fill="currentColor" />
                  </svg>
                </Styles.LinkIcon>
                <Styles.LinkText>Витрина</Styles.LinkText>
                <Styles.Arrow>
                  <LinkArrow />
                </Styles.Arrow>
              </Styles.Link>
            </li>
            <li>
              <Styles.Link to={routes.market}>
                <Styles.LinkIcon>
                  <Bin />
                </Styles.LinkIcon>
                <Styles.LinkText>Корзина</Styles.LinkText>
                <Styles.Arrow>
                  <LinkArrow />
                </Styles.Arrow>
              </Styles.Link>
            </li>
            <li>
              <Styles.Link to={routes.purchaseHistory}>
                <Styles.LinkIcon>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.489 2 2 6.489 2 12C2 17.511 6.489 22 12 22C17.511 22 22 17.511 22 12C22 6.489 17.511 2 12 2ZM12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7V11.553L15.705 14.24C15.893 14.427 15.999 14.681 15.9998 14.9462C16.0005 15.2113 15.8959 15.4659 15.709 15.654C15.522 15.842 15.268 15.948 15.0028 15.9488C14.7377 15.9495 14.4831 15.8449 14.295 15.658L11.295 12.678C11.2014 12.585 11.1272 12.4743 11.0765 12.3525C11.0259 12.2306 10.9999 12.1 11 11.968V7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6Z" fill="currentColor" />
                  </svg>
                </Styles.LinkIcon>
                <Styles.LinkText>История покупок</Styles.LinkText>
                <Styles.Arrow>
                  <LinkArrow />
                </Styles.Arrow>
              </Styles.Link>
            </li>
            <li>
              <Styles.Link to={routes.settings}>
                <Styles.LinkIcon>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 7.75C14.279 7.75 14.067 7.6622 13.9107 7.50592C13.7545 7.34964 13.6667 7.13768 13.6667 6.91667V5.66667H10.3333V6.91667C10.3333 7.13768 10.2455 7.34964 10.0893 7.50592C9.93297 7.6622 9.72101 7.75 9.5 7.75C9.27899 7.75 9.06702 7.6622 8.91074 7.50592C8.75446 7.34964 8.66667 7.13768 8.66667 6.91667V5.66667C8.66667 4.7475 9.41417 4 10.3333 4H13.6667C14.5858 4 15.3333 4.7475 15.3333 5.66667V6.91667C15.3333 7.13768 15.2455 7.34964 15.0893 7.50592C14.933 7.6622 14.721 7.75 14.5 7.75ZM12.5917 15.15C12.1911 15.2877 11.7551 15.2818 11.3583 15.1333L2 12.0167V18.375C2 19.6417 3.025 20.6667 4.29167 20.6667H19.7083C20.975 20.6667 22 19.6417 22 18.375V12.0167L12.5917 15.15Z" fill="currentColor" />
                    <path d="M22 8.79167V10.7L12.2 13.9667C12.1333 13.9917 12.0667 14 12 14C11.9333 14 11.8667 13.9917 11.8 13.9667L2 10.7V8.79167C2 7.525 3.025 6.5 4.29167 6.5H19.7083C20.975 6.5 22 7.525 22 8.79167Z" fill="currentColor" />
                  </svg>
                </Styles.LinkIcon>
                <Styles.LinkText>Компания</Styles.LinkText>
                <Styles.Arrow>
                  <LinkArrow />
                </Styles.Arrow>
              </Styles.Link>
            </li>
            {isCustomer && (
              <li>
                <Styles.Link to={routes.branches}>
                  <Styles.LinkIcon>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5189 3C7.92437 3 5 5.92437 5 9.51889C5 13.9798 10.8338 20.5287 11.0822 20.8053C11.3155 21.0651 11.7228 21.0647 11.9557 20.8053C12.2041 20.5287 18.0379 13.9798 18.0379 9.51889C18.0378 5.92437 15.1134 3 11.5189 3ZM11.5189 12.7987C9.71042 12.7987 8.23912 11.3274 8.23912 9.51889C8.23912 7.71038 9.71045 6.23909 11.5189 6.23909C13.3274 6.23909 14.7987 7.71042 14.7987 9.51893C14.7987 11.3274 13.3274 12.7987 11.5189 12.7987Z" fill="currentColor" />
                    </svg>
                  </Styles.LinkIcon>
                  <Styles.LinkText>Филиалы</Styles.LinkText>
                  <Styles.Arrow>
                    <LinkArrow />
                  </Styles.Arrow>
                </Styles.Link>
              </li>
            )}
            {isProvider && (
              <li>
                <Styles.Link to={routes.employees.index}>
                  <Styles.LinkIcon>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5189 3C7.92437 3 5 5.92437 5 9.51889C5 13.9798 10.8338 20.5287 11.0822 20.8053C11.3155 21.0651 11.7228 21.0647 11.9557 20.8053C12.2041 20.5287 18.0379 13.9798 18.0379 9.51889C18.0378 5.92437 15.1134 3 11.5189 3ZM11.5189 12.7987C9.71042 12.7987 8.23912 11.3274 8.23912 9.51889C8.23912 7.71038 9.71045 6.23909 11.5189 6.23909C13.3274 6.23909 14.7987 7.71042 14.7987 9.51893C14.7987 11.3274 13.3274 12.7987 11.5189 12.7987Z" fill="currentColor" />
                    </svg>
                  </Styles.LinkIcon>
                  <Styles.LinkText>Сотрудники</Styles.LinkText>
                  <Styles.Arrow>
                    <LinkArrow />
                  </Styles.Arrow>
                </Styles.Link>
              </li>
            )}
            <li>
              <Styles.Link to={routes.help}>
                <Styles.LinkIcon>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11.2759 12.842C11.1639 13.1033 11.1079 13.4067 11.1079 13.752H12.8859C12.8859 13.472 12.9419 13.2293 13.0539 13.024C13.1659 12.8187 13.3106 12.6273 13.4879 12.45C13.6746 12.2727 13.8706 12.0953 14.0759 11.918C14.2812 11.7313 14.4726 11.5353 14.6499 11.33C14.8366 11.1153 14.9859 10.868 15.0979 10.588C15.2192 10.308 15.2799 9.98133 15.2799 9.608C15.2799 9.06666 15.1352 8.60933 14.8459 8.236C14.5566 7.85333 14.1552 7.564 13.6419 7.368C13.1286 7.16266 12.5312 7.06 11.8499 7.06C10.9819 7.06 10.2306 7.214 9.59591 7.522C8.96124 7.82066 8.44791 8.236 8.05591 8.768L9.41391 9.65C9.66591 9.30466 9.97857 9.03866 10.3519 8.852C10.7346 8.66533 11.1826 8.572 11.6959 8.572C12.2279 8.572 12.6526 8.69333 12.9699 8.936C13.2872 9.17866 13.4459 9.496 13.4459 9.888C13.4459 10.1213 13.3899 10.3313 13.2779 10.518C13.1659 10.7047 13.0212 10.882 12.8439 11.05C12.6666 11.218 12.4752 11.3907 12.2699 11.568C12.0739 11.7453 11.8872 11.9367 11.7099 12.142C11.5326 12.3473 11.3879 12.5807 11.2759 12.842ZM11.1919 16.776C11.4066 16.9907 11.6772 17.098 12.0039 17.098C12.3306 17.098 12.5966 16.9907 12.8019 16.776C13.0072 16.5613 13.1099 16.3047 13.1099 16.006C13.1099 15.7073 13.0072 15.4553 12.8019 15.25C12.5966 15.0353 12.3306 14.928 12.0039 14.928C11.6772 14.928 11.4066 15.0353 11.1919 15.25C10.9866 15.4553 10.8839 15.7073 10.8839 16.006C10.8839 16.3047 10.9866 16.5613 11.1919 16.776Z" fill="currentColor" />
                  </svg>
                </Styles.LinkIcon>
                <Styles.LinkText>Помощь</Styles.LinkText>
                <Styles.Arrow>
                  <LinkArrow />
                </Styles.Arrow>
              </Styles.Link>
            </li>
          </Styles.Menu>
        </>
      )}
    </Styles.Content>
  );
};
