import { styled } from 'styled-components';

import { TypographyStyles } from 'src/ui/Typography/Typography';

import { Colors } from 'src/constants/colors';

export const Breadcrumbs = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: inline-block;

    margin-bottom: 16px;
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const EmptyTable = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px 0;

  display: flex;

  margin: 0 auto;
  max-width: 666px;
`;

export const EmptyTableText = styled.p`
  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};
  text-align: center;
  white-space: pre;
`;

export const EmptyTableButton = styled.button`
  border: none;

  background-color: transparent;

  ${TypographyStyles.Text};
  color: ${Colors.UltraViolet};
`;

export const Tables = styled.div`
  row-gap: 56px;
  flex-direction: column;

  display: flex;
`;
