import React, { forwardRef, useState } from 'react';

import { EyeInvisible, EyeVisible } from '../Icons';

import { PasswordInputProps } from './Input.types';

import * as Styles from './Input.styles';

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Styles.InputContainer>
      <Styles.PasswordInput ref={ref} type={isVisible ? 'text' : 'password'} {...props} />
      <Styles.PasswordIcon type="button" onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? <EyeInvisible /> : <EyeVisible />}
      </Styles.PasswordIcon>
    </Styles.InputContainer>
  );
});
