import React, {
  ComponentType,
  FC,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ProvidersFilterContextValue {
  filter: number[];
  setFilter: (value: number[]) => void;
}

const ProvidersFilterContext = createContext<ProvidersFilterContextValue>({
  filter: [],
  setFilter: () => {},
});

export const useProvidersFilter = () => useContext(ProvidersFilterContext);

const ProvidersFilterProvider: FC = ({ children }) => {
  const [filter, setFilter] = useState<number[]>([]);

  const context = useMemo(() => ({
    filter,
    setFilter,
  }), [filter]);

  return (
    <ProvidersFilterContext.Provider value={context}>
      {children}
    </ProvidersFilterContext.Provider>
  );
};

export const withProvidersFilter = <T extends JSX.IntrinsicAttributes>
  (Component: ComponentType<T>) => (
    (props: T) => (
      <ProvidersFilterProvider>
        <Component {...props} />
      </ProvidersFilterProvider>
    )
  );
