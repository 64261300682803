import styled from 'styled-components';

export const Main = styled.main`
  padding: 16px 0;

  @media screen and (min-width: 768px) {
    padding: 16px 32px;
  }

  @media screen and (min-width: 1280px) {
    padding: 16px 140px;
  }

  @media screen and (min-width: 1920px) {
    padding: 32px 160px;
  }
`;
