import styled from 'styled-components';

import { Colors } from 'src/constants/colors';

import { TypographyStyles } from 'src/ui/Typography/Typography';
import { Button } from 'src/ui/Button';

export const Header = styled.div`
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    margin-bottom: 56px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0;

  ${TypographyStyles.H3};
  color: ${Colors.BluePlaza};

  @media screen and (min-width: 768px) {
    ${TypographyStyles.H2};
  }
`;

export const Blocks = styled.ul`
  flex-direction: column;
  row-gap: 96px;

  display: flex;

  margin: 0;
`;

export const Block = styled.li`
`;

export const BlockTitle = styled.h5`
  margin-bottom: 32px;

  ${TypographyStyles.H5};
  color: ${Colors.BluePlaza};
  font-weight: 600;
`;

export const BlockOptions = styled.ul`
  flex-direction: column;
  row-gap: 32px;

  display: flex;

  margin: 0;
`;

export const BlockOption = styled.li`
  flex-direction: column;
  row-gap: 16px;

  display: flex;

  &:not(:last-child) {
    padding-bottom: 32px;
    box-shadow: 0 1px 0 0 ${Colors.LittleDipper};
  }
`;

export const BlockOptionHeader = styled(Button)`
  justify-content: space-between;
  align-items: flex-start;

  display: flex;

  ${TypographyStyles.H6};
  color: ${Colors.BluePlaza};
  font-weight: 400;
  text-align: left;

  @media screen and (min-width: 768px) {
    align-items: center;

    text-align: initial;
  }
`;

export const BlockOptionList = styled.ul`
  margin: 0;
  padding-left: 10px;
`;

export const BlockOptionItem = styled.li`
  align-items: baseline;
  column-gap: 10px;

  display: flex;
`;

export const BlockOptionSteps = styled.ol`
  margin: 0;
  padding-left: 4px;
`;

export const BlockOptionStep = styled.li`
  align-items: baseline;
  column-gap: 4px;

  display: flex;
`;

export const BlockOptionGroups = styled.ul`
  flex-direction: column;
  row-gap: 32px;

  display: flex;

  margin: 0;
`;

export const BlockOptionGroup = styled.li`
  flex-direction: column;
  row-gap: 8px;

  display: flex;
`;

export const BlockOptionTexts = styled.div`
  flex-direction: column;
  row-gap: 16px;

  display: flex;
`;

export const BlockOptionText = styled.p`
  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};
`;

export const Breakline = styled.br<{ mobileOnly?: boolean }>`
  display: none;

  @media screen and (min-width: 768px) {
    display: inline;
  }

  ${(props) => props.mobileOnly && `
    display: inline;

    @media screen and (min-width: 768px) {
      display: none;
    }
  `}
`;

export const BlockOptionImage = styled.img`
  display: block;

  box-shadow: 0px 0.14px 1.355px 0px rgba(66, 49, 137, 0.01), 0px 0.317px 3.08px 0px rgba(66, 49, 137, 0.02), 0px 0.552px 5.36px 0px rgba(66, 49, 137, 0.02), 0px 0.877px 8.515px 0px rgba(66, 49, 137, 0.03), 0px 1.352px 13.137px 0px rgba(66, 49, 137, 0.03), 0px 2.11px 20.5px 0px rgba(66, 49, 137, 0.03), 0px 3.503px 34.033px 0px rgba(66, 49, 137, 0.04), 0px 7px 68px 0px rgba(66, 49, 137, 0.07);
  width: 100%;
  height: auto;
`;

export const BlockOptionLinks = styled.ul`
  column-gap: 24px;

  display: flex;

  margin: 24px 0 0;
`;

export const BlockOptionLink = styled.li``;
