import { message } from 'antd';
import { usersApi } from 'src/api/api';

import { IProduct } from 'src/pages/CustomerProposal/types';

export const useExportOrderToXls = () => {
  const exportOrder = async ({ goods }: { goods: IProduct[] }) => {
    try {
      const response = await usersApi.exportGoodsXls({
        goods: goods.map(({ id, ...good }) => ({
          ...good,
          amount: Number(good.amount),
        })),
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      link.download = `proposal_${new Date().getTime()}.xlsx`;
      link.click();

      message.success('Экспорт заявки произошел успешно!');
    } catch (ex) {
      message.error('Произошла ошибка при экспорте заявки');

      throw ex;
    }
  };

  return {
    exportOrder,
  };
};
