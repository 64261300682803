import React, { useEffect, useRef } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import {
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import { useDispatch } from 'react-redux';

import useTypedSelector from 'src/hooks/useTypedSelector';
import useLeaveRoute from 'src/hooks/useLeaveRoute';

import { AppPage } from 'src/ui/AppPage';
import { Container } from 'src/ui/Container';
import { Toast } from 'src/ui/Toast';

import { usersApi } from 'src/api/api';

import { IBackendBranch } from 'src/constants/enums';

import { getUserData } from 'src/store/userProfile/actions';

import { AddCredentials } from './ui/AddCredentials';
import { BranchesForm, BranchesList } from './ui/BranchesList';
import { LeavingRouteConfirmation } from './ui/LeavingRouteConfirmation';

import { createBranch } from './Branches.helpers';

import * as Styles from './Branches.styles';

export const Branches = () => {
  const { usersBranch } = useTypedSelector((store) => store.userProfile);

  const history = useHistory();

  const modalRef = useRef<HTMLDialogElement | null>(null);

  const {
    lastLocation,
    handleLocationChange,
    setLastLocation,
  } = useLeaveRoute();

  useEffect(() => {
    if (lastLocation) {
      modalRef.current?.showModal();
    }
  }, [lastLocation]);

  const methods = useForm<BranchesForm>({
    defaultValues: {
      branches: usersBranch,
    },
  });

  const dispatch = useDispatch();

  // TODO: переделать
  useEffect(() => {
    methods.reset({
      branches: usersBranch,
    });
  }, [methods.reset, usersBranch]);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'branches',
  });

  const handleSubmit = methods.handleSubmit(
    async ({ branches }) => {
      try {
        // TODO: переделать
        await Promise.all(
          branches.map((branch) => {
            const body = {
              ...branch,
              region: branch.region.name,
            };

            return (
              body.id
                ? usersApi.updateBranch(body as IBackendBranch)
                : usersApi.createBranch(body)
            );
          }),
        );

        Toast.success('Изменения сохранены');

        // если мы пытались уйти со страницы,
        // то после отправки данных на бэкенд
        // спокойно переходим на нужный урл
        if (lastLocation) {
          history.push(lastLocation);
        } else {
          dispatch(getUserData()); // TODO: переделать
        }
      } catch (ex) {
        Toast.error('Произошла ошибка при сохранении филиалов');

        // TODO: возможно, стоит чуть переделать флоу - например,
        // не закрывать модалку, пока вся процедура сабмита не произойдет
        // в текущей реализации модалка закрывается сразу после нажатия на кнопку "да" / "нет"
        if (lastLocation) {
          // если мы пытались уйти со страницы
          // и в процессе отправки данных на бэкенд произошла ошибка,
          // то очищаем урл, на который нужно перейти

          setLastLocation(null);
        }
      }
    },
    () => {
      // если мы пытались уйти со страницы,
      // то очищаем урл, на который нужно перейти
      if (lastLocation) {
        setLastLocation(null);
      }
    },
  );

  const handleClose = ({ target }: Event) => {
    const { returnValue } = target as HTMLDialogElement;

    if (returnValue === 'yes') {
      // если пользовать нажал "да", то триггерим сабмит
      handleSubmit();
    } else {
      // в противном случае (закрытие через крестик или "нет") просто переходим на нужный урл
      history.push(lastLocation!);
    }
  };

  return (
    <AppPage>
      <Container variant="medium">
        <Styles.Header>
          <Styles.Title>
            Филиалы
          </Styles.Title>
          <AddCredentials />
        </Styles.Header>
        <FormProvider {...methods}>
          <form id="branches" onSubmit={handleSubmit}>
            {fields.length > 0 && (
              <BranchesList
                fields={fields}
                remove={remove}
              />
            )}
            <Styles.Buttons>
              <Styles.AddButton
                size="big"
                variant="filled"
                type="button"
                onClick={() => append(createBranch())}
              >
                Добавить филиал
              </Styles.AddButton>
              <Styles.SubmitButton
                size="big"
                variant="outlined"
                type="submit"
                loading={methods.formState.isSubmitting}
              >
                Сохранить изменения
              </Styles.SubmitButton>
            </Styles.Buttons>
          </form>
        </FormProvider>
      </Container>
      <Prompt
        when={methods.formState.isDirty}
        message={handleLocationChange}
      />
      <LeavingRouteConfirmation
        ref={modalRef}
        onClose={handleClose}
      />
    </AppPage>
  );
};
