import React, { FC, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import _get from 'lodash/get';
import _find from 'lodash/find';

import { FormField } from 'src/ui/FormField';
import { LinkArrow } from 'src/ui/Icons';
import { Select } from 'src/ui/Select';
import { Input } from 'src/ui/Input';

import cities from 'src/assets/data/cities';

import { DeleteBranch } from '../DeleteBranch';

import { BranchesForm } from './BranchesList.types';

import * as Styles from './BranchesList.styles';

interface BranchesListItemProps {
  index: number;
  canRemove: boolean;
  onRemove: (index: number) => void;
}

export const BranchesListItem: FC<BranchesListItemProps> = (props) => {
  const { index, canRemove, onRemove } = props;

  const [isOpened, setIsOpened] = useState<boolean>(true);

  const {
    register,
    control,
    formState,
  } = useFormContext<BranchesForm>();

  const errors = _get(formState, ['errors', 'branches', index]);

  return (
    <Styles.Branch>
      <Styles.Toggle
        type="button"
        afterIcon={<LinkArrow width={24} height={24} transform={isOpened ? 'rotate(-90)' : 'rotate(90)'} />}
        onClick={() => setIsOpened(!isOpened)}
      >
        Филиал
        {' '}
        {index + 1}
      </Styles.Toggle>
      {isOpened && (
        <Styles.Rows>
          <Styles.Row>
            <Styles.FormLabel required>
              Организация
            </Styles.FormLabel>
            <FormField error={errors?.organization?.message}>
              <Input
                placeholder="Организация"
                {...register(`branches.${index}.organization`, {
                  required: {
                    value: true,
                    message: 'Укажите название организации',
                  },
                  maxLength: {
                    value: 200,
                    message: 'Название организации не должно быть больше 200 символов',
                  },
                })}
                error={errors?.organization?.message}
              />
            </FormField>
          </Styles.Row>
          <Styles.Row>
            <Styles.FormLabel required>
              Название филиала
            </Styles.FormLabel>
            <FormField error={errors?.branchName?.message}>
              <Input
                placeholder="Название филиала"
                {...register(`branches.${index}.branchName`, {
                  required: {
                    value: true,
                    message: 'Укажите название филиала',
                  },
                  maxLength: {
                    value: 200,
                    message: 'Название филиала не должно быть больше 50 символов',
                  },
                })}
                error={errors?.branchName?.message}
              />
            </FormField>
          </Styles.Row>
          <Styles.Row>
            <Styles.FormLabel required>
              ФИО представителя
            </Styles.FormLabel>
            <FormField error={errors?.userName?.message}>
              <Input
                placeholder="ФИО представителя"
                {...register(`branches.${index}.userName`, {
                  required: {
                    value: true,
                    message: 'Укажите ФИО представителя',
                  },
                  maxLength: {
                    value: 200,
                    message: 'ФИО представителя не должно быть больше 50 символов',
                  },
                })}
                error={errors?.userName?.message}
              />
            </FormField>
          </Styles.Row>
          <Styles.Row>
            <Styles.FormLabel required>
              Телефон
            </Styles.FormLabel>
            <FormField error={errors?.phone?.message}>
              <Styles.Input
                forwardedAs={ReactInputMask}
                mask="+7(999) 999-9999"
                placeholder="Телефон"
                {...register(`branches.${index}.phone`, {
                  required: {
                    value: true,
                    message: 'Укажите номер телефона',
                  },
                })}
                error={errors?.phone?.message}
              />
            </FormField>
          </Styles.Row>
          <Styles.Row>
            <Styles.FormLabel required>
              Электронная почта
            </Styles.FormLabel>
            <FormField error={errors?.email?.message}>
              <Input
                placeholder="Электронная почта"
                {...register(`branches.${index}.email`, {
                  required: {
                    value: true,
                    message: 'Укажите электронную почту филиала',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Почта не должна быть больше 50 символов',
                  },
                  pattern: {
                    value: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                    message: 'Некорректная почта',
                  },
                })}
                error={errors?.email?.message}
              />
            </FormField>
          </Styles.Row>
          <Styles.Row>
            <Styles.FormLabel required>
              Регион доставки
            </Styles.FormLabel>
            <FormField error={errors?.region?.name?.message}>
              <Controller
                control={control}
                name={`branches.${index}.region.name`}
                render={({ field }) => (
                  <Select
                    placeholder="Регион"
                    value={_find(cities.map((city) => ({ label: city })), ['label', field.value])}
                    onChange={(value) => field.onChange(value?.label)}
                    options={cities.map((city) => ({ label: city }))}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ label }) => label}
                    isMulti={false}
                    isClearable
                    error={errors?.region?.name?.message}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Укажите регион',
                  },
                }}
              />
            </FormField>
          </Styles.Row>
          <Styles.Row>
            <Styles.FormLabel required>
              Адрес доставки
            </Styles.FormLabel>
            <FormField error={errors?.address?.message}>
              <Input
                placeholder="Улица, дом/корпус, офис"
                {...register(`branches.${index}.address`, {
                  required: {
                    value: true,
                    message: 'Укажите адрес доставки',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Адрес доставки не должен быть больше 100 символов',
                  },
                })}
                error={errors?.address?.message}
              />
            </FormField>
          </Styles.Row>
          {canRemove && (
            <Styles.Row>
              <span />
              <DeleteBranch
                index={index}
                onSubmit={() => onRemove(index)}
              />
            </Styles.Row>
          )}
        </Styles.Rows>
      )}
    </Styles.Branch>
  );
};
