import React, { FC } from 'react';
import _xor from 'lodash/xor';

import { Close } from 'src/ui/Icons';

import { Colors } from 'src/constants/colors';

import { useProvidersFilter } from '../../context/ProvidersFilter';
import { useComparedOrder } from '../../context/ComparedOrder';

import * as Styles from './ProvidersFilter.styles';

export const ProvidersFilter: FC = () => {
  const { filter, setFilter } = useProvidersFilter();
  const { comparedOrder } = useComparedOrder();

  if (!comparedOrder) {
    return null;
  }

  return (
    <Styles.Filters>
      {comparedOrder.offers.map((offer) => {
        const checked = filter.includes(offer.id);

        return (
          <Styles.Filter active={checked}>
            <Styles.FilterInput
              type="checkbox"
              id={`providers_filter_${offer.id}`}
              name="providers_filter"
              value={offer.id}
              checked={checked}
              onChange={({ target }) => setFilter(_xor(filter, [Number(target.value)]))}
            />
            <Styles.FilterLabel htmlFor={`providers_filter_${offer.id}`}>
              <Styles.FilterLabelText>
                {offer.organization}
              </Styles.FilterLabelText>
              {checked && (
                <Close color={Colors.DarkSlateBlue} />
              )}
            </Styles.FilterLabel>
          </Styles.Filter>
        );
      })}
    </Styles.Filters>
  );
};
