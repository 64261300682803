import { FC } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  target?: Element;
}

export const Portal: FC<PortalProps> = (props) => {
  const {
    children,
    target = document.body,
  } = props;

  return createPortal(children, target);
};
