import React, { forwardRef } from 'react';

import { Spinner } from '../Icons';

import { ButtonProps } from './Button.types';

import * as Styles from './Button.styles';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    loading,
    beforeIcon,
    afterIcon,
    children,
    disabled,
    ...other
  } = props;

  return (
    <Styles.Button
      ref={ref}
      {...other}
      loading={loading}
      disabled={disabled}
    >
      {!loading && beforeIcon}
      {beforeIcon && loading && (
        <Styles.Spinner>
          <Spinner />
        </Styles.Spinner>
      )}
      {children}
      {loading && (
        <Styles.Loader>
          <Styles.Spinner>
            <Spinner />
          </Styles.Spinner>
        </Styles.Loader>
      )}
      {!loading && afterIcon}
      {afterIcon && loading && (
        <Styles.Spinner>
          <Spinner />
        </Styles.Spinner>
      )}
    </Styles.Button>
  );
});
