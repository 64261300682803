import React, { ComponentPropsWithoutRef } from 'react';

export const BackArrow = (
  {
    width = 24,
    height = 24,
    color = 'currentColor',
    transform,
  }: ComponentPropsWithoutRef<'svg'>,
) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" transform={transform}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.54681 15.6682L13.6635 5.55046L13.6654 5.54851C13.7427 5.46982 13.8349 5.40731 13.9366 5.36464C14.0382 5.32197 14.1474 5.29999 14.2577 5.29999C14.368 5.29999 14.4772 5.32197 14.5789 5.36464C14.6805 5.40731 14.7729 5.47005 14.8502 5.54874C15.0045 5.70551 15.0909 5.91663 15.0909 6.13656C15.0909 6.3561 15.0048 6.56686 14.851 6.72354L14.8502 6.72438L6.16246 15.4121L27.9998 15.4121L28.0012 15.4121C28.1101 15.4116 28.218 15.4327 28.3186 15.4742C28.4193 15.5156 28.5108 15.5766 28.5878 15.6537C28.6647 15.7307 28.7256 15.8222 28.767 15.923C28.8084 16.0237 28.8294 16.1316 28.8288 16.2405V16.2421C28.8288 16.7111 28.4576 17.0874 27.9998 17.0874H6.16151L14.8501 25.7597C15.174 26.097 15.168 26.6347 14.8522 26.9498L14.85 26.952C14.7727 27.0307 14.6805 27.0932 14.5789 27.1359C14.4772 27.1785 14.368 27.2005 14.2577 27.2005C14.1474 27.2005 14.0382 27.1785 13.9366 27.1359C13.8349 27.0932 13.7427 27.0307 13.6654 26.952L3.55033 16.8368L3.54678 16.8334C3.46865 16.7578 3.40653 16.6673 3.3641 16.5672C3.32167 16.4671 3.2998 16.3595 3.2998 16.2508C3.2998 16.142 3.32167 16.0344 3.3641 15.9344C3.40653 15.8343 3.46868 15.7438 3.54681 15.6682Z" fill={color} />
  </svg>
);
