import React, { FC, useState } from 'react';
import { Tooltip, message } from 'antd';
import { NavLink, useParams } from 'react-router-dom';

import { Colors } from 'src/constants/colors';

import {
  Bin,
  LinkArrow,
  Lock,
  Plus,
} from 'src/ui/Icons';

import { usersApi } from 'src/api/api';

import { useCartContext } from 'src/pages/CompareProposalNew/context/Cart';

import { calculateGoodCost, getOffersWithMinimalGoodCost } from '../../../helpers';

import { CompareData, Good } from '../../../types';

import * as Styles from './CostRow.styles';

interface OfferRowProps {
  organization: string;
  id: number;
  good: Good;
  isOfferWithMinimalGoodCost: boolean;
}

const OfferRow: FC<OfferRowProps> = (props) => {
  const {
    organization,
    id,
    good,
    isOfferWithMinimalGoodCost,
  } = props;

  const { id: orderId } = useParams<{ id: string }>();

  const { cart, getCart } = useCartContext();

  const [status, setStatus] = useState('idle');

  const goodFromCart = cart.find(({ item }) => (
    item?.offersGoods.id === good.offersGoods.id
      && item?.offer.organization === organization
  ));

  const hasGoodInCart = Boolean(goodFromCart);

  const addGoodToCart = async () => {
    try {
      const cartGoods = [
        {
          productId: good.offersGoods.id,
          offerId: id,
          amount: good.amount
            ? good.amount
            : good.offersGoods.amount,
          fixedQuantity: Boolean(good.amount),
        },
      ];

      if (cart.length === 0) {
        await usersApi.createCart(Number(orderId), cartGoods);
      } else {
        await usersApi.updateCart(Number(orderId), cartGoods);
      }

      message.success('Товар добавлен в коризну', 3);
    } catch (e) {
      throw 'Произошла ошибка при добавлении товара из корзины!';
    }
  };

  const deleteGoodFromCart = async () => {
    try {
      await usersApi.deleteGoodFromCart(Number(orderId), good.offersGoods.id, goodFromCart!.id);

      message.success('Товар удален из корзины', 3);
    } catch (e) {
      throw 'Произошла ошибка при удалении товара из корзины!';
    }
  };

  const handleClick = async () => {
    try {
      setStatus('loading');

      if (hasGoodInCart) {
        await deleteGoodFromCart();
      } else {
        await addGoodToCart();
      }

      await getCart();
    } catch (e) {
      message.error(e);
    } finally {
      setStatus('idle');
    }
  };

  return (
    <Styles.TableRow>
      <Styles.TableProviderCell>
        <Styles.TableCellText clamp={1}>
          {organization}
        </Styles.TableCellText>
      </Styles.TableProviderCell>
      <Styles.TableCountCell>
        <Styles.TableCellText>
          {good.amount || good.offersGoods.amount}
        </Styles.TableCellText>
        <Styles.TableCellText>
          {`${good.offersGoods.type.name.slice(0, 2)}.`}
        </Styles.TableCellText>
        {good.amount && (
          <Tooltip
            trigger="hover"
            title="Поставщик зафиксировал количество. Это значит, что вы не сможете изменить его при заказе"
            placement="top"
          >
            <div>
              <Lock />
            </div>
          </Tooltip>
        )}
      </Styles.TableCountCell>
      <Styles.TableCommentCell>
        <Styles.TableCellText clamp={2}>
          {good.comment}
        </Styles.TableCellText>
      </Styles.TableCommentCell>
      <Styles.TableCostCell>
        <Styles.TableCellBadge style={{ backgroundColor: isOfferWithMinimalGoodCost ? Colors.SnowBallet : 'transparent' }}>
          {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(calculateGoodCost(good))}
        </Styles.TableCellBadge>
      </Styles.TableCostCell>
      <Styles.TableActionsCell>
        {hasGoodInCart ? (
          <Styles.TableCellLink forwardedAs={NavLink} to="/">
            <span className="large-container">
              Перейти в корзину
            </span>
            <span className="medium-container">
              Корзина
            </span>
            <span className="small-container">
              <Bin />
            </span>
          </Styles.TableCellLink>
        ) : (
          <Styles.TableCellButton
            variant="outlined"
            width="full"
            disabled={status === 'loading'}
            onClick={handleClick}
          >
            <span className="large-container">
              Добавить в корзину
            </span>
            <span className="medium-container">
              Добавить
            </span>
            <span className="small-container">
              <Plus />
            </span>
          </Styles.TableCellButton>
        )}
      </Styles.TableActionsCell>
    </Styles.TableRow>
  );
};

interface GoodTableProps {
  good: Good;
  goodIndex: number;
  offers: CompareData[];
}

export const GoodTable: FC<GoodTableProps> = (props) => {
  const { good, goodIndex, offers } = props;

  const [isBestOffersVisible, setIsBestOffersVisible] = useState<boolean>(true);

  const offersWithMinimalGoodCost = getOffersWithMinimalGoodCost(offers, good.offersGoods.id);
  const visibleOffers = isBestOffersVisible
    ? offersWithMinimalGoodCost
    : offers;

  return (
    <Styles.Good>
      <Styles.TableTitle>
        {good.offersGoods.name}
        {', '}
        {`${good.offersGoods.amount} ${good.offersGoods.type.name.slice(0, 2)}.`}
      </Styles.TableTitle>
      <Styles.Table>
        <Styles.TableHead>
          <Styles.TableHeadRow>
            <Styles.TableHeadProviderCell>
              Поставщик
            </Styles.TableHeadProviderCell>
            <Styles.TableHeadCountCell />
            <Styles.TableHeadCommentCell>
              Комментарий
            </Styles.TableHeadCommentCell>
            <Styles.TableHeadCostCell>
              Стоимость
            </Styles.TableHeadCostCell>
            <Styles.TableHeadActionsCell />
          </Styles.TableHeadRow>
        </Styles.TableHead>
        <Styles.TableBody>
          {visibleOffers.map((o) => (
            <OfferRow
              organization={o.organization}
              id={o.id}
              good={o.goods[goodIndex]}
              isOfferWithMinimalGoodCost={offersWithMinimalGoodCost.some(({ id }) => o.id === id)}
            />
          ))}
        </Styles.TableBody>
      </Styles.Table>
      <Styles.Toggle onClick={() => setIsBestOffersVisible(!isBestOffersVisible)}>
        <LinkArrow width={24} height={24} transform={isBestOffersVisible ? 'rotate(90)' : 'rotate(-90)'} />
        <span>
          {isBestOffersVisible ? 'Показать все предложения' : 'Отображать только лучшие предложения'}
        </span>
      </Styles.Toggle>
    </Styles.Good>
  );
};
