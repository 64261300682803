import { ChangeEvent, createContext, useContext } from 'react';

export interface TabsContextValue {
  value: string | null,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
}

export const TabsContext = createContext<TabsContextValue>({
  value: null,
  onChange: () => {},
});

export const useTabsContext = () => useContext(TabsContext);
