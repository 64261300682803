import styled from 'styled-components';

import { Button } from 'src/ui/Button';
import { TypographyStyles } from 'src/ui/Typography/Typography';
import { Colors } from 'src/constants/colors';

export const ModalBody = styled.div`
  width: 656px;
`;

export const Form = styled.form`
  margin-bottom: 56px;
`;

export const Text = styled.p`
  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};
`;

export const Buttons = styled.div`
  column-gap: 16px;

  display: flex;
`;

export const RemoveBranch = styled(Button)`
  justify-self: flex-start;

  ${TypographyStyles.Text};
  color: ${Colors.UltraViolet};
`;

export const SubmitButton = styled(Button)`
  ${TypographyStyles.TextMedium};
`;

export const CancelButton = styled(Button)`
  ${TypographyStyles.Text};
`;
