import { styled } from 'styled-components';

import { Colors } from 'src/constants/colors';
import { Button } from '../Button';
import { TypographyStyles } from '../Typography/Typography';

export const Toast = styled.div`
  position: fixed;

  left: 50%;
  top: 24px;
  transform: translate(-50%);

  box-shadow: 0px 0.13953px 1.3554px 0px rgba(66, 49, 137, 0.01), 0px 0.31702px 3.07958px 0px rgba(66, 49, 137, 0.02), 0px 0.55175px 5.35988px 0px rgba(66, 49, 137, 0.02), 0px 0.87651px 8.51466px 0px rgba(66, 49, 137, 0.03), 0px 1.35239px 13.13748px 0px rgba(66, 49, 137, 0.03), 0px 2.1103px 20.50001px 0px rgba(66, 49, 137, 0.03), 0px 3.50342px 34.0332px 0px rgba(66, 49, 137, 0.04), 0px 7px 68px 0px rgba(66, 49, 137, 0.07);
  border-radius: 8px;

  background-color: ${Colors.White};

  @media screen and (min-width: 1024px) {
    left: auto;
    top: auto;
    bottom: 56px;
    right: 24px;
    transform: none;
  }

  @media screen and (min-width: 1280px) {
    right: 48px;
  }
`;

export const ToastBody = styled.div`
  align-items: center;

  display: flex;

  padding: 8px 16px;
  box-shadow: 0px 0.13952694833278656px 1.3554046154022217px 0px rgba(66, 49, 137, 0.01), 0px 0.31701597571372986px 3.0795838832855225px 0px rgba(66, 49, 137, 0.02), 0px 0.5517522692680359px 5.359879016876221px 0px rgba(66, 49, 137, 0.02), 0px 0.8765086531639099px 8.514655113220215px 0px rgba(66, 49, 137, 0.03), 0px 1.3523876667022705px 13.137480735778809px 0px rgba(66, 49, 137, 0.03), 0px 2.110295057296753px 20.500009536743164px 0px rgba(66, 49, 137, 0.03), 0px 3.503418207168579px 34.033203125px 0px rgba(66, 49, 137, 0.04), 0px 7px 68px 0px rgba(66, 49, 137, 0.05);
`;

export const ToastIcon = styled.div`
  flex-shrink: 0;
`;

export const ToastText = styled.p`
  margin-left: 16px;
  margin-right: 56px;
  max-width: 280px;

  ${TypographyStyles.Text};
`;

export const CloseButton = styled(Button)`
  align-self: flex-start;

  margin-left: auto;

  color: ${Colors.RoughStone};
`;
