import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Colors } from 'src/constants/colors';

export const Arrow = styled.span`
  position: absolute;
  transform: translate(0, -50%) rotate(-45deg);
  top: 48px;
  right: 32px;

  width: 32px;
  height: 32px;

  @media screen and (min-width: 768px) {
    top: 48px;
    right: 44px;

    width: 36px;
    height: 28px;
  }

  @media screen and (min-width: 1024px) {
    top: 48px;
    right: 36px;
  }

  @media screen and (min-width: 1280px) {
    right: 48px;

    width: 28px;
    height: 28px;
  }

  @media screen and (min-width: 1920px) {
    top: 72px;
    right: 72px;

    width: 72px;
    height: 72px;
  }
`;

// TODO: что-то придумать с сеткой

export const GridContainer = styled.div`
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    margin-bottom: 56px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 64px;
  }

  @media screen and (min-width: 1920px) {
    margin-bottom: 96px;
  }
`;

export const Grid = styled.div`
  display: grid; 

  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 144px);
  grid-template-areas:
    "market"
    "logo"
    "order"
    "offer";

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 212px);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr); 
    grid-template-rows: 161px 266px;
    grid-template-areas:
      "market market market market market market market logo logo logo logo logo"
      "order order order order order offer offer offer offer offer offer offer";
  }

  @media screen and (min-width: 1920px) {
    grid-template-rows: 259px 425px; 
  }
`;

export const CustomerGrid = styled.div`
  display: grid; 

  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 192px);
  grid-template-areas:
    "market"
    "logo"
    "order";

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 283px);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr); 
    grid-template-rows: 161px 266px;
    grid-template-areas:
      "market market market market market market market logo logo logo logo logo"
      "order order order order order order order logo logo logo logo logo";
  }

  @media screen and (min-width: 1920px) {
    grid-template-rows: 259px 425px; 
  }
`;

export const ProviderGrid = styled.div`
  display: grid; 

  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 192px);
  grid-template-areas:
    "market"
    "logo"
    "offer";

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 283px);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr); 
    grid-template-rows: 161px 266px;
    grid-template-areas:
      "market market market market market market market logo logo logo logo logo"
      "offer offer offer offer offer offer offer logo logo logo logo logo";
  }

  @media screen and (min-width: 1920px) {
    grid-template-rows: 259px 425px; 
  }
`;

const GridItemLink = css`
  position: relative;

  align-items: flex-end;

  display: flex;
`;

export const GridItemContent = styled.div``;

export const GridItemTitle = styled.h2`
  margin-bottom: 0;

  font-family: 'Montserrat';
  font-style: normal;
  font-size: 26px;
  font-weight: 500;
  line-height: 130%;

  color: inherit;

  transition: transform 0.3s ease-out;
  transform-origin: top left;

  @media screen and (min-width: 768px) {
    font-size: 32px;
    font-weight: 500;
    line-height: 130%;
  }

  @media screen and (min-width: 1920px) {
    font-size: 52px;
    line-height: 120%;
  }
`;

export const GridItemText = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;

  @media screen and (min-width: 768px) {
    margin-top: 8px;

    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }
`;

export const GridItemHover = css`
  ${GridItemTitle} {
    transform: scale(1.1);
  }
`;

export const MarketGridItem = styled(NavLink)`
  grid-area: market;

  ${GridItemLink};

  padding: 24px 24px 36px;

  background-color: ${Colors.Glen};

  color: ${Colors.White};

  transition: background-color 0.3s ease-out;

  &:visited {
    color: ${Colors.White};
  }

  &:hover {
    background-color: ${Colors.GreenMoray};

    color: ${Colors.White};

    ${GridItemHover};
  }

  @media screen and (min-width: 768px) {
    border-radius: 20px;
    padding: 24px 36px 64px;
  }

  @media screen and (min-width: 1024px) {
    padding: 24px 36px;
  }

  @media screen and (min-width: 1280px) {
    padding: 24px 36px;
  }

  @media screen and (min-width: 1920px) {
    border-radius: 32px;
    padding: 24px 64px 48px 64px;
  }
`;

export const LogoGridItem = styled(NavLink)`
  position: relative;

  grid-area: logo;

  align-items: center;

  display: flex;

  border: 1px solid ${Colors.Black};
  padding: 0 0 0 16px;

  color: ${Colors.RoughStone};

  img {
    height: 97px;
    width: auto;

    transition: transform 0.3s ease-out;
    transform-origin: center left;
  }

  &:visited {
    color: ${Colors.RoughStone};
  }

  &:hover {
    color: ${Colors.RoughStone};

    img {
      transform: scale(1.20);
    }
  }

  @media screen and (min-width: 768px) {
    border-radius: 20px;
    padding: 0 0 0 24px;

    img {
      height: clamp(140px, 60%, 154px);
      width: auto;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 0 0 0 20px;

    img {
      height: clamp(99px, 60%, 133px);
      width: auto;
    }
  }

  @media screen and (min-width: 1920px) {
    border-radius: 32px;
    padding: 0 0 0 32px;

    img {
      height: clamp(167px, 33%, 233px);
      width: auto;
    }
  }
`;

export const OrderGridItem = styled(NavLink)`
  grid-area: order;

  ${GridItemLink};

  padding: 24px 24px 16px;

  background-color: ${Colors.UltraViolet};

  color: ${Colors.White};

  &:visited {
    color: ${Colors.White};
  }

  &:hover {
    background-color: ${Colors.DarkSlateBlue};

    color: ${Colors.White};

    ${GridItemHover};
  }

  @media screen and (min-width: 768px) {
    border-radius: 20px;
    padding: 24px 36px 36px;
  }

  @media screen and (min-width: 1024px) {
    padding: 24px 36px 36px 36px;
  }

  @media screen and (min-width: 1920px) {
    border-radius: 32px;
    padding: 24px 64px 64px 64px;
  }
`;

export const OfferGridItem = styled(NavLink)`
  grid-area: offer;

  ${GridItemLink};

  padding: 24px 24px 16px;

  background-color: ${Colors.Lavender};

  color: ${Colors.MajesticEggplant};

  &:visited {
    color: ${Colors.MajesticEggplant};
  }

  &:hover {
    background-color: ${Colors.LittleDipper};

    color: ${Colors.MajesticEggplant};

    ${GridItemHover};
  }

  @media screen and (min-width: 768px) {
    border-radius: 20px;
    padding: 24px 36px 36px;
  }

  @media screen and (min-width: 1024px) {
    padding: 24px 36px 36px 36px;
  }

  @media screen and (min-width: 1920px) {
    border-radius: 32px;
    padding: 24px 64px 64px 64px;
  }
`;

export const Links = styled.ul`
  gap: 24px;
  justify-content: center;

  display: flex;

  margin: 0;

  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const Link = styled.li`
  align-items: center;
  justify-content: center;

  display: flex;

  width: 48px;
  height: 48px;
`;
