/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import 'form-request-submit-polyfill';
import { QueryClientProvider, QueryClient } from 'react-query';

import './index.css';

import { store } from './store';
import App from './App';
import history from './helpers/history';
import ErrorBoundary from './components/ErrorBoundary';
import { debugApi } from './api/api';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <Provider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>
      </QueryParamProvider>
    </Router>
  </QueryClientProvider>,

  document.getElementById('root'),
);

window.onerror = (msg, url, lineNo, columnNo, error) => {
  const obj = {
    type: 'Default',
    msg,
    location: `${url}:${lineNo}:${columnNo}`,
    stack: error?.stack,
  };

  debugApi.log('soft', obj);
};

window.addEventListener('unhandledrejection', (event) => {
  const { reason } = event;

  if (reason.config.url.includes('/front')) {
    return;
  }

  const defaultObj = {
    type: 'Promise',
  };

  try {
    const obj = {
      ...defaultObj,
      status: reason.response.request.status,
      requestUrl: reason.response.request.responseURL,
      response: reason.response.request.responseText,
    };

    debugApi.log('soft', obj);
  } catch (e) {
    const obj = {
      ...defaultObj,
      reason,
    };

    debugApi.log('soft', obj);
  }
});
