import styled from 'styled-components';

import { Colors } from 'src/constants/colors';

import { Button } from 'src/ui/Button';
import { Input as BaseInput } from 'src/ui/Input';
import { TypographyStyles } from 'src/ui/Typography/Typography';
import { NavLink } from 'react-router-dom';

export const Title = styled.h1`
  margin-bottom: 24px;

  ${TypographyStyles.H3};
  color: ${Colors.BluePlaza};

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;

    ${TypographyStyles.H2};
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 56px;
  }
`;

export const Form = styled.form`
  margin-bottom: 56px;
`;

export const Fieldset = styled.fieldset`
  flex-direction: column;
  row-gap: 24px;

  display: flex;

  & + & {
    margin-top: 56px;
  }
`;

export const Field = styled.div`
  justify-content: space-between;
  align-items: baseline;
  grid-template-columns: 1fr;
  row-gap: 8px;

  display: grid;

  @media screen and (min-width: 1280px) {
    grid-template-columns: 35% 65%;
  }
`;

export const FieldLabel = styled.span<{ required?: boolean }>`
  ${TypographyStyles.Text};

  ${({ required }) => required && `
    &::after {
      content: '*';

      color: ${Colors.ChineseGoldfish};
    }
  `}
`;

export const Input = styled(BaseInput)``;

export const EmailBlock = styled.div`
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;

  display: flex;
`;

export const PasswordBlock = styled.div`
  flex-direction: column;
  row-gap: 24px;

  display: flex;
`;

export const SubmitButton = styled(Button)`
  margin-top: 56px;
  width: 100%;

  ${TypographyStyles.Text};

  @media screen and (min-width: 768px) {
    margin-top: 96px;
    width: auto;
  }
`;

export const Link = styled(NavLink)`
  display: inline-block;
  width: 100%;
  
  ${TypographyStyles.Text};
  color: ${Colors.UltraViolet};
  text-align: center;

  &:hover {
    color: ${Colors.UltraViolet};
  }

  @media screen and (min-width: 768px) {
    width: auto;
  }
`;
