import { UserTypes } from 'src/constants/enums';
import { RootState } from '..';

const createSelector = (...fns: ((store: RootState) => any)[]) => {
  const resultFnIndex = fns.length - 1;
  const resultFn = fns[resultFnIndex];

  return (store: RootState) => resultFn(
    ...fns
      .slice(0, resultFnIndex)
      .map((fn) => fn(store)),
  );
};

export const selectUserProfileStore = (store: RootState) => store.userProfile;

export const selectIsCustomer = createSelector(
  selectUserProfileStore,
  ({ type }) => type === UserTypes.Customer,
);

export const selectIsBranch = createSelector(
  selectUserProfileStore,
  ({ type }) => type === UserTypes.Branch,
);

export const selectIsProvider = createSelector(
  selectUserProfileStore,
  ({ type }) => type === UserTypes.Employee
    || type === UserTypes.Provider,
);

export const selectIsCustomerType = createSelector(
  selectIsBranch,
  selectIsCustomer,
  (isBranch, isCustomer) => isBranch || isCustomer,
);

export const selectIsCustomerOrProvider = createSelector(
  selectIsCustomer,
  selectIsProvider,
  (isCustomer, isProvider) => isCustomer || isProvider,
);
