/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import { message, Table, TablePaginationConfig } from 'antd';
import Column from 'antd/es/table/Column';
import { DebounceInput } from 'react-debounce-input';
import useDebounce from 'src/hooks/useDebounce';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { AxiosResponse } from 'axios';
import { LoadingButton } from 'src/components/shared-components/Loading';
import { FilterValue } from 'antd/lib/table/interface';
import { usersApi } from '../../../api/api';
import getCookie from '../../../helpers/getCookie';
import useActions from '../../../hooks/useActions';
import binImg from '../../../assets/img/bin.svg';
import cartImg from '../../../assets/img/cart.svg';
import crossImg from '../../../assets/img/cross.svg';
import { CompareData } from '../types';

import s from './CartModal.module.css';
import './CartModal.css';

interface CartModalProps {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  testCartDataFromServer: any;
  setResponseFromServer: React.Dispatch<React.SetStateAction<string>>;
  isCloseOrderBtnVisible: any;
  compareData: CompareData[];
  updateCompareData: () => void;
  testCartDataFilter: string | null;
  setTestCartDataFilter: (filter: string | null) => void;
}

interface CartItem {
  fixedQuantity: boolean;
  id: number;
  item: ICartData;
  amount: number;
}

interface ICartData {
  comment: string | null;
  offersGoods: {
    id: number;
    code: string;
    name: string;
    amount: string | number;
    price: string | number;
  };
  offer: {
    organization: string;
    offerUser: {
      email: string;
      id: number;
    }
  };
  organization?: string;
  total?: number;
  price: string;
}

const CartModal: React.FC<CartModalProps> = ({
  isCloseOrderBtnVisible,
  isModal,
  setIsModal,
  testCartDataFromServer,
  setResponseFromServer,
  compareData,
  updateCompareData,
  testCartDataFilter,
  setTestCartDataFilter,
}) => {
  const { showErr } = useActions();
  const [cartItems, setCartItems] = useState<Array<CartItem>>([]);
  const [editedCartItem, setEditedCartItem] = useState<string | number | null>(null);
  const [nameGoodsOrder, setNameGoodsOrder] = useState<any>({ cartItem: {}, name: '' });
  const [isPropostalSending, setIsPropostalSending] = useState(false);

  const inputRef = useRef<any>(null);
  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'start',
    });

    return () => {
      setTestCartDataFilter(null);
    };
  }, []);

  const id = window.location.href.split('/')[window.location.href.split('/').length - 1];

  useEffect(() => {
    const newCartData = Array.isArray(testCartDataFromServer) && testCartDataFromServer[0]?.item
      ? testCartDataFromServer
      : [];

    setCartItems(newCartData);
  }, [testCartDataFromServer]);

  const deleteItemFromCartServer = async (cartItem: any) => {
    usersApi.deleteGoodFromCart(
      Number(id),
      cartItem?.item?.offersGoods?.id,
      cartItem.id,
    ).then((r: any) => {
      setResponseFromServer(`${r} ${Math.random()}`);
      message.error('Позиция удалена из корзины!');
    });
  };

  const deleteItemFromCart = (cartItem: ICartData) => {
    deleteItemFromCartServer(cartItem);
  };

  const sendProposals = async () => {
    setIsPropostalSending(true);
    const token: string | undefined | null = await getCookie('Authentication');

    const filteredTestCartDataFromServer = testCartDataFromServer.filter(({ item }: CartItem) => {
      if (!testCartDataFilter) {
        const { active } = compareData.find(({ organization }) => (
          organization === item.offer.organization
        )) || {};

        return active;
      }

      return item.offer.organization === testCartDataFilter;
    });

    usersApi.sendCompareOffer(token, id, filteredTestCartDataFromServer).then((res: any) => {
      setIsPropostalSending(false);
      if (res.data.result) {
        updateCompareData();
        showErr('Заявки отправлены!');
      } else showErr('Ошибка, после закрытия заявки отправка невозможна');
    });
  };

  const changeCartItemQuantity = async (cartItem: any, indexRender: number, e: any) => {
    const { value } = e.target;

    const { id: offerId } = compareData.find(({ organization }) => (
      organization === cartItem.item.offer.organization
    )) || {};

    if (!offerId) {
      message.error('Произошла ошибка при изменении кол-ва товара');

      return;
    }

    const goods = [
      {

        productId: cartItem.item.offersGoods.id,

        offerId,
        amount: Number(value),

        fixedQuantity: cartItem.fixedQuantity,

        id: cartItem.id,
      },
    ];

    if (Number(value) > 0) {
      await usersApi.deleteGoodFromCart(
        Number(id),
        cartItem.item.offersGoods.id,
        cartItem.id,
      ).then((r) => setResponseFromServer({
        ...r.data,
        id: cartItem.id,
        index: indexRender,
      }));

      await usersApi.updateCart(Number(id), goods).then((r) => {
        setResponseFromServer({
          ...r.data,
          id: cartItem.id,
          index: indexRender,
        });
      });
    }
  };
  const debounceChangeName = useDebounce(nameGoodsOrder.name, 500);

  useEffect(() => {
    const token: string | null | undefined = getCookie('Authentication');

    if (debounceChangeName) {
      const { id: offerId } = compareData.find(({ organization }) => (
        organization === nameGoodsOrder?.cartItem?.item?.offer?.organization
      )) || {};

      if (!offerId) {
        message.error('Произошла ошибка при изменении названия товара');

        return;
      }

      const goods = [
        {
          productId: nameGoodsOrder?.cartItem?.item?.offersGoods.id,
          goods: { ...nameGoodsOrder?.cartItem?.item?.offersGoods, name: nameGoodsOrder.name },
        },
      ];

      usersApi.updateOrdersGoods(token, id, goods[0])
        .then((r: AxiosResponse) => {
          setResponseFromServer({
            ...r.data,
            id: nameGoodsOrder.cartItem.id,
            index: nameGoodsOrder.indexRender,
          });
        });
    }
  }, [debounceChangeName]);

  const changeNameOrderGoods = (cartItem: CartItem, e: any, indexRender: number) => {
    setNameGoodsOrder({ cartItem, name: e, indexRender });
    setCartItems((prev: any) => prev.map((elem: any) => {
      if (editedCartItem === elem.item?.offersGoods?.id) {
        return {
          ...elem,
          item: {
            ...elem.item,
            offersGoods: {
              ...elem.item.offersGoods,
              name: e,
            },
          },
        };
      }
      return elem;
    }));
  };

  const handleTableChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
  ) => {
    setTestCartDataFilter((filters.email && filters.email[0]) as string);
  };

  const filteredCartItems = useMemo(() => (
    cartItems.filter(({ item }) => (
      !testCartDataFilter || item.offer.organization === testCartDataFilter
    ))
  ), [cartItems, testCartDataFilter]);

  const providerFilters = useMemo(() => {
    const uniqueCartItems = new Set(cartItems.map(({ item }) => item.offer.organization));

    return Array.from(uniqueCartItems)
      .map((organization) => ({
        text: organization,
        value: organization,
      }));
  }, [cartItems]);

  const totalCartPrice = useMemo(() => (
    filteredCartItems.reduce((memo, { item, amount }) => (
      memo += Number(item.price) * amount
    ), 0)
  ), [filteredCartItems]);

  return (
    <>
      <div className={`${s.fade} ${isModal && s.active}`} onClick={() => setIsModal(false)} />
      <div className={`${s.cart} ${isModal && s.active}`}>
        <div className={s.inner}>
          <div className={s.top}>
            <div className={s.header}>
              <div className={s.left}>
                <div className={s.cart_img}>
                  <img src={cartImg} alt="cartImg" />
                </div>
                <div className={s.title}>Выбранные предложения</div>
              </div>
              <div className={s.cross} onClick={() => setIsModal(false)}>
                <img src={crossImg} alt="crossImg" />
              </div>
            </div>
            <div className={s.table}>
              <Table
                pagination={false}
                className="cart_table"
                size="small"
                dataSource={filteredCartItems}
                scroll={{ x: 900 }}
                bordered
                onChange={handleTableChange}
              >
                <Column
                  align="center"
                  width={150}
                  title="Поставщик"
                  dataIndex="email"
                  key="email"
                  fixed="left"
                  render={(_: any, cartItem: any) => <div>{cartItem.item.offer.organization}</div>}
                  sorter={(a, b) => {
                    if (a.item.offer.organization > b.item.offer.organization) {
                      return 1;
                    } if (a.item.offer.organization < b.item.offer.organization) {
                      return -1;
                    }
                    return 0;
                  }}
                  defaultSortOrder="ascend"
                  filters={providerFilters}
                  filterMultiple={false}
                />
                <Column
                  align="center"
                  width={150}
                  title="Наименование товара"
                  dataIndex="name"
                  key="name"
                  fixed="left"
                  render={(_: string, cartItem: CartItem, indexRender: number) => (
                    <div className={s.nameTextareaBlock}>
                      {editedCartItem === cartItem?.item?.offersGoods?.id
                        ? (
                          <TextArea
                            className={s.nameTextarea}
                            autoSize={{ minRows: 1, maxRows: 6 }}
                            bordered={false}
                            ref={inputRef && inputRef}
                            value={cartItem.item.offersGoods.name}
                            onChange={({ target }) => {
                              changeNameOrderGoods(cartItem, target.value, indexRender);
                            }}
                          />
                        )
                        : (
                          <div className={s.nameTextarea}>
                            {cartItem.item?.offersGoods?.name}
                          </div>
                        )}
                      <EditOutlined onClick={() => {
                        setEditedCartItem((prev) => (prev
                          ? null : cartItem?.item?.offersGoods?.id || null));
                      }}
                      />
                    </div>
                  )}
                />
                <Column
                  align="center"
                  width={109}
                  title="Количество"
                  dataIndex="amount"
                  key="amount"
                  render={(nothing: any, cartItem: any, indexRender: number) => (
                    <DebounceInput
                      step={1}
                      type="number"
                      min={1}
                      disabled={cartItem.fixedQuantity}
                      onKeyDown={(e: any) => {
                        if (e.key === '.' || e.key === '-' || e.key === ',' || e.key === '/' || e.key === ('+' || '-' || '=')) {
                          e.preventDefault();
                        }
                      }}
                      debounceTimeout={500}
                      onChange={(e: any) => changeCartItemQuantity(cartItem, indexRender, e)}
                      value={cartItem.amount === null
                        ? cartItem.item.offersGoods.amount : cartItem.amount}
                      className={`${s.centred} ${s.w100}`}
                    />
                  )}
                />
                <Column
                  align="center"
                  width={127}
                  title="Цена за товар"
                  dataIndex="price"
                  key="price"
                  render={(nothing: any, cartItem: any) => <div>{cartItem.item.price}</div>}
                />
                <Column
                  align="center"
                  width={105}
                  title="Цена итого"
                  dataIndex="total"
                  key="total"
                  render={(_: any, cartItem: any) => (
                    <div>
                      {(Number(cartItem.item.price) * cartItem.amount).toFixed(2)}
                    </div>
                  )}
                />
                <Column
                  align="center"
                  className={`${s.clear_bin} cart_column`}
                  width={57}
                  title=""
                  dataIndex="clear"
                  key="clear"
                  render={(nothing: any, cartItem: any) => (
                    <img
                      onClick={() => deleteItemFromCart(cartItem)}
                      alt="очистить поле"
                      src={binImg}
                    />
                  )}
                />
                ))

              </Table>
              <div className={s.total_price}>
                <span>
                  Итого товаров:
                  {' '}
                  {filteredCartItems.length}
                  {' '}
                  на сумму
                  {' '}
                  {totalCartPrice.toFixed(2)}
                  {' '}
                  ₽
                </span>
              </div>
            </div>
          </div>
          <div className={s.footer}>
            {isCloseOrderBtnVisible && (
              <>
                <div className={s.info}>
                  *после клика на кнопку справа мы разошлем на почты поставщиков
                  письма с выбранными товарами.
                </div>
                <div onClick={() => sendProposals()} className={s.button}>
                  Отправить заявки
                  {isPropostalSending && <button type="button"><LoadingButton /></button>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CartModal;
