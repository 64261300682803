import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Colors } from 'src/constants/colors';
import { TypographyStyles } from 'src/ui/Typography/Typography';

export const Content = styled.div`
  container-type: inline-size;

  flex-direction: column;
  flex-grow: 1;
  row-gap: 32px;
  
  display: flex;
`;

export const Menu = styled.ul`
  margin: 0;
`;

export const LinkIcon = styled.span`
  flex-shrink: 0;

  display: block;
`;

export const LinkText = styled.span`
  opacity: 0;

  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};
  white-space: nowrap;

  transition: opacity .1s ease-in-out;

  @container (min-width: 272px) {
    opacity: 1;
  }
`;

export const Link = styled(NavLink)`
  position: relative;
  
  align-items: center;
  gap: 12px;
  flex-wrap: nowrap;

  display: flex;

  padding: 16px;
  min-height: 64px;

  && {
    color: ${Colors.RoughStone};
  }

  &:hover,
  &.active {
    background-color: ${Colors.Lavender};

    color: ${Colors.MajesticEggplant};

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 4px;
      height: 100%;

      background-color: ${Colors.UltraViolet};
    }

    ${LinkText} {
      color: ${Colors.MajesticEggplant};
    }
  }

  @container (min-width: 375px) {
    padding: 16px 24px;
  }

  @media screen and (min-width: 768px) {
    @container (min-width: 375px) {
      padding: 16px 40px;
    }
  }
`;

export const UserLink = styled(Link)`
  @container (min-width: 272px) {
    padding: 10px 16px;
  }

  @container (min-width: 375px) {
    padding: 16px 24px;
  }

  @media screen and (min-width: 768px) {
    @container (min-width: 375px) {
      padding: 16px 40px;
    }
  }
`;

export const Arrow = styled.span`
  margin-left: auto;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const UserAvatar = styled.div`
  @container (min-width: 272px) {
    margin-right: 4px;
  }
`;

export const UserName = styled.span`
  display: none;

  @container (min-width: 272px) {
    display: block;

    ${TypographyStyles.TextMedium};
  }
`;

export const UserEmail = styled.span`
  display: none;

  @container (min-width: 272px) {
    display: block;

    ${TypographyStyles.TextSmall};
  }
`;
