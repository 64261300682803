import styled, { css } from 'styled-components';

import { Colors } from 'src/constants/colors';
import { TypographyStyles } from 'src/ui/Typography/Typography';
import { Button } from 'src/ui/Button';

const IdCellMixin = css`
  flex-basis: 120px;

  text-align: left;

  @container order-layout (min-width: 944px) {
    flex-basis: 160px;
  }

  @container order-layout (min-width: 1516px) {
    flex-basis: 200px;
  }
`;

const DateCellMixin = css`
  flex-basis: 90px;

  text-align: left;
`;

const ProviderTypeCellMixin = css`
  flex-basis: 100px;

  text-align: left;

  @container order-layout (min-width: 944px) {
    flex-basis: 120px;
  }

  @container order-layout (min-width: 1516px) {
    flex-basis: 360px;
  }
`;

const ProvidersCellMixin = css`
  flex-basis: 0;
  flex-grow: 1;

  text-align: left;
`;

const GoodsCountCellMixin = css`
  flex-basis: 62px;

  text-align: right;
`;

const OffersCountCellMixin = css`
  flex-basis: 64px;

  text-align: right;
`;

const ActionsCellMixin = css`
  flex-basis: 24px;

  @container order-layout (min-width: 944px) {
    flex-basis: 182px;
  }
`;

export const Table = styled.table`
  table-layout: fixed;
  
  width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody`
  &:not(:last-child) {
    border-bottom: 56px solid transparent;
  }
`;

export const TableHeadRow = styled.tr`
  container: order-layout / inline-size;

  column-gap: 16px;

  display: flex;

  height: 28px;
  
  @media screen and (min-width: 1280px) {
    column-gap: 20px;
  }
`;

export const TableHeadCell = styled.th`
  ${TypographyStyles.TextSmall};
  color: ${Colors.BluePlaza};
`;

export const TableHeadIdCell = styled(TableHeadCell)`
  ${IdCellMixin};
`;

export const TableHeadDateCell = styled(TableHeadCell)`
  ${DateCellMixin};
`;

export const TableHeadProviderTypeCell = styled(TableHeadCell)`
  ${ProviderTypeCellMixin};
`;

export const TableHeadProvidersCell = styled(TableHeadCell)`
  ${ProvidersCellMixin};
`;

export const TableHeadGoodsCountCell = styled(TableHeadCell)`
  ${GoodsCountCellMixin};
`;

export const TableHeadOffersCountCell = styled(TableHeadCell)`
  ${OffersCountCellMixin};
`;

export const TableHeadActionsCell = styled(TableHeadCell)`
  ${ActionsCellMixin};
`;

export const TableRow = styled.tr`
  container: order-layout / inline-size;

  align-items: center;
  column-gap: 16px;

  display: flex;

  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 56px;

  &:first-child {
    padding-top: 0;
    min-height: 48px;
  }

  &:first-child:last-child {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 40px;
  }

  &:last-child:not(:first-child) {
    padding-bottom: 0;
    min-height: 48px;
  }

  &:not(:last-child) {
    box-shadow: 0 0.5px 0 0 ${Colors.LittleDipper};
  }

  @media screen and (min-width: 1280px) {
    column-gap: 20px;
  }
`;

export const TableCell = styled.td``;

export const TableIdCell = styled(TableCell)`
  ${IdCellMixin};
`;

export const TableDateCell = styled(TableCell)`
  ${DateCellMixin};

  column-gap: 16px;

  display: flex;
`;

export const TableProviderTypeCell = styled(TableCell)`
  ${ProviderTypeCellMixin};
`;

export const TableProvidersCell = styled(TableCell)`
  ${ProvidersCellMixin};
`;

export const TableGoodsCountCell = styled(TableCell)`
  ${GoodsCountCellMixin};
`;

export const TableOffersCountCell = styled(TableCell)`
  ${OffersCountCellMixin};
`;

export const TableActionsCell = styled(TableCell)`
  column-gap: 16px;
  align-items: center;

  display: flex;

  ${ActionsCellMixin};
`;

export const TableCellText = styled.span<{ clamp?: number }>`
  display: block;

  color: ${Colors.BluePlaza};
  ${TypographyStyles.TextSmall};

  ${({ clamp }) => clamp && `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${clamp};
    word-break: break-all;

    overflow: hidden;
  `}

  @media screen and (min-width: 1280px) {
    ${TypographyStyles.Text};
  }
`;

export const TableCellButton = styled(Button)`
  display: none;

  @media screen and (min-width: 1280px) {
    display: flex;

    ${TypographyStyles.Text};
  }
`;
