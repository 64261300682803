import styled from 'styled-components';

import { Colors } from 'src/constants/colors';

import { Button } from '../Button';
import { TypographyStyles } from '../Typography/Typography';

export const Tooltip = styled.div`
  position: absolute;

  box-shadow: 0px 0.14px 1.355px 0px rgba(66, 49, 137, 0.01), 0px 0.317px 3.08px 0px rgba(66, 49, 137, 0.02), 0px 0.552px 5.36px 0px rgba(66, 49, 137, 0.02), 0px 0.877px 8.515px 0px rgba(66, 49, 137, 0.03), 0px 1.352px 13.137px 0px rgba(66, 49, 137, 0.03), 0px 2.11px 20.5px 0px rgba(66, 49, 137, 0.03), 0px 3.503px 34.033px 0px rgba(66, 49, 137, 0.04), 0px 7px 68px 0px rgba(66, 49, 137, 0.07);
  border-radius: 8px;

  background-color: ${Colors.White};
`;

export const List = styled.ul`
  margin: 0;
`;

export const Item = styled.li``;

export const ItemButton = styled(Button)`
  justify-content: flex-start;

  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};
`;
