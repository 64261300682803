import React, { useState } from 'react';

import routes from 'src/routes';

import { DoubleArrow } from 'src/ui/Icons';

import { SidebarMenu } from '../SidebarMenu';

import * as Styles from './AppSidebar.styles';

export const AppSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Styles.Sidebar isCollapsed={isCollapsed}>
      <Styles.Header>
        <Styles.Title to={routes.index}>
          <svg width="89" height="48" viewBox="0 0 89 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1408_162)">
              <path d="M32.716 28.7891V27.6717C31.9321 28.521 30.9986 28.9449 29.9155 28.9449C28.622 28.9449 27.5903 28.5335 26.8204 27.7106C26.049 26.8878 25.6641 25.7969 25.6641 24.4395C25.6641 23.068 26.1176 21.9491 27.0261 21.0795C27.9332 20.2114 29.0864 19.7766 30.4843 19.7766C31.3851 19.7766 32.13 19.9854 32.7176 20.4031V15.0764H34.2854V28.7891H32.716ZM30.2085 27.4753C31.1747 27.4753 32.01 27.0841 32.716 26.3003V21.9319C31.9976 21.4753 31.2729 21.2462 30.542 21.2462C29.6412 21.2462 28.8791 21.5377 28.2604 22.1174C27.6402 22.6987 27.33 23.4592 27.33 24.399C27.33 25.3527 27.5981 26.1039 28.1326 26.6525C28.6687 27.201 29.3607 27.4753 30.2085 27.4753Z" fill="#1191D0" />
              <path d="M43.8637 26.5948L44.353 27.8291C43.5037 28.6005 42.4003 28.9854 41.0429 28.9854C39.62 28.9854 38.4761 28.5678 37.6143 27.7325C36.7525 26.8971 36.3208 25.7797 36.3208 24.3834C36.3208 23.0509 36.726 21.9506 37.5348 21.0826C38.3437 20.2145 39.4548 19.7797 40.8652 19.7797C42.0278 19.7797 43.0003 20.1553 43.7842 20.9065C44.5681 21.6576 44.9592 22.627 44.9592 23.8161C44.9592 24.2213 44.9265 24.5735 44.8611 24.8743H38.0834C38.1489 25.6971 38.459 26.3439 39.0138 26.813C39.5686 27.2836 40.2839 27.5174 41.1582 27.5174C42.3083 27.5143 43.2091 27.2088 43.8637 26.5948ZM40.7873 21.2462C40.0034 21.2462 39.3644 21.4644 38.8673 21.9023C38.3702 22.3402 38.0772 22.9044 37.9852 23.5963H43.3338C43.2948 22.8654 43.0502 22.2904 42.5998 21.8727C42.1494 21.455 41.5447 21.2462 40.7873 21.2462Z" fill="#1191D0" />
              <path d="M46.8994 28.7891V19.9745H48.4672V21.0514C48.7929 20.6992 49.2184 20.4047 49.7405 20.1693C50.2625 19.934 50.7986 19.8171 51.3472 19.8171C53.7893 19.8171 55.0111 21.1106 55.0111 23.6961V28.7891H53.4433V23.9891C53.4433 22.1875 52.6142 21.2852 50.956 21.2852C49.9633 21.2852 49.1342 21.6639 48.4688 22.4213V28.7875H46.8994V28.7891Z" fill="#1191D0" />
              <path d="M57.2612 25.4384V17.5247H58.829V19.973H62.0612V21.4426H58.829V25.2826C58.829 26.746 59.3511 27.4769 60.3968 27.4769C60.8924 27.4769 61.3568 27.3132 61.7869 26.9875L62.2763 28.2015C61.6747 28.6987 60.9251 28.9465 60.0228 28.9465C59.0566 28.9465 58.3537 28.6519 57.9173 28.0644C57.4794 27.4753 57.2612 26.601 57.2612 25.4384Z" fill="#1191D0" />
              <path d="M77.2625 23.0088V28.7875H75.6947V23.4389C75.6947 22.002 75.0604 21.2836 73.795 21.2836C73.2854 21.2836 72.8256 21.4005 72.4142 21.6358C72.0028 21.8711 71.6926 22.1781 71.4838 22.5569C71.4963 22.6613 71.5025 22.8701 71.5025 23.1833V28.7859H69.9347V23.1054C69.9347 22.5568 69.7711 22.1158 69.4454 21.7839C69.1181 21.4504 68.6552 21.2836 68.0552 21.2836C67.075 21.2836 66.3051 21.6826 65.7441 22.4789V28.7875H64.1763V19.973H65.7441V21.0888C66.0698 20.7101 66.4983 20.4031 67.0267 20.1678C67.555 19.9324 68.1004 19.8156 68.663 19.8156C69.3425 19.8156 69.9129 19.9527 70.3773 20.227C70.8402 20.5013 71.1581 20.8737 71.328 21.3444C71.6537 20.8878 72.0885 20.5184 72.6308 20.2379C73.1732 19.9574 73.77 19.8171 74.423 19.8171C76.315 19.8171 77.2625 20.8815 77.2625 23.0088Z" fill="#1191D0" />
              <path d="M79.7109 32.707V19.9746H81.2787V21.0125C81.592 20.6463 82.019 20.3501 82.5613 20.1211C83.1037 19.892 83.6741 19.7782 84.2756 19.7782C85.5161 19.7782 86.5057 20.1927 87.2429 21.0218C87.98 21.8509 88.3494 22.9247 88.3494 24.2447C88.3494 25.6037 87.9255 26.732 87.0761 27.6343C86.2268 28.5351 85.1234 28.9855 83.766 28.9855C82.7203 28.9855 81.8912 28.7829 81.2787 28.3777V32.707H79.7109ZM83.8252 21.2463C82.7795 21.2463 81.9317 21.6514 81.2787 22.4603V26.8286C81.9582 27.2852 82.7405 27.5143 83.6289 27.5143C84.5561 27.5143 85.2964 27.2135 85.8528 26.6135C86.4076 26.0135 86.685 25.2421 86.685 24.3024C86.685 23.3891 86.4309 22.6504 85.9213 22.0894C85.4117 21.5268 84.7135 21.2463 83.8252 21.2463Z" fill="#1191D0" />
              <path d="M-0.078125 -0.0779419C4.75304 2.8831 13.8699 10.0519 13.8699 24.6234C13.8699 38.2597 2.88291 46.5195 -0.000202917 48.0499C5.68811 42.6234 10.1842 33.5376 10.0517 23.9221C9.86006 10.0722 3.50629 3.35063 -0.078125 -0.0779419Z" fill="#1191D0" />
              <path d="M11.688 8.79425C14.7379 10.6628 20.4932 15.1885 20.4932 24.388C20.4932 32.9968 13.5566 38.2114 11.7379 39.1761C15.3285 35.7506 18.168 30.014 18.0838 23.9439C17.9623 15.2026 13.9508 10.9589 11.688 8.79425Z" fill="#1191D0" />
            </g>
            <defs>
              <clipPath id="clip0_1408_162">
                <rect width="88.3636" height="48" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Styles.Title>
      </Styles.Header>
      <SidebarMenu />
      <Styles.Toggle onClick={() => setIsCollapsed(!isCollapsed)}>
        <DoubleArrow transform={isCollapsed ? 'rotate(0)' : 'rotate(-180)'} />
      </Styles.Toggle>
    </Styles.Sidebar>
  );
};
