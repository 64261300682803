import React from 'react';
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

import routes from 'src/routes';

import { authApi } from 'src/api/api';
import { Avatar } from 'src/ui/Icons';

import './NavProfile.css';

export function NavProfile() {
  const handleLogoutClick = async () => {
    await authApi.logOut();

    localStorage.clear();

    window.location.href = routes.index;
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu className="nav-profile-menu">
          <Menu.Item className="nav-profile-menu__item">
            <Link to={routes.personal}>
              Личный кабинет
            </Link>
          </Menu.Item>
          <Menu.Item
            className="nav-profile-menu__item nav-profile-menu__item--alarm"
            onClick={handleLogoutClick}
          >
            Выйти
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <button type="button">
        <Avatar />
      </button>
    </Dropdown>
  );
}
