import styled, { css } from 'styled-components';

import { TypographyVariants, TypographyProps } from './Typography.types';

export const TypographyStyles = {
  [TypographyVariants.Accent]: css`
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
  `,
  [TypographyVariants.H1]: css`
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
  `,
  [TypographyVariants.H2]: css`
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px; 
  `,
  [TypographyVariants.H3]: css`
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;  
  `,
  [TypographyVariants.H4]: css`
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  `,
  [TypographyVariants.H5]: css`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  `,
  [TypographyVariants.H6]: css`
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  `,
  [TypographyVariants.TextMedium]: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  `,
  [TypographyVariants.Text]: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  [TypographyVariants.TextSmall]: css`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
};

export const Typography = styled.span.withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})<TypographyProps>`
  ${({ variant }) => TypographyStyles[variant]};
`;
