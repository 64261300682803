import React, { FC } from 'react';

import * as Styles from './FormField.styles';

interface FormFieldProps {
  error?: string;
}

export const FormField: FC<FormFieldProps> = (props) => {
  const {
    children,
    error,
  } = props;

  return (
    <Styles.FormField>
      {children}
      {error && (
        <Styles.FormError>
          {error}
        </Styles.FormError>
      )}
    </Styles.FormField>
  );
};
