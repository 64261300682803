import styled from 'styled-components';

import { Button } from 'src/ui/Button';
import { Input as BaseInput } from 'src/ui/Input';
import { TypographyStyles } from 'src/ui/Typography/Typography';

import { Colors } from 'src/constants/colors';

export const Branches = styled.ul`
  flex-direction: column;
  row-gap: 32px;

  display: flex;

  margin-bottom: 96px;

  @media screen and (min-width: 1024px) {
    row-gap: 56px;
  }
`;

export const Branch = styled.div`
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;

  display: flex;

  @media screen and (min-width: 768px) {
    row-gap: 24px;
  }
`;

export const Rows = styled.ul`
  flex-direction: column;
  row-gap: 16px;

  display: flex;

  width: 100%;

  @media screen and (min-width: 768px) {
    row-gap: 24px;
  }
`;

export const Row = styled.li`
  align-items: center;
  justify-content: space-between;

  display: grid;

  grid-template-columns: 1fr;
  row-gap: 8px;

  @media screen and (min-width: 1280px) {
    grid-template-columns: 35% 65%;
  }
`;

export const FormLabel = styled.span<{ required?: boolean }>`
  ${TypographyStyles.Text};

  ${({ required }) => required && `
    &::after {
      content: '*';

      color: ${Colors.ChineseGoldfish};
    }
  `}
`;

export const Input = styled(BaseInput)``;

export const Toggle = styled(Button)`
  align-items: center;
  column-gap: 16px;

  display: flex;

  ${TypographyStyles.H5};

  @media screen and (min-width: 768px) {
    ${TypographyStyles.H4};
  }
`;
