import React, { FC } from 'react';

import { useTabsContext } from './Tabs.context';

import * as Styles from './Tabs.styles';

interface TabProps {
  id: string;
  name: string;
  value: string;
}

export const Tab: FC<TabProps> = (props) => {
  const {
    id,
    name,
    value,
    children,
  } = props;

  const { value: current, onChange } = useTabsContext();

  return (
    <Styles.Tab>
      <Styles.TabInput
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={current === value}
        onChange={onChange}
      />
      <Styles.TabLabel htmlFor={id}>
        {children}
      </Styles.TabLabel>
    </Styles.Tab>
  );
};
