export enum Colors {
  White = '#FFFFFF',
  WhiteSmoke = '#F2F2F2',
  LittleDipper = '#E4E6EA',
  RoughStone = '#9DA1AA',
  BluePlaza = '#2F353B',
  Lavender = '#E6E6FA',
  UltraViolet = '#7366BD',
  MajesticEggplant = '#423189',
  SnowBallet = '#E3F3E9',
  NinilakhOxide = '#A0D6B4',
  ScotchLassie = '#669E85',
  GreenMoray = '#40826D',
  DarkSlateGray = '#2F4F4F',
  ChineseGoldfish = '#F34723',
  GentianBlue = '#1B1A98',
  JapanBlush = '#E1DAF5',
  Cinder = '#212529',
  Black = '#000000',
  InspirationPeak = '#4ea585',
  MindGreen = '#379874',
  OuterRim = '#16157B',
  Soap = '#D0C6ED',
  Glen = '#47A76A',
  DarkSlateBlue = '#483D8B',
  SugarCrystal = '#F8F4FF',
  Gainsboro = '#D9D9D9',
}
