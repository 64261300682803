/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-children-prop */
import React, {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { nanoid } from 'nanoid';
import {
  NavLink, Prompt, Redirect, useHistory,
} from 'react-router-dom';
import {
  AutoComplete, Input, message, Tooltip, Menu, Dropdown,
} from 'antd';
import { AxiosError } from 'axios';

import cx from 'classnames';
import { useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import ConfirmProposal from 'src/components/shared-components/Modals/ConfirmProposal';
import withHint from 'src/hocs/withHint';
import routes from 'src/routes';
import useDebounce from 'src/hooks/useDebounce';
import { LoadingButton } from 'src/components/shared-components/Loading';
import SaveTemplate from 'src/components/shared-components/Modals/SaveTemplate';
import useLeaveRoute from 'src/hooks/useLeaveRoute';
import useBeforeUnload from 'src/hooks/useBeforeUnload';
import {
  InfoCircleOutlined, LeftOutlined, RightOutlined, SettingOutlined,
} from '@ant-design/icons';
import CustomerProposalContacts from 'src/components/CustomerProposalContacts';
import { About } from 'src/components';
import { UserTypes } from 'src/constants/enums';
import { useExportOrderToXls } from 'src/hooks/useExportOrderToXls';
import plusImg from '../../assets/img/plus.png';
import s from './CustomerProposal.module.css';
import getCookie from '../../helpers/getCookie';
import useTypedSelector from '../../hooks/useTypedSelector';
import {
  IProduct, ProductItemProps, ISearchResult, IOrderData, IAutoComplete,
} from './types';
import { usersApi } from '../../api/api';

const CustomAutoComplete = withHint<IAutoComplete>(AutoComplete);

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  deleteProduct,
  setProducts,
  products,
}) => {
  const [name, setName] = useState<string>('');
  const [searchResult, setSearchResult] = useState<ISearchResult[]>([]);

  const [isCustomProductName, isSetCustomProductName] = useState(false);

  const { isRefresh } = useTypedSelector((state) => state.refresh);

  const crossBtn = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const customProductNameHandler = () => {
    isSetCustomProductName(true);
  };

  const amountHandler = (value: string | undefined) => {
    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, amount: value };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const typeHandler = (value: string) => {
    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, type: value };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const codeHandler = (value: string) => {
    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, code: value };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const clearInput = () => {
    if (product.name.length > 0) {
      const updatedState = products.map((item: IProduct) => {
        if (item.id === product.id) {
          return { ...item, name: '' };
        }
        return item;
      });
      setProducts(updatedState);
    }
  };

  const handleClickOutside = (event: any) => {
    if (crossBtn.current && crossBtn.current.contains(event.target)) {
      clearInput();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const productNameItemHandler = (productName: string) => {
    setName(productName);

    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, name: productName };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const debounceName = useDebounce(name, 500);

  useEffect(() => {
    const token: string | null | undefined = getCookie('Authentication');
    if (debounceName?.length > 0 && debounceName?.length < 50) {
      usersApi.search(token, debounceName).then((r) => setSearchResult(r.data.items));
    }
  }, [debounceName, isRefresh]);

  return (
    <div className={s.product} key={product.id}>
      <div className={s.input_bottom}>
        <CustomAutoComplete
          autoFocus={product.id !== 1}
          allowClear
          className={s.search_input_autocomplete}
          options={name !== ''
            ? searchResult.map((el, index) => ({
              label: el.name,
              value: el.name,
              key: index + 1,
            })).concat([{ label: `Добавить ${name}`, value: name, key: 0 }])
            : searchResult.map((el, index) => ({
              value: el.name,
              key: index + 1,
            }))}
          value={product.name}
          onSelect={(e: string) => {
            if (!searchResult.some((res) => res.name === e)) {
              customProductNameHandler();
            } else {
              productNameItemHandler(e);
            }
          }}
          hint={product.name}
          onSearch={productNameItemHandler}
        >
          <Input
            type="text"
            value={product?.name}
            required
          />
        </CustomAutoComplete>
      </div>
      <div className={s.input_bottom}>
        <div>
          <Input
            type="text"
            disabled={!isCustomProductName}
            value={product?.code}
            onChange={(e: ChangeEvent<HTMLInputElement>) => codeHandler(e.target.value)}
          />
        </div>
      </div>
      <div className={s.input_bottom}>
        <div>
          <CurrencyInput
            required
            defaultValue={1}
            allowDecimals={false}
            allowNegativeValue={false}
            disableGroupSeparators
            disableAbbreviations
            value={product?.amount}
            onValueChange={(e: string | undefined) => amountHandler(e)}
            pattern="^[1-9][0-9]*$"
            title="Поле не может иметь значений меньше 1"
          />
        </div>

      </div>
      <div className={s.input_bottom}>
        <div>
          <select
            className={s.input_select}
            value={product?.type}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => typeHandler(e.target.value)}
          >
            <option value="штука">штука</option>
            <option value="упаковка">упаковка</option>
          </select>
        </div>

      </div>
      {products.length > 1 && (
        <div className={s.delete_button_wrap}>
          <button
            type="button"
            className={s.delete_button}
            onClick={() => deleteProduct(product.id)}
          >
            <span />
          </button>
        </div>
      )}
    </div>
  );
};

const CustomerProposal: React.FC = () => {
  const { result } = useTypedSelector((state) => state.responseResult);
  const { email, type } = useTypedSelector((state) => state.userProfile);
  const userInfo = useTypedSelector((state) => state.userProfile);
  const { isRefresh } = useTypedSelector((state) => state.refresh);

  const [organization, setOrganization] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [, setUserEmail] = useState<string>(email);
  const [linkType, setLinkType] = useState<string>('E-mail');
  const [supplierTypes, setSupplierTypes] = useState<string[]>(['Материалы']);
  const [uniqProviderTypes, setUniqProviderTypes] = useState<string[]>(['Материалы']);
  const [uniqProviders, setUniqProviders] = useState<any>({});
  const [exceptionalSuppliers, setExceptionalSuppliers] = useState([]);
  const [region, setRegion] = useState<string>('г. Санкт-Петербург');
  const [deliveryAddress, setDeliveryAddress] = useState<string>('г. Санкт-Петербург');
  const [comment, setComment] = useState<string>('');
  const [fileData, setFileData] = useState<File | null>(null);
  const [goods, setGoods] = useState<IProduct[]>([
    {
      name: '', code: '', amount: '1', id: nanoid(), type: 'штука',
    },
  ]);
  const formRef = useRef<HTMLFormElement>(null);

  const [isUniqProvidersValid, setIsUniqProvidersValid] = useState(false);

  const [isLoadingButton, setButtonIsLoading] = useState(false);

  const [templateData, setTemplateData] = useState<any>([]);

  const [isTemplateSaving, setIsTemplateSaving] = useState(false);
  const [saveTemplateModalOpened, setSaveTemplateModalOpened] = useState(false);
  const [isContactsOpened, setIsContactsOpened] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [customProviderEmails, setCustomProviderEmails] = useState<string[]>([]);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (userInfo.name !== '' || null) {
      setName(userInfo.name);
    }
    if (userInfo.organization !== '' || null) {
      setOrganization(userInfo.organization);
    }
    if (userInfo.phone !== '' || null) {
      setNumber(userInfo.phone);
    }
    if (typeof userInfo.region === 'string') {
      if (userInfo.region !== '' && userInfo.region !== null) {
        setRegion(userInfo.region);
      }
    } else if (typeof userInfo.region === 'object') {
      if (userInfo.region && userInfo?.region?.name) {
        setRegion(userInfo?.region?.name);
      }
    }
    if (userInfo.address !== '' || null) {
      setDeliveryAddress(userInfo.address);
    }
  }, [userInfo]);

  useEffect(() => {
    if (isUniqProvidersValid) {
      usersApi.getTemplateData().then((r) => {
        if (r.data.data) {
          setTemplateData(r.data.data);
        }
      });
    }
  }, [isUniqProvidersValid]);

  useBeforeUnload();

  const {
    lastLocation,
    handleLocationChange,
  } = useLeaveRoute();

  useEffect(() => {
    if (lastLocation) {
      setSaveTemplateModalOpened(true);
    }
  }, [lastLocation]);

  useEffect(() => {
    const orderGoods = JSON.parse(localStorage.getItem('DENTMP_SHOWCASE_ORDER') || '[]');

    if (orderGoods.length > 0) {
      setGoods(orderGoods.map((item: IProduct) => ({
        id: item.id,
        code: item.code,
        name: item.name,
        amount: item.amount,
        type: item.type,
      })));
    }
  }, []);

  const handleSaveTemplateModalCloseClick = () => {
    setSaveTemplateModalOpened(false);

    if (lastLocation) {
      history.push(lastLocation);
    }
  };

  const setDataFromTemplate = () => {
    message.success('Шаблон был успешно применен!', 3);
    if (Object.keys(templateData).length > 0) {
      if (!userInfo.phone) {
        setNumber(templateData.phone);
      }

      if (!userInfo.organization) {
        setOrganization(templateData.organization);
      }

      if (!userInfo.region) {
        setRegion(templateData.region);
      }

      if (!userInfo.address) {
        setDeliveryAddress(templateData.deliveryAddress);
      }

      const newGoods = templateData.goods.map((item: IProduct) => ({
        id: nanoid(),
        code: item.code,
        name: item.name,
        amount: item.amount,
        type: item.type,
      }));

      setGoods(newGoods);

      localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(newGoods));
    }
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setButtonIsLoading(true);
    const data: any = new FormData();
    const updatedData: IOrderData = {};

    if (fileData !== null) {
      data.append('file', fileData);
    }

    data.append('organization', organization);
    data.append('name', name.trim());
    data.append('phone', number);
    data.append('linkType', linkType);
    for (let i = 0; i < supplierTypes.length; i += 1) {
      data.append(`supplierTypes[${i}]`, supplierTypes[i]);
    }
    data.append('region', region);
    data.append('deliveryAddress', deliveryAddress);
    data.append('comment', comment);

    for (let i = 0; i < goods.length; i += 1) {
      data.append(`goods[${i}][name]`, goods[i].name);
      data.append(`goods[${i}][amount]`, goods[i].amount);
      data.append(`goods[${i}][type]`, goods[i].type);
      if (goods[i]?.code !== null && goods[i]?.code.length > 0) data.append(`goods[${i}][code]`, goods[i]?.code);
    }

    if (exceptionalSuppliers.length > 0) {
      for (let i = 0; i < exceptionalSuppliers?.length; i += 1) {
        data.append(`exceptionalSuppliers[${i}][userId]`, exceptionalSuppliers[i]);
      }
    }

    if (customProviderEmails.length > 0) {
      for (let i = 0; i < customProviderEmails.length; i += 1) {
        data.append(`customProviderEmails[${i}]`, customProviderEmails[i]);
      }
    }

    if (name !== userInfo.name) updatedData.name = name.trim();
    if (organization !== userInfo.organization) updatedData.organization = organization;
    if (number !== userInfo.phone) updatedData.phone = number;
    if (region !== userInfo.region) updatedData.region = region;
    if (deliveryAddress !== userInfo.address) updatedData.address = deliveryAddress;

    const token: string | null | undefined = getCookie('Authentication');

    try {
      if (name !== userInfo.name
        || organization !== userInfo.organization
        || number !== userInfo.phone
        || region !== userInfo.region
        || deliveryAddress !== userInfo.address
      ) {
        const newToken: string | null | undefined = getCookie('Authentication');
        await usersApi.updateData(newToken, updatedData);
      }

      const createNewProposalResponse = await usersApi.createNewProposal(token, data);

      dispatch({
        type: 'GET_RESULT',
        payload: {
          result: createNewProposalResponse.data.result,
          msg: createNewProposalResponse.data.msg,
          orderId: createNewProposalResponse.data.orderId,
        },
      });

      setButtonIsLoading(false);

      localStorage.removeItem('DENTMP_SHOWCASE_ORDER');
      localStorage.removeItem('DENTMP_SHOWCASE_ORDER_SOURCE');
    } catch (err) {
      message.error(`Ошибка создания заказа: ${(err as AxiosError<Error>).response?.data.message.toString()}`);
    }
  }

  const handleSaveTemplateClick = async () => {
    setIsTemplateSaving(true);

    const data: IOrderData = {};

    data.organization = organization;
    data.name = name;
    data.phone = number;
    data.linkType = linkType;

    data.supplierTypes = supplierTypes;

    if (exceptionalSuppliers.length > 0) {
      data.exceptionalSuppliers = exceptionalSuppliers.map((item: any) => ({
        userId: item,
      }));
    }
    data.region = region;
    data.deliveryAddress = deliveryAddress;
    data.comment = comment;
    data.goods = goods.map((item: any) => ({
      name: item.name,
      code: item.code,
      type: item.type,
      amount: item.amount,
    }));

    const token: string | null | undefined = getCookie('Authentication');

    try {
      const response = await usersApi.createTemplateData(token, data);

      if (response.data.msg === 'Шаблон создан!') {
        message.success('Шаблон успешно создан!', 3);
      } else if (response.data.msg === 'Шаблон сохранен!') {
        message.success('Шаблон сохранен!', 3);
      } else {
        message.error('Ошибка в создании шаблона!', 3);
      }
    } catch (e) {
      message.error('Произошла ошибка при создании шаблона');
    } finally {
      setIsTemplateSaving(false);

      if (lastLocation) {
        setSaveTemplateModalOpened(false);
        history.push(lastLocation);
      }
    }
  };

  const addProduct = useCallback(() => {
    const newGoods = [...goods, {
      name: '',
      code: '',
      amount: '1',
      id: nanoid(),
      type: 'штука',
    }];

    setGoods(newGoods);

    localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(newGoods));
  }, [goods]);

  const deleteProduct = useCallback((id: number | string) => {
    const newGoods = goods.filter((product: IProduct) => product.id !== id);

    setGoods(newGoods);

    localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(newGoods));
  }, [goods]);

  useEffect(() => {
    const token: string | null | undefined = getCookie('Authentication');
    usersApi.getUniqProviders(token, supplierTypes).then((r) => {
      setUniqProviders(r.data);
      setIsUniqProvidersValid(true);
    });
  }, [uniqProviderTypes, isRefresh]);

  const setSupplierHandler = useCallback((e: string[]) => {
    for (let i = 0; i < uniqProviders.items?.length; i += 1) {
      if (uniqProviderTypes !== uniqProviders.items[i].providerTypes.name) {
        setExceptionalSuppliers([]);
      }
    }

    if (e.length > 0) {
      setSupplierTypes(e);
      setUniqProviderTypes(e);
    } else {
      setSupplierTypes(['Материалы']);
      setUniqProviderTypes(['Материалы']);
    }
  }, [uniqProviders, uniqProviderTypes]);

  const handleChange = useCallback((value: any) => {
    setExceptionalSuppliers(value);
  }, []);

  const organizationHandler = useCallback((value: string) => {
    setOrganization(value);
  }, []);

  const nameHandler = useCallback((value: string) => {
    setName(value);
  }, []);

  const phoneHandler = useCallback((value: string) => {
    setNumber(value);
  }, []);

  const emailHandler = useCallback((value: string) => {
    setUserEmail(value);
  }, []);

  const linkTypeHandler = useCallback((value: string) => {
    setLinkType(value);
  }, []);

  const handleImportBase = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const [file] = Array.from(event.target.files || []);

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        await usersApi.importBaseFromFile(formData);

        message.success('База импортирована успешно!');
      }
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при импорте базы');
    }
  };

  const handleImportGoods = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsImporting(true);

      const [file] = Array.from(event.target.files || []);

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        const { data } = await usersApi.importGoodsFromFile(formData);

        const importedGoods = data.goods.map((good: IProduct) => ({
          id: nanoid(),
          code: '',
          name: good.name,
          amount: good.amount,
          type: good.type,
        }));

        setGoods(importedGoods);

        localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(importedGoods));
      }
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при импорте товаров');
    } finally {
      setIsImporting(false);
    }
  };

  const handleGoodsUpdate = (updatedGoods: IProduct[]) => {
    setGoods(updatedGoods);

    localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(updatedGoods));
  };

  const { exportOrder } = useExportOrderToXls();

  const exportXls = async () => {
    try {
      setIsExporting(true);

      await exportOrder({ goods });
    } catch (ex) {
      console.warn(ex);
    } finally {
      setIsExporting(false);
    }
  };

  const contactsRef = useRef<HTMLDivElement>(null);

  const settingsMenu = [
    {
      id: 1,
      title: (
        <label>
          Загрузить базу
          <input type="file" onChange={handleImportBase} />
        </label>
      ),
      hint: 'Импорт возможен в формате .xls',
      visible: type === UserTypes.Customer,
    },
    {
      id: 2,
      title: (
        <label>
          Импорт из файла
          <input type="file" onChange={handleImportGoods} />
        </label>
      ),
      hint: 'Импорт возможен в формате .xls, в форме заявки отобразятся наименования товаров',
    },
    {
      id: 3,
      title: (
        <span onClick={setDataFromTemplate}>Применить шаблон</span>
      ),
      hint: 'Будет применен ранее сохраненный шаблон',
    },
    {
      id: 4,
      title: (
        <span onClick={exportXls}>Экспорт заявки в Excel</span>
      ),
    },
  ];

  const handleClick = () => {
    const invalidElements = formRef.current?.querySelectorAll(':invalid') || [];
    const hasOnlyContactsError = Array.from(invalidElements)
      .every((node) => contactsRef.current?.contains(node));

    if (hasOnlyContactsError && !isContactsOpened) {
      setIsContactsOpened(true);
    }
  };

  return (
    <div className={s.page}>
      <div className={s.proposal_settings}>
        <span
          className={cx(s.form_title, s.form_title_link)}
          onClick={() => setIsContactsOpened(!isContactsOpened)}
        >
          Контактные данные
          <button type="button" className={s.settings_icon}>
            {!isContactsOpened && (
              <RightOutlined />
            )}
            {isContactsOpened && (
              <LeftOutlined />
            )}
          </button>
        </span>
        <div className={s.form_title_container}>
          <span className={cx(s.form_title, s.hide_on_mobile)}>
            Как работает сервис?
          </span>
          <div className={s.form_title_tooltip}>
            <Tooltip
              title={About}
              color="#ffffff"
              placement="bottomLeft"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </div>
      </div>
      <form
        className={s.form}
        ref={formRef}
        id="new-order"
        action="POST"
        onSubmit={handleSubmit}
      >
        <div
          ref={contactsRef}
          className={cx(s.proposal_contacts, isContactsOpened && s.proposal_contacts__active)}
        >
          <CustomerProposalContacts
            organization={organization}
            organizationHandler={organizationHandler}
            name={name}
            nameHandler={nameHandler}
            number={number}
            phoneHandler={phoneHandler}
            emailHandler={emailHandler}
            fileData={fileData}
            setFileData={setFileData}
            supplierTypes={supplierTypes}
            setSupplierHandler={setSupplierHandler}
            exceptionalSuppliers={exceptionalSuppliers}
            handleChange={handleChange}
            uniqProviders={uniqProviders}
            deliveryAddress={deliveryAddress}
            setDeliveryAddress={setDeliveryAddress}
            comment={comment}
            setComment={setComment}
            region={region}
            setRegion={setRegion}
            linkTypeHandler={linkTypeHandler}
            customProviderEmails={customProviderEmails}
            setCustomProviderEmails={setCustomProviderEmails}
          />
        </div>
        <div className={cx(s.bottom, isContactsOpened && s.bottom__small)}>
          <div className={s.bottom_inner}>
            <div className={s.form_header}>
              <span className={s.form_title}>
                Заявка на товар
              </span>
              <Dropdown
                arrow
                overlay={(
                  <Menu>
                    {settingsMenu
                      .filter(({ visible = true }) => visible)
                      .map(({ id, title, hint }) => (
                        <Menu.Item key={id}>
                          <div className={s.proposal_settings_menu_item}>
                            <span className="font-weight-normal">
                              {title}
                            </span>
                            {hint && (
                              <div className={s.form_title_tooltip}>
                                <Tooltip
                                  title={(
                                    <div className={s.tooltip_content}>
                                      {hint}
                                    </div>
                                  )}
                                  color="#ffffff"
                                  placement="bottomLeft"
                                >
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </Menu.Item>
                      ))}
                  </Menu>
                )}
                placement="bottomLeft"
                trigger={['click']}
              >
                <button type="button" className={s.settings_icon}>
                  <SettingOutlined />
                </button>
              </Dropdown>
              {(isImporting || isExporting) && (
                <div className={s.importing_loader}>
                  <LoadingButton />
                </div>
              )}
            </div>
            <div className={s.bottom_body}>
              <div className={s.products_title}>
                <div>Наименование товара</div>
                <div>Штрихкод</div>
                <div>Кол-во</div>
                <div>Ед-измерения</div>
              </div>
              {goods.map((el: IProduct) => (
                <ProductItem
                  key={el.id}
                  product={el}
                  deleteProduct={deleteProduct}
                  setProducts={handleGoodsUpdate}
                  products={goods}
                />
              ))}
            </div>
            <div className={s.buttons}>
              {goods.length <= 150 && (
                <div className={s.add_button}>
                  <button type="button" onClick={() => addProduct()}>
                    <div className={s.add_button_inner}>
                      <img src={plusImg} alt="+" />
                      <div>Добавить товар</div>
                    </div>
                  </button>
                </div>
              )}
              <NavLink
                className={s.marketLink}
                to={routes.market}
                onMouseDown={() => localStorage.setItem('DENTMP_SHOWCASE_ORDER_SOURCE', window.location.pathname)}
              >
                Добавить товар с витрины
              </NavLink>
            </div>
            <div className={s.bottom_footer}>
              {!result ? (
                <ConfirmProposal
                  formRef={formRef}
                  loading={isLoadingButton}
                  onClick={handleClick}
                />
              ) : <Redirect to={routes.success} />}
              <button
                className={cx(s.submitButton, s.saveTemplateButton)}
                type="button"
                disabled={isTemplateSaving}
                onClick={handleSaveTemplateClick}
              >
                {!isTemplateSaving && 'Сохранить шаблон'}
                {isTemplateSaving && <LoadingButton />}
              </button>
            </div>
            <div className={s.footer_text}>
              Нажимая кнопку «Далее», я подтверждаю свою дееспособность,
              принимаю условия
              {' '}
              <NavLink to={routes.agreement}>
                Пользовательского соглашения
                {' '}
              </NavLink>
              {' '}
              и подтверждаю свое
              {' '}
              <NavLink to={routes.privacyPolicy}>
                согласие на обработку персональных данных
                {' '}
              </NavLink>
              в соответствии с Федеральным законом № 152-ФЗ «О
              персональных данных» от 27.06.2006 г.
            </div>
          </div>
        </div>
      </form>
      <Prompt
        when={!result}
        message={handleLocationChange}
      />
      {saveTemplateModalOpened && (
        <SaveTemplate
          isSaving={isTemplateSaving}
          onSubmit={handleSaveTemplateClick}
          onCancel={handleSaveTemplateModalCloseClick}
        />
      )}
    </div>
  );
};

export default CustomerProposal;
