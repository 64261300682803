import { Colors } from 'src/constants/colors';
import { Button } from 'src/ui/Button';
import { TypographyStyles } from 'src/ui/Typography/Typography';
import { css, styled } from 'styled-components';

const ProviderCellMixin = css`
  flex-basis: 146px;

  text-align: left;

  @container cost-layout (min-width: 704px) {
    flex-basis: 164px;
  }

  @container cost-layout (min-width: 944px) {
    flex-basis: 128px;
  }

  @container cost-layout (min-width: 1159px) {
    flex-basis: 214px;
  }

  @container cost-layout (min-width: 1516px) {
    flex-basis: 240px;
  }
`;

const CountCellMixin = css`
  flex-basis: 120px;
`;

const CommentCellMixin = css`
  flex-basis: 0;
  flex-grow: 1;

  text-align: left;
`;

const CostCellMixin = css``;

const ActionsCellMixin = css`
  flex-basis: 56px;

  @container cost-layout (min-width: 868px) {
    flex-basis: 128px;
  }

  @container cost-layout (min-width: 1516px) {
    flex-basis: 216px;
  }
`;

export const Good = styled.div`
  &:not(:last-child) {
    margin-bottom: 56px;
  }
`;

export const TableTitle = styled.h6`  
  margin: 0;
  margin-bottom: 16px;

  ${TypographyStyles.H6};
`;

export const Table = styled.table`
  table-layout: fixed;
  
  width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody`
  &:not(:last-child) {
    border-bottom: 56px solid transparent;
  }
`;

export const TableHeadRow = styled.tr`
  container: cost-layout / inline-size;

  column-gap: 16px;

  display: flex;

  height: 28px;
  
  @media screen and (min-width: 1280px) {
    column-gap: 20px;
  }
`;

export const TableHeadCell = styled.th`
  ${TypographyStyles.TextSmall};
  color: ${Colors.BluePlaza};
`;

export const TableHeadProviderCell = styled(TableHeadCell)`
  ${ProviderCellMixin};
`;

export const TableHeadCountCell = styled(TableHeadCell)`
  ${CountCellMixin};

  text-align: left;

  &::after {
    content: 'Кол-во';
  }

  @container cost-layout (min-width: 1516px) {
    &::after {
      content: 'Количество';
    }
  }
`;

export const TableHeadCommentCell = styled(TableHeadCell)`
  ${CommentCellMixin};
`;

export const TableHeadCostCell = styled(TableHeadCell)`
  ${CostCellMixin};

  text-align: right;
`;

export const TableHeadActionsCell = styled(TableHeadCell)`
  ${ActionsCellMixin};
`;

export const TableRow = styled.tr`
  container: cost-layout / inline-size;

  align-items: center;
  column-gap: 16px;

  display: flex;

  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 56px;

  &:first-child {
    padding-top: 0;
    min-height: 48px;
  }

  &:first-child:last-child {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 40px;
  }

  &:last-child:not(:first-child) {
    padding-bottom: 0;
    min-height: 48px;
  }

  &:not(:last-child) {
    box-shadow: 0 0.5px 0 0 ${Colors.LittleDipper};
  }

  @media screen and (min-width: 1280px) {
    column-gap: 20px;
  }
`;

export const TableCell = styled.td``;

export const TableProviderCell = styled(TableCell)`
  ${ProviderCellMixin};
`;

export const TableCountCell = styled(TableCell)`
  ${CountCellMixin};

  column-gap: 16px;

  display: flex;
`;

export const TableCommentCell = styled(TableCell)`
  ${CommentCellMixin};
`;

export const TableCostCell = styled(TableCell)`
  ${CostCellMixin};
`;

export const TableActionsCell = styled(TableCell)`
  ${ActionsCellMixin};
`;

export const TableCellText = styled.span<{ clamp?: number }>`
  display: block;

  color: ${Colors.BluePlaza};
  ${TypographyStyles.TextSmall};

  ${({ clamp }) => clamp && `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${clamp};
    word-break: break-all;

    overflow: hidden;
  `}

  @media screen and (min-width: 1280px) {
    ${TypographyStyles.Text};
  }
`;

export const TableCellButton = styled(Button)`
  padding: 8px 16px;

  ${TypographyStyles.Text};

  .small-container {
    display: inline;
  }

  .medium-container,
  .large-container {
    display: none;
  }

  @container cost-layout (min-width: 868px) {
    padding: 8px 24px;

    .small-container {
      display: none;
    }

    .medium-container {
      display: inline;
    }
  }

  @container cost-layout (min-width: 1516px) {
    .medium-container {
      display: none;
    }

    .large-container {
      display: inline;
    }
  }
`;

export const TableCellLink = styled(Button).attrs({
  variant: 'filled',
  size: 'small',
  width: 'full',
})`
  padding: 8px 16px;

  ${TypographyStyles.Text};
  justify-content: center;

  .small-container {
    display: inline;
  }

  .medium-container,
  .large-container {
    display: none;
  }

  @container cost-layout (min-width: 868px) {
    padding: 8px 24px;
    
    .small-container {
      display: none;
    }

    .medium-container {
      display: inline;
    }
  }

  @container cost-layout (min-width: 1516px) {
    .medium-container {
      display: none;
    }

    .large-container {
      display: inline;
    }
  }
`;

export const TableCellBadge = styled.div`
  padding: 4px 16px;

  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};

  border-radius: 16px;
`;

export const Toggle = styled(Button)`
  margin-top: 16px;

  align-items: center;
  column-gap: 8px;

  ${TypographyStyles.Text};
  color: ${Colors.UltraViolet};
`;
