import React, { ComponentPropsWithoutRef, FC } from 'react';

export const DoubleArrow: FC<ComponentPropsWithoutRef<'svg'>> = (props) => {
  const {
    stroke = '#7366BD',
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 18L5 12L11 6" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M18 18L12 12L18 6" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
