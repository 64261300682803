import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getUserData } from 'src/store/userProfile/actions';

import { withPreventDefault } from 'src/hocs/withPreventDefault';

import { authApi } from 'src/api/api';

import { Modal } from 'src/ui/Modal';
import { FormField } from 'src/ui/FormField';
import { Input } from 'src/ui/Input';
import { Toast } from 'src/ui/Toast';

import getCookie from 'src/helpers/getCookie';

import { EmailForm } from './ChangeEmail.types';

import * as Styles from './ChangeEmail.styles';

export const ChangeEmail = () => {
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit: onSubmit,
    reset,
    formState,
  } = useForm<EmailForm>({
    defaultValues: {
      email: '',
    },
  });

  const handleSubmit = onSubmit(async (data) => {
    try {
      await authApi.changeEmail(data.email);

      Toast.success('Ссылка для подтверждения новой почты отправлена', 100000);

      await authApi.refresh();

      const updatedToken: string | undefined | null = getCookie('Authentication');

      // TODO: переделать работу с профилями и авторизацию
      dispatch(getUserData(updatedToken || ''));

      modalRef.current?.close();
    } catch (ex) {
      Toast.error('Произошла ошибка при обновлении почты');
    }
  });

  return (
    <>
      <Styles.ChangeButton
        type="button"
        onClick={() => modalRef.current?.showModal()}
      >
        Изменить адрес электронной почты
      </Styles.ChangeButton>
      <Modal
        ref={modalRef}
        title="Изменить адрес электронной почты"
        onClose={() => reset()}
      >
        <Styles.ModalBody>
          <Styles.Form id="change-email" onSubmit={withPreventDefault(handleSubmit)}>
            <Styles.Field>
              <Styles.FieldLabel required>
                Введите новый адрес
              </Styles.FieldLabel>
              <FormField error={formState.errors.email?.message}>
                <Input
                  placeholder="new@emailadress.ru"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Укажите новый адрес электронной почты',
                    },
                    pattern: {
                      value: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                      message: 'Некорректная почта',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Почта должна быть не больше 50 символов',
                    },
                  })}
                  error={formState.errors.email?.message}
                />
              </FormField>
            </Styles.Field>
          </Styles.Form>
          <Styles.Buttons>
            <Styles.SubmitButton
              form="change-email"
              type="submit"
              variant="filled"
              size="big"
              loading={formState.isSubmitting}
            >
              Подтвердить
            </Styles.SubmitButton>
            <Styles.CancelButton
              type="button"
              variant="outlined"
              size="big"
              onClick={() => modalRef.current?.close()}
            >
              Отмена
            </Styles.CancelButton>
          </Styles.Buttons>
        </Styles.ModalBody>
      </Modal>
    </>
  );
};
