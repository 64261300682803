import styled from 'styled-components';

import { Colors } from 'src/constants/colors';
import { TypographyStyles } from 'src/ui/Typography/Typography';
import { Button } from 'src/ui/Button';

export const Header = styled.div`
  align-items: center;
  justify-content: space-between;

  display: flex;

  margin-bottom: 56px;
`;

export const Tabs = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  margin-bottom: 0;

  ${TypographyStyles.H2};
  color: ${Colors.BluePlaza};
`;

export const CreateOrder = styled(Button)`
  ${TypographyStyles.TextMedium};
`;

export const Filters = styled.div`
  column-gap: 16px;
  margin-bottom: 32px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  max-width: 706px;
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const EmptyTable = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);

  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;

  display: flex;
`;

export const EmptyTableText = styled.p`
  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};
  text-align: center;
`;

export const Table = styled.div`
  margin-bottom: 96px;
`;
