import React, { useMemo, useState } from 'react';
import { InputNumber, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { NavLink, useHistory } from 'react-router-dom';
import cx from 'classnames';

import { selectIsCustomerType } from 'src/store/userProfile/selectors';
import useTypedSelector from 'src/hooks/useTypedSelector';

import MarketStyles from 'src/pages/Market/index.module.css';

import MarketBreadcrumbs from 'src/components/MarketBreadcrumbs';

import routes from 'src/routes';

import { LocationState } from 'src/constants/enums';

import useMarketGood from './useMarketGood';

import AddMarketGoodToOrderButton from './AddMarketGoodToOrderButton';

import Carousel from './Carousel';
import Lightbox from './Lightbox';

import s from './index.module.css';

const MarketGood = () => {
  const {
    good,
    orderGood,
    hasGoodInOrder,
    onChange,
    onClick,
  } = useMarketGood();

  const isCustomerType = useTypedSelector(selectIsCustomerType);

  const [isLightboxOpened, setIsLightboxOpened] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);

  const handleCarouselImageClick = (index: number) => {
    setClickedImageIndex(index);
    setIsLightboxOpened(true);
  };

  const history = useHistory<LocationState>();
  const { from = window.location.href } = history.location.state || {};
  const { search, pathname: comeFrom } = new URL(from);

  const breadcrumbs = useMemo(() => (
    [
      {
        title: 'DentMarketPlace',
        url: routes.index,
      },
      {
        title: good?.supplierType.name,
        url: `${routes.market}?supplierType=${good?.supplierType.name}`,
      },
      {
        title: good?.name,
        url: `${routes.market}/${good?.id}`,
      },
    ]
  ), [good]);

  return (
    <>
      <div className={s.breadcrumbsContainer}>
        <MarketBreadcrumbs items={breadcrumbs} />
      </div>
      {!good && (
        <div className={MarketStyles.pageContentLoader}>
          <LoadingOutlined style={{ fontSize: 48 }} />
        </div>
      )}
      {good && (
        <div className={s.good}>
          <h1 className={MarketStyles.pageContentTitle}>
            {good.name}
          </h1>
          <span className={s.goodCode}>
            Артикул:
            {' '}
            {good.code}
          </span>
          <div className={s.goodData}>
            <Carousel
              files={good.files}
              onClick={handleCarouselImageClick}
            />
            {isLightboxOpened && (
              <Lightbox
                activeImageIndex={clickedImageIndex}
                files={good.files}
                onChange={setClickedImageIndex}
                onClose={() => setIsLightboxOpened(false)}
              />
            )}
            {good.files.length === 0 && (
              <div className={s.goodImageTemplate} />
            )}
            <div>
              {isCustomerType && (
                <>
                  <div className={s.goodMetric}>
                    <span className={s.goodMetricTitle}>Количество</span>
                    <div className={s.goodMetricCounter}>
                      <InputNumber
                        type="number"
                        min={1}
                        size="large"
                        onChange={(value) => onChange({ name: 'amount', value })}
                        value={orderGood.amount}
                      />
                    </div>
                  </div>
                  <div className={s.goodMetric}>
                    <span className={s.goodMetricTitle}>Единица измерения</span>
                    <Select
                      className={s.goodType}
                      defaultValue="штука"
                      onChange={(value) => onChange({ name: 'type', value })}
                      value={orderGood.type}
                    >
                      <Select.Option value="штука">Штука</Select.Option>
                      <Select.Option value="упаковка">Упаковка</Select.Option>
                    </Select>
                  </div>
                  <div className={s.goodControls}>
                    <AddMarketGoodToOrderButton onClick={onClick} />
                    {hasGoodInOrder && (
                      <NavLink
                        className={cx(s.goodControl, s.goToOrderLink)}
                        to={localStorage.getItem('DENTMP_SHOWCASE_ORDER_SOURCE') || routes.new}
                      >
                        Перейти к заказу
                      </NavLink>
                    )}
                    {search && comeFrom === routes.market && (
                      <button
                        type="button"
                        className={cx(s.goodControl, s.goToOrderLink)}
                        onClick={() => history.goBack()}
                      >
                        Вернуться к результатам поиска
                      </button>
                    )}
                  </div>
                </>
              )}
              {good.description && (
                <>
                  <h2 className={s.goodDescriptionTitle}>
                    Описание товара
                  </h2>
                  <p className={s.goodDescription}>
                    {good.description}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MarketGood;
