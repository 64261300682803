const applyParams = (url: string, params: string[]) => {
  let resultUrl = url;

  params.forEach((param) => {
    resultUrl = resultUrl.replace(/:.*/, param);
  });

  return resultUrl;
};

export default applyParams;
