import React from 'react';
import { LandingHeader } from 'src/components/LandingHeader';

import MarketHeaderForm from '../MarketHeaderForm';
import s from './index.module.css';

function MarketHeaderMobile() {
  return (
    <div>
      <LandingHeader />
      <div className={s.mobileSearchForm}>
        <MarketHeaderForm />
      </div>
    </div>
  );
}
export default MarketHeaderMobile;
