import styled from 'styled-components';

import { Button } from 'src/ui/Button';
import { Colors } from 'src/constants/colors';
import { TypographyStyles } from 'src/ui/Typography/Typography';

export const DrawerTrigger = styled(Button)`
  display: block;

  @container order-layout (min-width: 688px) {
    display: none;
  }
`;

export const Actions = styled.ul`
  flex-direction: column;
  row-gap: 16px;

  display: flex;

  margin: 0;
`;

export const ActionButton = styled(Button)`
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;

  ${TypographyStyles.TextMedium};
  color: ${Colors.BluePlaza};
`;

export const Action = styled.li`
  &:not(:last-child) {
    padding: 0 0 16px;

    box-shadow: 0 1px 0 0 ${Colors.LittleDipper};
  }
`;

export const ActionButtonHint = styled.span`
  ${TypographyStyles.TextSmall};
  color: ${Colors.RoughStone};
`;
