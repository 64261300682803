import styled from 'styled-components';

import { Colors } from 'src/constants/colors';

import { Button } from 'src/ui/Button';
import { TypographyStyles } from 'src/ui/Typography/Typography';

export const Title = styled.h1`
  margin-bottom: 24px;

  ${TypographyStyles.H3};
  color: ${Colors.BluePlaza};

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
    
    ${TypographyStyles.H2};
  }

  @media screen and (min-width: 1024px) { 
    margin-bottom: 56px;
  }
`;

export const Rows = styled.ul`
  flex-direction: column;
  row-gap: 24px;

  display: flex;

  margin-bottom: 56px;

  @media screen and (min-width: 768px) {
    margin-bottom: 96px;
  }
`;

export const Row = styled.li`
  justify-content: space-between;
  grid-template-columns: 1fr;
  row-gap: 8px;

  display: grid;

  @media screen and (min-width: 1280px) {
    grid-template-columns: 35% 65%;
  }
`;

export const FormLabel = styled.span<{ required?: boolean }>`
  ${TypographyStyles.Text};

  ${({ required }) => required && `
    &::after {
      content: '*';

      color: ${Colors.ChineseGoldfish};
    }
  `}

  @media screen and (min-width: 1280px) {
    margin-top: 16px;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;

  ${TypographyStyles.TextMedium};

  @media screen and (min-width: 768px) {
    width: auto;
  }
`;
