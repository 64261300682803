import { styled } from 'styled-components';

import { Colors } from 'src/constants/colors';

export const Header = styled.header`
  position: relative;

  box-shadow: 0 -1px 0 0 inset ${Colors.LittleDipper};
  padding: 16px;

  @media screen and (min-width: 768px) {
    padding: 16px 32px;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Sidebar = styled.div`
  display: flex;

  margin-left: auto;
`;
