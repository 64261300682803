import React, {
  ComponentType,
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';

import { usersApi } from 'src/api/api';

import { ProposalStatuses } from 'src/types/proposal';

import { CompareData, ComparedOrder } from '../types';

import { useProvidersFilter } from './ProvidersFilter';

interface ComparedOrderContextValue {
  comparedOrder: ComparedOrder | null;
  getComparedOrder: () => Promise<void>;
}

const ComparedOrderContext = createContext<ComparedOrderContextValue>({
  comparedOrder: null,
  getComparedOrder: async () => {},
});

export const useComparedOrder = () => useContext(ComparedOrderContext);

const ComparedOrderContextProvider: FC = ({ children }) => {
  const [comparedOrder, setComparedOrder] = useState<ComparedOrder | null>(null);

  const { id: orderId } = useParams<{ id: string }>();

  const { setFilter } = useProvidersFilter();

  const getComparedOrder = useCallback(async () => {
    try {
      const { data } = await usersApi.getProposalsCompare(Number(orderId));

      // const data = MOCK;

      setComparedOrder({
        ...data,
        offers: data.offers
          .map((item) => ({
            ...item,
            goods: item.goods.sort((a, b) => a.offersGoods.id - b.offersGoods.id),
            status: ProposalStatuses.DONE,
          }))
          .concat((data.offersStatus || []) as unknown as CompareData[]),
      });

      setFilter(data.offers.map((item) => item.id));
    } catch (e) {
      // TODO: редирект ту нотфаунд
    }
  }, []);

  useEffect(() => {
    getComparedOrder();
  }, []);

  const context = useMemo(() => ({
    comparedOrder,
    getComparedOrder,
  }), [comparedOrder, getComparedOrder]);

  return (
    <ComparedOrderContext.Provider value={context}>
      {children}
    </ComparedOrderContext.Provider>
  );
};

export const withComparedOrder = <T extends JSX.IntrinsicAttributes>
  (Component: ComponentType<T>) => (
    (props: T) => (
      <ComparedOrderContextProvider>
        <Component {...props} />
      </ComparedOrderContextProvider>
    )
  );
