/* eslint-disable prefer-regex-literals */
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Form,
  Input,
  Tag,
} from 'antd';

import {
  ContainerOutlined,
  SettingOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';

import routes from 'src/routes';
import { UserTypes } from 'src/constants/enums';
import { IBackendUserData } from 'src/types/userProfile';
import { selectIsCustomerType } from 'src/store/userProfile/selectors';

import s from './Settings.module.css';
import './Setting.css';
import { authApi, usersApi } from '../../api/api';

import { setUserDataAC } from '../../store/userProfile/actions';
import useActions from '../../hooks/useActions';
import useTypedSelector from '../../hooks/useTypedSelector';
import getCookie from '../../helpers/getCookie';
import CustomerForm from './CustomerForm';
import ProviderForm from './ProviderForm';
import { SettingsNew } from '../SettingsNew';

const getIconComponent = (visible: boolean) => (
  visible
    ? <EyeTwoTone />
    : <EyeInvisibleOutlined />
);

const Settings: FC = () => {
  const userData = useTypedSelector((state) => state.userProfile);

  const { showErr, changeEmail, changePassword } = useActions();

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    const { type, id, ...oldData } = userData;

    form.setFieldsValue({
      ...oldData,
      providerTypes: oldData.providerTypes.map(({ name }) => name),
    });
  }, [userData]);

  if (userData.name === undefined) {
    return null;
  }

  const inputsProviderTypesHandler = async (values: any) => {
    const token: string | null | undefined = getCookie('Authentication');
    const isChecked = values.length > userData.providerTypes.length;
    const mappedProviderTypes = userData.providerTypes.map(({ name }) => name);

    const value = isChecked
      ? values.find((providerType: any) => !mappedProviderTypes.includes(providerType))
      : mappedProviderTypes.find((providerType: any) => !values.includes(providerType));

    if (isChecked) {
      dispatch({
        type: 'SET_USER_DATA',
        payload: { providerTypes: [...userData.providerTypes, { name: value }] },
      });
      usersApi.addProviderType(token, value).then(() => {
        showErr('Тип поставщика обновлен!');
      });
    } else {
      dispatch({
        type: 'REMOVE_TYPE_OF_PROVIDER',
        payload: value,
      });
      usersApi.deleteProviderType(token, value).then(() => {
        showErr('Тип поставщика обновлен!');
      });
    }
  };

  const emailChangeHandler = ({ email }: any) => {
    const token: string | null | undefined = getCookie('Authentication') ? getCookie('Authentication') : window.localStorage.getItem('token');

    if (token) {
      changeEmail(email);
    } else {
      authApi
        .refresh()
        .then(() => {
          changeEmail(email);
        });
    }
  };

  const isCustomerRole = userData.type
    && [UserTypes.Customer, UserTypes.Branch].includes(userData.type);

  const isProviderRole = userData.type
    && [UserTypes.Provider, UserTypes.Employee].includes(userData.type);

  const changeUserInfoHandler = async (newUserData: any) => {
    const {
      email: oldEmail,
      isProvider,
      providerTypes,
      isActive,
      usersBranch,
      region,
      name,
      mainBranchName,
      usersEmployee,
      email,
      password1,
      password2,
      ...otherData
    } = newUserData;

    const regionValue = typeof region === 'object' && region !== null
      ? region.name || ''
      : region;

    const body: IBackendUserData = {
      ...otherData,
      name: name.trim(),
      region: regionValue,
    };

    if (isCustomerRole) {
      body.branchName = mainBranchName;
    }

    usersApi.updateData(body)
      .then(() => {
        showErr('Данные обновлены!');

        dispatch(setUserDataAC({
          ...newUserData,
          ...(isProviderRole && {
            providerTypes: providerTypes.map((type: string) => ({ name: type })),
          }),
          email,
          region: {
            name: regionValue,
          },
        }));
      })
      .catch(() => {
        showErr('Произошла ошибка при сохранении!');
      });
  };

  const saveProfileData = async (values: any) => {
    await changeUserInfoHandler(values);

    const {
      password2,
      password1,
      email,
    } = values;

    if (userData.email !== email) {
      emailChangeHandler(values);
    }

    if (password1 !== ''
      && password2 !== ''
      && password2 !== password1
    ) {
      changePassword(
        password1,
        password2,
      );
    }
  };

  const canEdit = userData.type
    && [UserTypes.Customer, UserTypes.Provider].includes(userData.type);

  return (
    <div className={s.settings}>
      <div className={s.settingsHeader}>
        <Link to={routes.personal}>
          <Button
            type="default"
            icon={<ContainerOutlined />}
            size="large"
          >
            {isCustomerRole ? 'Мои заявки' : 'Мои предложения'}
          </Button>
        </Link>
        <Link to={routes.settings}>
          <div className={s.settingsContainer}>
            <Button
              type="default"
              icon={<SettingOutlined />}
              size="large"
            >
              Настройки
            </Button>
          </div>
        </Link>
      </div>
      <div className={s.changeUserInfoBlock}>
        <div className={`${s.bigTitle} ${s.btwJst}`}>
          <span>Личные данные</span>
          <div className={s.inputGroupPrepend}>
            <Tag color="magenta">
              {isCustomerRole ? 'Заказчик' : 'Поставщик'}
            </Tag>
          </div>
        </div>
        <Form
          form={form}
          onFinish={saveProfileData}
        >
          {isCustomerRole && (
            <CustomerForm />
          )}
          {isProviderRole && (
            <ProviderForm onChangeProviderTypes={inputsProviderTypesHandler} />
          )}
          <div className={s.inputGroupItem}>
            <span className={s.inputDescription}>
              Электронная почта
            </span>
            <Form.Item
              className={s.inputDescription}
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите адрес эл. почты',
                },
                {
                  message: 'Пожалуйста, введите свою почту корректно!',
                  pattern: new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/),
                },
              ]}
            >
              <Input
                maxLength={50}
                type="email"
                placeholder="Электронная почта"
                disabled={!canEdit}
              />
            </Form.Item>
          </div>
          {canEdit && (
            <>
              <div className={s.inputGroupItem}>
                <div className={`${s.passwordBlock} ${s.w100} password_block`}>
                  <span className={s.inputDescription}>Изменить пароль</span>
                  <Form.Item
                    className={s.formItem}
                    name="password1"
                    rules={[
                      {
                        message: 'Пароль должен состоять хотя бы из 6 символов!',
                        pattern: new RegExp('^(?=.{6,})'),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Старый пароль"
                      iconRender={getIconComponent}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={s.inputGroupItem}>
                <div className={`${s.passwordBlock} ${s.w100} password_block`}>
                  <span className={s.inputDescription}> </span>
                  <Form.Item
                    className={s.formItem}
                    name="password2"
                    dependencies={['password1']}
                    rules={[
                      {
                        message: 'Пожалуйста, введите новый пароль!',
                      },
                      {
                        message: 'Пароль должен состоять хотя бы из 6 символов!',
                        pattern: new RegExp('^(?=.{6,})'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_: any, value: any) {
                          if (!value || getFieldValue('password1') !== value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Пароли совпадают!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Новый пароль"
                      iconRender={getIconComponent}
                    />
                  </Form.Item>
                </div>
              </div>
              <button
                type="submit"
                className={`${s.mainStyleBtn} ${s.btn}`}
              >
                Сохранить
              </button>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default () => {
  const isCustomerType = useSelector(selectIsCustomerType);

  if (isCustomerType) {
    return (
      <SettingsNew />
    );
  }

  return (
    <Settings />
  );
};
