import { styled } from 'styled-components';

import { Colors } from 'src/constants/colors';
import { TypographyStyles } from 'src/ui/Typography/Typography';

export const Filters = styled.ul`
  flex-wrap: wrap;
  gap: 16px;

  display: flex;

  margin-bottom: 32px;
`;

export const Filter = styled.li<{ active: boolean }>`
  justify-content: center;

  display: flex;

  box-shadow: 0 0 0 1px ${Colors.Lavender};
  border-radius: 4px;
  padding: 8px 8px 8px 16px;

  background-color: ${Colors.Lavender};

  ${({ active }) => !active && `
    box-shadow: 0 0 0 1px ${Colors.RoughStone};
    padding: 8px 16px 8px 16px;

    background-color: transparent;
  `}
`;

export const FilterInput = styled.input`
  appearance: none;
`;

export const FilterLabel = styled.label`
  gap: 10px;
  align-items: center;

  display: flex;

  cursor: pointer;
`;

export const FilterLabelText = styled.span`
  display: inline-block;
  
  max-width: 285px;

  ${TypographyStyles.TextMedium};
  color: ${Colors.BluePlaza};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
