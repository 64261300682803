import {
  Branch,
  IEmployee,
  UserTypes,
} from 'src/constants/enums';

export enum ActionsTypes {
  SET_USER_DATA = 'SET_USER_DATA',
  REMOVE_TYPE_OF_PROVIDER = 'REMOVE_TYPE_OF_PROVIDER',
  SET_USER_TYPE = 'SET_USER_TYPE',
}
// интерфейс всего редьюсера
export interface UserProfileState extends IUserData {
  type: UserTypes | null,
  id: number | null,
}

export interface IUserData {
  name: string;
  email: string;
  isProvider: boolean | null;
  phone: string;
  organization: string;
  region: { name: string };
  address: string;
  website?: string;
  providerTypes: { name: string }[];
  isActive: boolean;
  usersBranch: Branch[];
  mainBranchName: string;
  usersEmployee: IEmployee[];
}

export interface IBackendUserData extends Partial<Pick<IUserData, 'name' | 'phone' | 'organization' | 'address' | 'website'>> {
  branchName?: string;
  region: string;
}

// интерфейса экшена
export interface SetUserData {
  type: ActionsTypes.SET_USER_DATA;
  payload: Partial<IUserData>;
}

export interface RemoveTypeOfProvider {
  type: ActionsTypes.REMOVE_TYPE_OF_PROVIDER;
  payload: IUserData;
}

export interface ISetUserType {
  type: ActionsTypes.SET_USER_TYPE;
  payload: {
    type: UserTypes;
  }
}

// далее, если нужно добавить другие типы экшенов - ставим | и добавляем */
export type UserProfileActions = SetUserData | RemoveTypeOfProvider | ISetUserType;
