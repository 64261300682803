import React, { FC, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';

import { AppSidebar } from 'src/components/AppSidebar';
import { AppHeader } from 'src/components/AppHeader';

import * as Styles from './AppPage.styles';

export const AppPage: FC = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleResize = _debounce(() => {
      if (mainRef.current && contentRef.current) {
        mainRef.current.style.setProperty(
          '--scrollbar-width',
          `${contentRef.current.offsetWidth - contentRef.current.clientWidth}px`,
        );
      }
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Styles.Flex>
      <AppSidebar />
      <Styles.Content ref={contentRef}>
        <AppHeader />
        <Styles.Main ref={mainRef}>
          {children}
        </Styles.Main>
      </Styles.Content>
    </Styles.Flex>
  );
};
