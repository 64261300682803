import styled, { createGlobalStyle } from 'styled-components';

import { Colors } from 'src/constants/colors';
import { TypographyStyles } from 'src/ui/Typography/Typography';

export const GlobalStyles = createGlobalStyle`
  .order-actions {
    overflow: hidden;
    
    border-radius: 8px;
    box-shadow: 0px 0.13953px 1.3554px 0px rgba(66, 49, 137, 0.01), 0px 0.31702px 3.07958px 0px rgba(66, 49, 137, 0.02), 0px 0.55175px 5.35988px 0px rgba(66, 49, 137, 0.02), 0px 0.87651px 8.51466px 0px rgba(66, 49, 137, 0.03), 0px 1.35239px 13.13748px 0px rgba(66, 49, 137, 0.03), 0px 2.1103px 20.50001px 0px rgba(66, 49, 137, 0.03), 0px 3.50342px 34.0332px 0px rgba(66, 49, 137, 0.04), 0px 7px 68px 0px rgba(66, 49, 137, 0.07);
    padding: 0;
  }

  .order-actions__item {
    padding: 16px 32px;

    ${TypographyStyles.Text};
  }
`;

export const Header = styled.div`
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  display: flex;

  margin-bottom: 32px;

  @media screen and (min-width: 1024px) {
    gap: 16px 24px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 56px;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  column-gap: 8px;

  display: flex;
`;

export const Title = styled.h3`
  margin: 0;

  ${TypographyStyles.H4};
  color: ${Colors.BluePlaza};

  @media screen and (min-width: 1024px) {
    ${TypographyStyles.H3};
  }

  @media screen and (min-width: 1280px) {
    ${TypographyStyles.H2};
  }

  @media screen and (min-width: 1920px) {
    ${TypographyStyles.H1};
  }
`;
