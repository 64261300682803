import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
`;

export const Main = styled.main`
  --scrollbar-width: 0;

  position: relative; // TODO: возможно, ошибка

  flex-grow: 1;

  padding: 24px 0 24px 16px;
  padding-right: calc(16px - var(--scrollbar-width));

  @media screen and (min-width: 768px) {
    padding: 32px 0 32px 32px;
    padding-right: calc(32px - var(--scrollbar-width));
  }
`;

export const Content = styled.div`
  flex-direction: column;

  display: flex;

  height: 100vh;
  width: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;
