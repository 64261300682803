import React from 'react';

import ReactSelect, {
  GroupBase,
  Props,
  ValueContainerProps,
  components as SelectComponents,
  OptionProps,
  mergeStyles,
  StylesConfig,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select';

import * as Styles from './Select.styles';
import { Close, LinkArrow } from '../Icons';

const ValueContainer = <
  T,
  K extends boolean,
  N extends GroupBase<T>,
>
  ({ children, ...props }: ValueContainerProps<T, K, N>) => (
    <SelectComponents.ValueContainer {...props}>
      <Styles.SelectValueContainer>
        {children}
      </Styles.SelectValueContainer>
    </SelectComponents.ValueContainer>
  );

const Option = <
  T,
  K extends boolean,
  N extends GroupBase<T>,
>
  ({ children, ...props }: OptionProps<T, K, N>) => (
    <SelectComponents.Option {...props}>
      <Styles.SelectOption>
        {children}
      </Styles.SelectOption>
    </SelectComponents.Option>
  );

const DropdownIndicator = <
  T,
  K extends boolean,
  N extends GroupBase<T>,
>
  ({ children, ...props }: DropdownIndicatorProps<T, K, N>) => (
    <SelectComponents.DropdownIndicator {...props}>
      <LinkArrow width={24} height={24} transform="rotate(90)" />
    </SelectComponents.DropdownIndicator>
  );

const ClearIndicator = <
  T,
  K extends boolean,
  N extends GroupBase<T>,
>({ children, ...props }: ClearIndicatorProps<T, K, N>) => {
  if (!props.selectProps.menuIsOpen) {
    return null;
  }

  return (
    <SelectComponents.ClearIndicator {...props}>
      <Close width={24} height={24} />
    </SelectComponents.ClearIndicator>
  );
};

export const Select = <
  T,
  K extends boolean,
  N extends GroupBase<T>,
>(props: Props<T, K, N>) => {
  const { components, styles, ...other } = props;

  return (
    <ReactSelect
      {...other}
      styles={mergeStyles(Styles.DefaultStylesProp as unknown as StylesConfig<T, K, N>, styles)}
      components={{
        ValueContainer,
        Option,
        IndicatorSeparator: null,
        DropdownIndicator,
        ClearIndicator,
        ...components,
      }}
    />
  );
};
