import { Colors } from 'src/constants/colors';
import styled from 'styled-components';

import { TypographyStyles } from '../Typography/Typography';

import { InputProps } from './Input.types';
import { Button } from '../Button';

export const Input = styled.input<InputProps>`
  box-shadow: 0 0 0 1px ${Colors.BluePlaza};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  
  background: ${Colors.White};

  ${TypographyStyles.Text};
  color: ${Colors.BluePlaza};
  caret-color: ${Colors.UltraViolet};

  &::placeholder {
    ${TypographyStyles.Text};
    color: ${Colors.RoughStone};
  }

  &:hover, &:focus {
    color: ${Colors.BluePlaza};
  }

  ${({ error }) => !error && `
    &:hover {
      box-shadow: 0 0 0 1px ${Colors.UltraViolet};
    }

    &:focus {
      box-shadow: 0 0 0 1px ${Colors.MajesticEggplant};
    }
  `}

  ${({ error }) => error && `
    box-shadow: 0 0 0 1px ${Colors.ChineseGoldfish};
  `}

  &:disabled {
    box-shadow: 0 0 0 1px ${Colors.RoughStone};

    color: ${Colors.RoughStone};
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const PasswordInput = styled(Input)`
  padding-right: 40px; // TODO: возможно для иконок надо переделать
`;

export const PasswordIcon = styled(Button)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export const SearchInput = styled(Input)`
  padding-right: 40px;
`;

export const SearchIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
