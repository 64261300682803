import React, { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';

import { Modal } from 'src/ui/Modal';
import { Input, PasswordInput } from 'src/ui/Input';
import { FormField } from 'src/ui/FormField';

import { usersApi } from 'src/api/api';
import { Toast } from 'src/ui/Toast';

import { BranchCredentials } from 'src/constants/enums';

import * as Styles from './AddCredentials.styles';

interface AddCredentialsProps {}

export const AddCredentials: FC<AddCredentialsProps> = () => {
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const {
    register,
    handleSubmit: onSubmit,
    reset,
    formState: {
      errors,
      isSubmitting,
    },
    setError,
  } = useForm<BranchCredentials>({
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const handleSubmit = onSubmit(async (data) => {
    try {
      await usersApi.saveBranchCredentials(data);

      modalRef.current!.close();

      Toast.success('Изменения сохранены');
    } catch (ex) {
      setError('login', {
        message: (ex as AxiosError).response?.data.message,
      });

      Toast.error('Произошла ошибка при сохранении доступов');
    }
  });

  return (
    <>
      <Styles.SettingsButton
        size="small"
        variant="filled"
        type="button"
        onClick={() => modalRef.current?.showModal()}
      >
        Задать логин и пароль
      </Styles.SettingsButton>
      <Modal
        ref={modalRef}
        title="Задайте логин и пароль для филиалов"
        onClose={() => reset()}
      >
        <Styles.ModalBody>
          <Styles.Form id="credentials" onSubmit={handleSubmit}>
            <div>
              <Styles.FormLabel>
                Логин
              </Styles.FormLabel>
              <FormField error={errors.login?.message}>
                <Input
                  placeholder="Логин"
                  {...register('login', {
                    required: {
                      value: true,
                      message: 'Пожалуйста, введите логин',
                    },
                    minLength: {
                      value: 4,
                      message: 'Логин должен быть больше 4 символов',
                    },
                    maxLength: {
                      value: 30,
                      message: 'Логин должен быть не больше 30 символов',
                    },
                  })}
                  error={errors.login?.message}
                />
              </FormField>
            </div>
            <div>
              <Styles.FormLabel>
                Пароль
              </Styles.FormLabel>
              <FormField error={errors.password?.message}>
                <PasswordInput
                  placeholder="Пароль"
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Пожалуйста, введите пароль',
                    },
                    minLength: {
                      value: 6,
                      message: 'Пароль должен быть больше 6 символов',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Пароль должен быть не больше 50 символов',
                    },
                  })}
                  error={errors.password?.message}
                />
              </FormField>
            </div>
          </Styles.Form>
          <Styles.Buttons>
            <Styles.SubmitButton
              form="credentials"
              type="submit"
              variant="filled"
              size="big"
              loading={isSubmitting}
            >
              Сохранить
            </Styles.SubmitButton>
            <Styles.CancelButton
              type="button"
              variant="outlined"
              size="big"
              onClick={() => modalRef.current?.close()}
            >
              Отмена
            </Styles.CancelButton>
          </Styles.Buttons>
        </Styles.ModalBody>
      </Modal>
    </>
  );
};
